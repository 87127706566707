<ul>
  <li
    *ngFor="let tab of tabs"
    (click)="selectTab(tab)"
    [class.active]="tab.active"
  >
    <a>{{ tab.tabTitle }}</a>
  </li>
</ul>
<ng-content></ng-content>
