import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { LinkedAssignment } from '@k2/common/entities-state/types';
import { AutocompleteSelectorConfig } from '@k2/common/ui/components/autocomplete-selector/autocomplete-selector.component';
import { AssignmentAutocompleteOptionComponent } from '../assignment-autocomplete-option/assignment-autocomplete-option.component';
import { AssignmentIngotComponent } from '../assignment-ingot/assignment-ingot.component';
import { map } from 'rxjs/operators';
import { ComponentSpec } from '@k2/common/ui/component-spec';

@Component({
  selector: 'assignment-selector',
  templateUrl: 'assignment-selector.component.html',
  styleUrls: ['assignment-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentSelectorComponent implements OnChanges {
  @Input() selected: LinkedAssignment;
  @Input() config: assignmentSelectorConfig;
  @Output() selectedChange = new EventEmitter<LinkedAssignment>();

  selectorConfig: AutocompleteSelectorConfig<LinkedAssignment>;

  constructor(private backend: Backend) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config == null) return;

    this.selectorConfig = {
      toId: (linkedAssignment: LinkedAssignment) => linkedAssignment.assignmentId,
      toName: (linkedAssignment: LinkedAssignment) => linkedAssignment.assignmentId.toString(),
      query: (query: string) => {
        return this.backend.search
          .searchAssignmentLinks(query)
          .pipe(
            map(linkedAssignments =>
              linkedAssignments.filter(
                linkedAssignment => !this.config.excluded.includes(linkedAssignment.assignmentId)
              )
            )
          );
      },
      toSelectedSpec: (
        linkedAssignment: LinkedAssignment
      ): ComponentSpec<AssignmentIngotComponent> => ({
        component: AssignmentIngotComponent,
        inputs: { linkedAssignment }
      }),
      toOptionSpec: (
        linkedAssignment: LinkedAssignment,
        query: string
      ): ComponentSpec<Partial<AssignmentAutocompleteOptionComponent>> => ({
        component: AssignmentAutocompleteOptionComponent,
        inputs: { linkedAssignment, highlight: query }
      }),
      multiple: this.config.multiple,
      placeholder: this.config.placeholder
    };
  }

  update = (selected: LinkedAssignment) => {
    this.selectedChange.emit(selected);
  };
}

export interface assignmentSelectorConfig {
  readonly multiple: boolean;
  readonly excluded: number[];
  readonly placeholder?: string;
}
