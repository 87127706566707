<div [id]="field.attributes.id" [class.compact]="hasALotOptions" class="group" [ngClass]="field.attributes?.inline ? 'group-inline' : 'group'">
  <label *ngFor="let option of options" class="d-flex align-items-center">
    <input
      type="radio"
      [name]="randomizedFieldName"
      [value]="option.id"
      [formControl]="field.control"
    />
    <span class="radio-option">{{ option.value }}</span>
  </label>
</div>
