<span *ngIf="origin.country_iso && destination.country_iso">
  {{ origin.city_utf8 }}
  <country-flag
    [code]="origin.country_iso"
    [tooltip]="origin.country"
  ></country-flag>
  &nbsp;
  <icon type="share-alt"></icon>
  &nbsp;{{ destination.city_utf8 }}
  <country-flag
    [code]="destination.country_iso"
    [tooltip]="destination.country"
  ></country-flag>
</span>

<span *ngIf="!origin.country_iso && !destination.country_iso">n/a</span>
