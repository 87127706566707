<autocomplete-option>
  <figure>
    <file-jet-img
      [image]="partner.avatar"
      [width]="100"
      [height]="40"
      [alt]="partner.name"
    ></file-jet-img>
  </figure>

  <div [innerHTML]="partner.name | highlight: highlight" class="main"></div>
</autocomplete-option>
