import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'timec',
  templateUrl: 'timec.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimecComponent implements OnChanges {
  @Input() iso: string; // ISO8601

  ngOnChanges() {
    this.iso = this.iso?.replace(/\s/g, 'T');
  }
}
