<div class="d-flex flex-column">
  <div *ngFor="let currentStats of stats | keyvalue" class="d-flex flex-row">
    <div class="item mr-3">
      <div class="year">{{ currentStats.key || 'N/A' }}</div>
    </div>

    <div class="item">
      <div class="title">{{ currentStats.value.total_relo.name }}:</div>
      <div class="number">{{ currentStats.value.total_relo.value }}</div>
    </div>

    <div class="item" *ngFor="let top of currentStats.value.top3">
      <div class="title">{{ top.name }}:</div>
      <div class="number">{{ top.total }}</div>
    </div>
  </div>
</div>

