import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@k2/common/entities-state/types';
import { FlightMapLocation } from '@k2/common/map/components/flight-map/flight-map.component';

@Component({
  selector: 'assignment-flight-map',
  templateUrl: 'assignment-flight-map.component.html',
  styleUrls: ['assignment-flight-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentFlightMapComponent {
  @Input() origin: Location;
  @Input() destination: Location;

  get formattedOrigin(): FlightMapLocation {
    return format(this.origin);
  }

  get formattedDestination(): FlightMapLocation {
    return format(this.destination);
  }
}

function format(location: Location): FlightMapLocation {
  return {
    name: `${location.city_utf8} (${location.country})`,
    latitude: Number(location.latitude),
    longitude: Number(location.longitude)
  };
}
