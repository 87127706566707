import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { pipeValuesTo, ReplayLastSubject } from '@k2/common/helpers';
import { first } from 'rxjs/operators';

@Component({
  selector: 'follow-assignment-toggle',
  templateUrl: 'follow-assignment-toggle.component.html',
  styleUrls: ['follow-assignment-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowAssignmentToggleComponent implements OnChanges {
  @Input() assignmentId: number;
  following = new ReplayLastSubject<boolean>();

  constructor(private backend: Backend) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.backend.assignments
      .isFollowedByCurrentUser(this.assignmentId)
      .subscribe(pipeValuesTo(this.following));
  }

  toggle = () => {
    this.following.pipe(first()).subscribe(following => {
      if (following) {
        this.backend.assignments.unfollowByCurrentUser(this.assignmentId).subscribe();
        this.following.next(false);
      } else {
        this.backend.assignments.followByCurrentUser(this.assignmentId).subscribe();
        this.following.next(true);
      }
    });
  };
}
