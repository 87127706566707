declare global {
  const AmCharts: any;
}

/**
 * Lazily loads the AmMap library.
 */
export async function loadAmMap() {
  await import('./ammap-bundle');
  return AmCharts;
}
