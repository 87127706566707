import { Action, ActionReducer } from '@ngrx/store';

export class BatchAction implements Action {
  constructor(readonly type: string, readonly actions: Action[] | BatchAction[]) {}
}

/**
 * Tries to apply a given action and all sub actions in one turn.
 */
export function handleBatchAction(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    const actions = toBasicActions(action);
    return actions.reduce(reducer, state);
  };
}

/**
 * Converts a given action to an array of Actions.
 *
 * A give action could be a BatchAction. Each BatchAction could wraps another BatchAction-s.
 * Result of this function will be a flat array of all actions in a correct order.
 */
export function toBasicActions(action: Action | BatchAction): Action[] {
  if (isBatchAction(action)) {
    const subActions = (action.actions as BatchAction[])
      .map(toBasicActions)
      .reduce((all, actions) => [...all, ...actions], []);

    return [action, ...subActions];
  }
  return [action];
}

function isBatchAction(action): action is BatchAction {
  return Array.isArray(action.actions);
}
