import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'noticeboard',
  templateUrl: 'noticeboard.component.html',
  styleUrls: ['noticeboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NoticeboardComponent {
  _notes: string;
  get notes(): string {
    return this._notes;
  }

  // this forces the markdown editor to use htmlString values instead of jsonDoc
  @Input() set notes(value: string) {
    this._notes = (value ? this.convertToHtmlString(value) : null) || '<p></p>';
  }
  @Input() k2Only = true;
  @Input() placeholder: string = 'There are no K2 notes...';
  @Output() save = new EventEmitter<string>();
  @Input() showHeader: boolean = true;
  @Input() title: string = null;

  convertToHtmlString(string: string): string {
    let processedString = string;
    // replace newlines
    processedString = processedString.replace(/(?:\n)/g, '<br>');
    processedString = processedString.replace(/(?:\\n)/g, '<br>');
    // replace tabs
    processedString = processedString.replace(/(?:\t)/g, '<span>&nbsp&nbsp&nbsp&nbsp</span>');
    processedString = processedString.replace(/(?:\\t)/g, '<span>&nbsp&nbsp&nbsp&nbsp</span>');
    return processedString.startsWith('<p') ? processedString : ('<p>' + processedString + '</p>');
  }

  onNotesChange(event: any) {
    this.notes = event;
  }
}
