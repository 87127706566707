<h2>Find partner</h2>

<form class="controls" novalidate (submit)="search()">
  <div class="line">
    <label>
      Country:
      <place-selector [(place)]="place"></place-selector>
    </label>

    <label>
      Partner name:
      <input
        type="text"
        [(ngModel)]="name"
        [ngModelOptions]="{ standalone: true }"
        class="form-control name"
        placeholder="(optional)"
      />
    </label>
  </div>

  <div class="line">
    <div class="button"><btn icon="search" htmlType="submit">Search</btn></div>
  </div>
</form>

<div class="content">
  <no-data *ngIf="results == null">
    Please enter search criteria and hit search button.
  </no-data>

  <ng-container *ngIf="results != null">
    <ng-container *ngIf="results.length > 0">
      <div class="results-count">
        Found {{ results.length }}
        {{ results.length === 1 ? 'result' : 'results' }} :
      </div>
      <a
        *ngFor="let partner of results"
        (click)="!partner.is_prohibited && select(partner)"
        [class.disabled]="partner.is_prohibited"
        class="partner"
      >
        <file-jet-img
          [image]="partner.avatar"
          [width]="190"
          [height]="76"
          [alt]="partner.name"
        ></file-jet-img>

        <div class="overview">
          <h3>
            {{ partner.name }}
            <tag *ngIf="partner.is_prohibited" value="prohibited"></tag>
          </h3>

          <div class="details">
            <bit *ngIf="partner.location" label="Location" multiLine>
              {{ partner.location.city_utf8 }} ,
              {{ partner.location.country_iso2 }}
            </bit>

            <bit label="Rating" multiLine>
              <star-rating [rating]="partner.avg_rating"></star-rating>
            </bit>

            <bit label="Last 6 months" multiLine>{{ partner.initiations }}</bit>
          </div>
        </div>

        <div *ngIf="!partner.is_prohibited" class="chevron">
          <icon type="chevron-right"></icon>
        </div>
      </a>
    </ng-container>

    <no-data *ngIf="results.length === 0">
      No search results for a given criteria.
    </no-data>
  </ng-container>
</div>
