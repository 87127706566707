<section class="services-messages">
  <div class="row header">
    <div class="col-sm-6">
      <h2 class="pull-left" *ngIf="service || services?.length === 1">
        Service messages
      </h2>
      <h2 class="pull-left" *ngIf="services?.length > 1">Messages</h2>
    </div>

    <div *ngIf="services?.length > 1" class="col-sm-6 text-right">
      <div class="form-inline">
        <div class="form-group">
          <label>Filter messages&nbsp;</label>
          <select
            id="filterElement"
            [(ngModel)]="filterService"
            (ngModelChange)="synchroniseServiceSelectors($event)"
          >
            <option value="" selected>All services</option>
            <option *ngFor="let service of services" [ngValue]="service">
              {{ service.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <p class="intro mt5">
    Post messages to colleagues about this assignment as well as keeping a
    record of phone conversations with the assignee or the client.
  </p>

  <ul class="messages">
    <li
      *ngFor="let message of messages | filterByService: filterService"
      class="message-block"
    >
      <div *ngIf="message.postState === 'failed'" class="message-error">
        Message post failed. We will try it again for you after a while...
      </div>

      <div class="message-body {{ message.postState }}">
        <figure class="avatar">
          <file-jet-img
            [image]="message.author.avatar"
            [width]="50"
            [height]="50"
            [alt]="message.author | userName"
          ></file-jet-img>
        </figure>

        <div class="message-info">
          <dl>
            <dt>K2:</dt>
            <dd *ngIf="message.author.id !== userId">
              <a [routerLink]="message.author | userLink"></a>
            </dd>
            <dd *ngIf="message.author.id === userId">
              <strong>You</strong>
            </dd>

            <dt>Time:&nbsp;</dt>
            <dd><relative-time [iso]="message.created_at"></relative-time></dd>

            <dt>Service:</dt>
            <dd>
              <a
                *ngIf="message.services_id"
                [routerLink]="[
                  '/staff/clients',
                  clientId,
                  'assignment',
                  assignmentId,
                  'services',
                  message.services_id
                ]"
              >
                {{ findService(message.services_id).name }}
              </a>
              <span *ngIf="!message.services_id">
                {{ findService(message.services_id).name }}</span
              >
            </dd>
          </dl>
        </div>

        <service-message-text [text]="message.text"></service-message-text>
      </div>
    </li>
  </ul>

  <div class="message-block">
    <figure class="avatar">
      <file-jet-img
        [image]="currentUserAvatar"
        [width]="50"
        [height]="50"
        [alt]="'avatar'"
      ></file-jet-img>
    </figure>

    <textarea
      [(ngModel)]="draftText"
      class="form-control"
      placeholder="Type your message here"
    ></textarea>

    <div class="form-inline message-controls">
      <div class="form-group">
        <span *ngIf="services?.length > 1">
          <label>Category&nbsp;</label>
          <select
            [(ngModel)]="selectedService"
            (ngModelChange)="synchroniseServiceSelectors($event)"
          >
            <option *ngFor="let service of services" [ngValue]="service">
              {{ service.name }}
            </option>
          </select>
        </span>

        <button (click)="addMessage()" class="btn btn-default">
          Post message
        </button>
      </div>
    </div>
  </div>
</section>
