<k2-table [items]="items">
  <ng-container header><h2>Ending assignment soon</h2></ng-container>

  <table>
    <thead>
      <tr>
        <th>Assignee</th>
        <th>End date</th>
        <th>Destination</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let assignment of items">
        <td class="key-column">
          <a [routerLink]="assignment | assigneeLink">
            {{ assignment.person | userName }}
          </a>
          <star-icon
            [serviceLevelTypesId]="assignment.service_level_types_id"
          ></star-icon>
        </td>

        <td>
          <date [iso]="assignment.end_date"></date>
          (<relative-time [iso]="assignment.end_date"></relative-time>)
        </td>

        <td>
          {{ assignment.destination.city_utf8 }}
          <country-flag
            code="{{ assignment.destination.country_iso }}"
            tooltip="{{ assignment.destination.country }}"
          ></country-flag>
        </td>
      </tr>
    </tbody>
  </table>

  <no-data ifNothing>There are no ending assignments.</no-data>
</k2-table>
