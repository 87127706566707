import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FeedbackTimeframeReport } from '@k2/common/entities-state/types';
import { DoughnutChartConfig } from '@k2/common/k2-charts/components/doughnut-chart/doughnut-chart.component';
import { values, toPairs } from 'ramda';

@Component({
  selector: 'response-rates',
  templateUrl: 'response-rates.component.html',
  styleUrls: ['response-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponseRatesComponent implements OnChanges {
  @Input() responseRates: FeedbackTimeframeReport;

  response = toDoughnutChart([]);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.responseRates &&
      (this.responseRates.totalRecieved > 0 || this.responseRates.totalAwaiting > 0)
    ) {
      this.response = toDoughnutChart({
        'Received responses': this.responseRates.totalRecieved,
        'Awaiting responses': this.responseRates.totalAwaiting
      });
    } else {
      this.response = toDoughnutChart([]);
    }
  }

  get totalRequested() {
    return this.responseRates ? this.responseRates.totalRequested : 0;
  }

  get totalReceived() {
    return this.responseRates ? this.responseRates.totalRecieved : 0;
  }

  get totalAwaiting() {
    return this.responseRates ? this.responseRates.totalAwaiting : 0;
  }

  get totalPercentage() {
    if (this.responseRates) {
      let percentage = Math.round(
        (this.responseRates.totalRecieved / this.responseRates.totalRequested) * 100
      );
      if (!isNaN(percentage)) {
        return percentage;
      }
      return 0;
    }
    return 0;
  }
}

function toDoughnutChart(data): DoughnutChartConfig {
  const sum = values(data).reduce((previous, current) => previous + current, 0);

  const labels = toPairs(data).map(
    ([key, value]: [string, number]) => `${Math.round((100 / sum) * value)}% ${key}`
  );

  return {
    labels,
    data: values(data)
  };
}
