import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Assignment } from '@k2/common/entities-state/types';

@Component({
  selector: 'assignments-starting-soon-table',
  templateUrl: 'assignments-starting-soon-table.component.html',
  styleUrls: ['assignments-soon-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentStartingSoonTableComponent {
  @Input() items: Assignment[];
}
