import { appConfig } from '@k2/common/app-config';
import { DocumentFile } from '@k2/common/entities-state/types';

const { apiUrl } = appConfig;

export type DocumentUrlType = 'origin' | 'thumbnail';

export function toDocumentUrl(file: DocumentFile, urlType: DocumentUrlType = 'origin'): string {
  if (urlType === 'origin') {
    return `${apiUrl}/file/download/${file.id}/${file.identifier_hash}/${file.name}`;
  }
  if (urlType === 'thumbnail') {
    return `${apiUrl}/file/download/thumb/${file.id}/${file.identifier_hash}/${file.name}`;
  }
  throw Error(`Failed to generate document URL! Type "${urlType}" is not supported.`);
}
