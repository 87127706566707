import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@k2/common/entities-state/types';

@Component({
  selector: 'user-ingot-base',
  templateUrl: 'user-ingot-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserIngotBaseComponent {
  @Input() user: User;
  @Input() link: any;
}
