import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * An autocomplete option wrapper.
 *
 * <figure> is an optional element.
 * Use a ".main" class for a main text.
 *
 * EXAMPLE:
 *   <autocomplete-option>
 *     <figure>
 *       ...
 *     </figure>
 *
 *     <div class="main">Main text</div>
 *     <div>Additional text</div>
 *   </autocomplete-option>
 */
@Component({
  selector: 'autocomplete-option',
  templateUrl: 'autocomplete-option.component.html',
  styleUrls: ['autocomplete-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteOptionComponent {}
