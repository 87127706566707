import { Component, Input, SimpleChanges } from '@angular/core';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { addValidators, WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'checkboxes-control',
  templateUrl: 'checkboxes-control.component.html',
  styleUrls: ['checkboxes-control.component.scss']
})
export class CheckboxesControlComponent extends WithFormControl<(string | number)[]> {

  @Input() field: Field<(string | number)[]>;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.field) return;
    if (this.field.attributes.minSelectedOptions) {
      addValidators(this.field.control, this.customValidator.bind(this));
      this.field.control.updateValueAndValidity();
    }
  }

  get options(): ValueOption[] {
    return this.field.attributes.valueOptions;
  }

  private customValidator(_: AbstractControl): ValidationErrors {
    if (this.value?.length && this.value.length < this.field.attributes.minSelectedOptions) {
      return { minSelectedOptions: `Select at least ${this.field.attributes.minSelectedOptions} options` };
    }
    return null;
  }
}
