<autocomplete-option>
  <div
    [innerHTML]="linkedAssignment.assignmentId | k2Id | highlight: highlight"
    class="main"
  ></div>
  <div>Client: {{ linkedAssignment.client.name }}</div>
  <div *ngIf="linkedAssignment.assignee.name">
    Assignee: {{ linkedAssignment.assignee.name }}
  </div>
</autocomplete-option>
