<deletable-item-options (delete)="onDelete.emit()" [deleteLink]="deleteLink">
  <ng-content></ng-content>
  <a *ngIf="editLink" [routerLink]="editLink">
    <span class="glyphicon glyphicon-pencil visible-xs-inline" aria-hidden="true"></span>
    <span class="hidden-xs">edit</span>
  </a>
  <a *ngIf="!editLink" (click)="onEdit.emit()">
    <span class="glyphicon glyphicon-pencil visible-xs-inline" aria-hidden="true"></span>
    <span class="hidden-xs">edit</span>
  </a>
  |
</deletable-item-options>
