import { NgModule } from '@angular/core';
import { DoughnutChartComponent } from '@k2/common/k2-charts/components/doughnut-chart/doughnut-chart.component';
import { setDefaults } from '@k2/common/k2-charts/defaults';
import { SharedModule } from '@k2/common/shared.module';
import { NgChartsModule } from 'ng2-charts';

const components = [DoughnutChartComponent];

setDefaults();

@NgModule({
  imports: [NgChartsModule, SharedModule],
  declarations: components,
  exports: [...components, NgChartsModule]
})
export class K2ChartsModule {
}
