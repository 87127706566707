import { getBase } from 'just-location-base';

const available = 10;
const aspectRatio = window.screen.width / window.screen.height;

export function generateBackgroundStyle() {
  if (aspectRatio > 1.3) {
    const DATE = new Date();
    const START_DATE = new Date(`12.15.${DATE.getFullYear()}`);
    const END_DATE = new Date(`01.05.${DATE.getFullYear() + 1}`);
    let imageUrl;

    if (START_DATE <= DATE && DATE <= END_DATE) {
      imageUrl = new URL(`./11.jpg`, getBase());
    } else {
      const INDEX = Math.round(new Date().getMinutes() / (60 / (available - 1)));
      imageUrl = new URL(`./${INDEX}.jpg`, getBase());
    }

    return {
      backgroundColor: '#343f56',
      background: `linear-gradient(0deg, rgba(52, 63, 85, 0.65), rgba(52, 63, 85, 0.65)), url('${imageUrl}')`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    };
  }

  return { backgroundColor: '#343f56' };
}
