import { NgModule } from '@angular/core';
import { featureEffects } from '@k2/common/sessions-state/effects';
import { featureReducer } from '@k2/common/sessions-state/reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    StoreModule.forFeature('sessions', featureReducer),
    EffectsModule.forFeature(featureEffects)
  ]
})
export class SessionsStateModule {}
