import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FieldSpecValueFormatter } from '@k2/common/k2-forms/field-spec/field-spec-value-formatter';
import { FieldSpecValueComponent } from '@k2/common/k2-forms/field-spec/field-spec-value.component';
import {
  FORMATTERS_LIST_PROVIDER,
  FORMATTERS_PROVIDER
} from '@k2/common/k2-forms/field-spec/formatters';
import { SharedModule } from '@k2/common/shared.module';
import { TelephoneModule } from '@k2/common/telephone/telephone.module';
import { UsersModule } from '@k2/common/users/users.module';

@NgModule({
  imports: [SharedModule, TelephoneModule, UsersModule],
  declarations: [FieldSpecValueComponent],
  exports: [FieldSpecValueComponent],
  providers: [FORMATTERS_PROVIDER, FORMATTERS_LIST_PROVIDER, DatePipe, FieldSpecValueFormatter]
})
export class FieldSpecModule {}
