import { Injectable, Provider } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterState } from '@k2/common/routing/state';
import { RouterStateSerializer } from '@ngrx/router-store';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    return { url, queryParams };
  }
}

export const routerStateSerializerProvider: Provider = {
  provide: RouterStateSerializer,
  useClass: CustomRouterStateSerializer
};
