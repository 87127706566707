<annotated-control [field]="field">
  <div>
    <k2-select
      [value]="model.currency"
      [options]="currencies"
      [disabled]="disabled"
      (valueChange)="update($event, 'currency')"
      (openedChange)="currencyOpenedChange($event)"
    ></k2-select>

    <input
      #amount
      [ngModel]="model.amount"
      (ngModelChange)="update($event, 'amount')"
      (focus)="onFocusChanged('amount', true)"
      (blur)="onFocusChanged('amount', false)"
      [disabled]="disabled"
      type="number"
      step="0.01"
      min="0"
      class="form-control"
    />

    <select
      [ngModel]="model.frequency"
      (ngModelChange)="update($event, 'frequency')"
      (focus)="onFocusChanged('frequency', true)"
      [disabled]="disabled"
      (blur)="onFrequencyTouch()"
      class="form-control"
    >
      <optgroup placeholder></optgroup>

      <option *ngFor="let frequency of frequencies" [ngValue]="frequency.id">
        {{ frequency.value }}
      </option>
    </select>
  </div>
</annotated-control>
