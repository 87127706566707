import { Pipe, PipeTransform } from '@angular/core';
import { Airport } from '../components/airports/airport';

@Pipe({
  name: 'airportName'
})
export class AirportNamePipe implements PipeTransform {
  transform(airport: Airport) {
    return toAirportName(airport);
  }
}

export function toAirportName(airport: Airport): string | undefined {
  if (airport == null || (airport.name == null && airport.iata_code == null)) return;
  return `${airport.name || ''} ${airport.iata_code ? '(' + airport.iata_code + ')' : ''}`.trim();
}
