<h2>Recent additional comments</h2>

<p>
  These are the most recent responses where your employees have taken the time
  to include additional comments on their assignment co-ordinator
</p>

<div>
  <k2-table [items]="comments" [paginator]="commentsPaginator">
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Assignee</th>
          <th>Rating</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let comment of comments | paginate: commentsPaginator | async"
        >
          <td><date [iso]="comment.date.date"></date></td>
          <td>
            <a [routerLink]="comment.assignment | assigneeLink">
              {{ comment.assignment.person | userName }}
            </a>
          </td>
          <td><star-rating [rating]="comment.rating"></star-rating></td>
          <td><inline-text [text]="comment.comment"></inline-text></td>
        </tr>
      </tbody>
    </table>
  </k2-table>
</div>
