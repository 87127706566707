<section *ngIf="isVisible" outline>
  <h2>Assignment progress</h2>

  <div *ngIf="hasStarted">
    <p>{{ progress }}% way through assignment</p>

    <small>Will end <relative-time [iso]="endDate"></relative-time>.</small>
  </div>

  <div *ngIf="!hasStarted">
    <p>Assignment not started yet</p>
    <small>
      Will start in <relative-time [iso]="startDate"></relative-time>.
    </small>
  </div>
</section>
