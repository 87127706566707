import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { K2ChartsModule } from '@k2/common/k2-charts/k2-charts.module';
import { ServiceProgressStatusComponent } from '@k2/common/services/components/progress-status/service-progress-status.component';
import { AdditionalServicesSelectorComponent } from '@k2/common/services/components/services-selector/additional-services-selector.component';
import { ServicesSelectorComponent } from '@k2/common/services/components/services-selector/services-selector.component';
import { StorageUsageChartComponent } from '@k2/common/services/components/storage-usage-chart/storage-usage-chart.component';
import { StorageUsageComponent } from '@k2/common/services/components/storage-usage/storage-usage.component';
import { SharedModule } from '@k2/common/shared.module';
import { ResponseRatesComponent } from './components/reponse-rates/response-rates.component';
import { FeedbackSubsidiariesComponent } from './components/feedback-subsidiaries/feedback-subsidiaries.component';
import { FeedbackCommentsComponent } from './components/feedback-comments/feedback-comments.component';

const components = [
  ServiceProgressStatusComponent,
  ServicesSelectorComponent,
  AdditionalServicesSelectorComponent,
  StorageUsageComponent,
  StorageUsageChartComponent,
  ResponseRatesComponent,
  FeedbackSubsidiariesComponent,
  FeedbackCommentsComponent
];

@NgModule({
  imports: [SharedModule, FormsModule, K2ChartsModule],
  declarations: components,
  exports: components
})
export class ServicesModule {}
