import {
  Directive,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { equals, mapObjIndexed } from 'ramda';

/**
 * Renders a component described in a given `spec`.
 *
 * EXAMPLE:
 *   const spec = {
 *     component: UserIngot,
 *     inputs: {
 *       user: { id: 1, first_name: 'John' }
 *     }
 *   }
 *
 *   <ng-template component-host [spec]="spec"></ng-template>
 */
@Directive({
  selector: '[component-host]'
})
export class ComponentHostDirective implements OnChanges {
  @Input() spec: ComponentSpec;

  constructor(
    private viewContainerRef: ViewContainerRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (equals(changes.spec.previousValue, changes.spec.currentValue)) return;

    this.viewContainerRef.clear();

    const componentRef = this.viewContainerRef.createComponent(this.spec.component);
    const { instance } = componentRef;

    Object.assign(instance, this.spec.inputs);

    if (instance.ngOnChanges == null) return;
    instance.ngOnChanges(
      mapObjIndexed(value => new SimpleChange(undefined, value, true), this.spec.inputs)
    );
  }
}
