import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isNotNil } from '@k2/common/helpers';
import { ValidationRule } from '@k2/common/k2-forms-state/types';
import { appConfig } from '@k2/common/app-config';
import {
  isFloat,
  isFloatTooLong,
  isInteger,
  isIntegerTooLong,
  isPasswordStrengthEnough
} from '@k2/common/k2-forms/validation-functions';

const minPasswordLength = appConfig.password.min_length;
const minPasswordNumbers = appConfig.password.min_numbers;
const minPasswordSymbols = appConfig.password.min_symbols;

export function toValidators(validationRules: ValidationRule[] = []): ValidatorFn[] {
  return validationRules.map(spec => validators[spec.rule]).filter(isNotNil);
}

const validators: Record<string, ValidatorFn> = {
  Required: requiredValidator,
  RequiredAddress1: requiredAddress1Validator,
  RequiredCity: requiredCityValidator,
  Email: emptyOr(emailValidator),
  IsNumber: emptyOr(floatValidator),
  IsInteger: emptyOr(integerValidator),
  Positive: emptyOr(positiveValidator),
  Timeslot: timeslotValidator
};

export function emptyOr(validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.value) return null;
    return validator(control);
  };
}

export function requiredValidator(control: AbstractControl) {
  const result = Validators.required(control);
  if (result == null) return null;
  return { required: 'This is a required field' };
}

export function requiredAddress1Validator({ value }: AbstractControl): ValidationErrors {
  if (value && value.address1) return null;
  return { requiredAddress1: 'Field should contain address line 1' };
}

export function requiredCityValidator({ value }: AbstractControl): ValidationErrors {
  if (value && value.city) return null;
  return { requiredCity: 'Field should contain city' };
}

export function emailValidator(control: AbstractControl) {
  const result = Validators.email(control);
  if (result == null) return null;
  return { email: 'Field should contain a valid email address' };
}

export function floatValidator({ value }: AbstractControl): ValidationErrors {
  if (isFloatTooLong(value)) return { numberTooLong: 'Field should contain 10 digits or less' };
  if (!isFloat(value)) return { float: 'Field should contain 4 decimals or less' };
  return null;
}

export function passwordStrengthValidator({ value }: AbstractControl): ValidationErrors {
  if (!isPasswordStrengthEnough(value)) {
    return {
      passwordStrength: `Password must contain at least ${minPasswordLength} characters, including ${minPasswordNumbers} ${
        minPasswordNumbers > 1 ? 'numbers' : 'number'
      } and ${minPasswordSymbols} ${minPasswordSymbols > 1 ? 'symbols' : 'symbol'}.`
    };
  }
  return null;
}

export function passwordsMatchValidator({ value }: AbstractControl): ValidationErrors {
  const values = Object.values(value);
  if (values.some(v => v !== values[0])) {
    return { passwordsEqual: `Passwords don't match` };
  }
  return null;
}

export function timeslotValidator({ value }: AbstractControl): ValidationErrors {
  if (value && value.start && isInteger(value.start)) {
    if (value.end && isInteger(value.end) && value.end < value.start)
      return { min: 'End time must be the same or after start time.' };

    if (value.end == null) return { required: 'End time is a missing' };
  }
  return null;
}

function integerValidator({ value }: AbstractControl): ValidationErrors {
  if (isIntegerTooLong(value)) return { numberTooLong: 'Field should contain 11 digits or less' };
  if (!isInteger(value)) return { integer: 'Field should be a whole number' };
  return null;
}

function positiveValidator({ value }: AbstractControl): ValidationErrors {
  if (value < 0) return { positive: 'Field should be a positive number' };
  return null;
}
