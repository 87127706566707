import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BACK, Back, FORWARD, Forward, NAVIGATE, Navigate } from '@k2/common/routing/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoutingEffects {
  constructor(private actions: Actions, private router: Router, private location: Location) {}

  
  navigate = createEffect(() => this.actions.pipe(
    ofType<Navigate>(NAVIGATE),
    tap(({ absolutePath, extras }) => {
      this.router.navigate([absolutePath], extras);
    })
  ), { dispatch: false });

  
  navigateBack = createEffect(() => this.actions.pipe(
    ofType<Back>(BACK),
    tap(() => this.location.back())
  ), { dispatch: false });

  
  navigateForward = createEffect(() => this.actions.pipe(
    ofType<Forward>(FORWARD),
    tap(() => this.location.forward())
  ), { dispatch: false });
}
