import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '@k2/common/entities-state/types';

@Pipe({
  name: 'currencyName'
})
export class CurrencyNamePipe implements PipeTransform {
  transform(currency: Currency): string {
    if (!currency) return;

    if (currency.iso3 !== currency.symbol) return `${currency.iso3} ${currency.symbol}`;

    return currency.iso3;
  }
}
