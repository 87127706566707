import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { addValidators } from '@k2/common/k2-forms/field-control/utils';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';

@Component({
  selector: 'quoted-product-control',
  templateUrl: 'quoted-product-control.component.html',
  styleUrls: ['quoted-product-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuotedProductControlComponent implements OnChanges {
  @Input() field: Field<Value[]>;
  values: Value[];

  ngOnChanges(): void {
    this.values = this.field.control.value;

    if (this.values.length === 0) this.addEmptyValue();

    addValidators(this.field.control, this.customValidator);
    this.field.control.updateValueAndValidity();
  }

  customValidator = (c: AbstractControl): ValidationErrors => {
    const values: Value[] = this.field.control.value;
    if (values.every(isValueValid)) return null;
    return { required: true };
  };

  get disabled() {
    return this.field.control.disabled;
  }

  getProductOptions = (selectedId: number): ValueOption[] => {
    const selectedIds = this.values.map(({ id }) => id);
    return this.field.attributes.valueOptions
      .filter(({ id }) => id === selectedId || !selectedIds.includes(id))
      .map(({ id, name }) => ({ id, value: name }));
  };

  get currencyOptions(): K2SelectOption[] {
    return this.field.attributes.currencies.map(({ id, iso3 }) => ({
      id,
      text: iso3
    }));
  }

  touch = () => {
    this.field.control.markAsTouched();
  };

  updateProduct = (index: number, productId: number) => {
    const product = this.field.attributes.valueOptions.find(({ id }) => id === productId);
    this.updateValue(index, value => ({ ...value, id: product.id, product_name: product.name }));
  };

  updateCurrency = (index: number, currencyId: number) => {
    this.updateValue(index, value => ({ ...value, currencies_id: currencyId }));
  };

  updateDescription = (index: number, description: string) => {
    this.updateValue(index, value => ({ ...value, description }));
  };

  updateAmount = (index: number, amount: number) => {
    this.updateValue(index, value => ({ ...value, amount }));
  };

  private updateValue = (index: number, fn: (current: Value) => Value) => {
    const values = [...this.values];
    values.splice(index, 1, fn(values[index]));
    this.setValues(values);
  };

  addEmptyValue = () => {
    const value = createEmptyValue(this.field.attributes.defaultCurrencyId);
    this.setValues([...this.values, value]);
  };

  removeValue = (index: number) => {
    const values = [...this.values];
    values.splice(index, 1);

    if (values.length === 0) {
      this.setValues([createEmptyValue(this.field.attributes.defaultCurrencyId)]);
    } else {
      this.setValues(values);
    }
  };

  isRemovableValue = (value: Value) => {
    if (value.required) return false;
    if (isNotEmptyValue(value)) return true;
    return this.values.length > 1;
  };

  canAddEmptyValue = () => {
    return isNotEmptyValue(this.values[this.values.length - 1]);
  };

  extractId = (index, { id }) => id;

  private setValues = (values: Value[]) => {
    this.values = values;
    this.field.control.setValue(values.filter(isNotEmptyValue));
  };
}

function createEmptyValue(defaultCurrencyId: number): Value {
  return {
    id: null,
    product_name: null,
    currencies_id: defaultCurrencyId,
    amount: null,
    required: false
  };
}

function isValueValid({ id, amount, currencies_id, required }: Value) {
  if (id == null) return false;
  if (required && currencies_id == null && amount != null) return false;
  if (!required && (currencies_id == null || amount == null)) return false;
  return true;
}

function isNotEmptyValue({ required, ...value }: Value) {
  return Object.keys(value)
    .map(key => value[key])
    .some(v => v != null);
}

interface Value {
  readonly id: number;
  readonly product_name: string;
  readonly currencies_id: number;
  readonly amount: number;
  readonly required: boolean;
  readonly description?: string;
}
