import { isNotNil } from '@k2/common/helpers';
import { EntityId } from '@k2/common/entities-state/entity';
import { RootState } from '@k2/common/entities-state/state';
import { denormalize, schema } from 'normalizr';
import { curry, filter } from 'ramda';
import { createSelector } from 'reselect';

const filterUndefinedOut = filter(isNotNil);

const getEntities = (state: RootState) => state.entities;

export function getDenormalized<T = any>(schema: schema.Entity, ids?: EntityId[]) {
  if (ids == null) return state => undefined;

  const { key } = schema;

  return createSelector(getEntities, (entities): T[] => {
    if (entities == null || entities[key] == null) return [];

    const input = { [key]: ids };
    const denormalized = denormalize(input, { [key]: [schema] }, entities);

    return filterUndefinedOut(denormalized[key]);
  });
}

/**
 * @deprecated
 */
export function getAllDenormalized<T = any>(schema: schema.Entity) {
  const { key } = schema;

  return createSelector(getEntities, (entities): T[] => {
    if (entities == null || entities[key] == null) return [];

    const input = { [key]: Object.keys(entities[key]) };
    const denormalized = denormalize(input, { [key]: [schema] }, entities);

    return filterUndefinedOut(denormalized[key]);
  });
}

export const getOneDenormalized = curry(<T = any>(schema: schema.Entity, id: EntityId) => {
  const { key } = schema;

  return createSelector(getEntities, (entities): T => {
    if (entities == null || entities[key] == null) return;

    const input = { [key]: [id] };
    const denormalized = denormalize(input, { [key]: [schema] }, entities);
    return denormalized[key][0];
  });
});

export function getDenormalizedObject<T = any>(schema: schema.Object, input: any) {
  return createSelector(getEntities, (entities): T => {
    if (entities == null) return;

    return denormalize(input, schema, entities);
  });
}
