import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { appConfig } from '@k2/common/app-config';
import { isNotNil } from '@k2/common/helpers';
import {
  DocumentsDialogComponent,
  DocumentsDialogData
} from '@k2/common/documents/components/documents-dialog/documents-dialog.component';
import { interval, Observable, race } from 'rxjs';
import { filter, first, flatMap } from 'rxjs/operators';

const config = appConfig.dialog;

@Injectable()
export class DocumentsDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Opens a documents dialog.
   */
  open = <T>(data: DocumentsDialogData): Observable<T> => {
    return race(data.documents, interval(config.maxDelay)).pipe(
      first(),
      flatMap(() =>
        this.dialog.open(DocumentsDialogComponent, { width: config.width, data }).afterClosed()
      ),
      filter(isNotNil)
    );
  };
}
