import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Partner } from '@k2/common/entities-state/types';
import { PartnerAutocompleteOptionComponent } from '@k2/common/partners/component/partner-autocomplete-option/partner-autocomplete-option.component';
import { PartnerIngotComponent } from '@k2/common/partners/component/partner-ingot/partner-ingot.component';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { AutocompleteSelectorConfig } from '@k2/common/ui/components/autocomplete-selector/autocomplete-selector.component';

@Component({
  selector: 'partners-selector',
  templateUrl: 'partners-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnersSelectorComponent implements OnChanges {
  @Input() selected: Partner[];
  @Input() config: PartnersSelectorConfig;
  @Output() selectedChange = new EventEmitter<Partner[]>();

  selectorConfig: AutocompleteSelectorConfig<Partner>;

  constructor(private backend: Backend) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config == null) return;

    this.selectorConfig = {
      toId: (partner: Partner) => partner.id,
      toName: (partner: Partner) => partner.name,
      query: (query: string) => this.backend.partners.searchChildren(query),
      toSelectedSpec: (partner: Partner): ComponentSpec<PartnerIngotComponent> => ({
        component: PartnerIngotComponent,
        inputs: { partner }
      }),
      toOptionSpec: (
        partner: Partner,
        query: string
      ): ComponentSpec<PartnerAutocompleteOptionComponent> => ({
        component: PartnerAutocompleteOptionComponent,
        inputs: { partner, highlight: query }
      }),
      multiple: this.config.multiple,
      placeholder: this.config.placeholder
    };
  }

  update = (selected: Partner[]) => {
    this.selectedChange.emit(selected);
  };
}

export interface PartnersSelectorConfig {
  readonly multiple: boolean;
  readonly placeholder?: string;
}
