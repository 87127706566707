import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Location } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { LocationSelectorConfig } from '@k2/common/locations/components/locations-selector/locations-selector.component';

@Component({
  selector: 'locations-selector-control',
  templateUrl: 'locations-selector-control.component.html'
})
export class LocationsSelectorControlComponent implements OnChanges {
  @Input() field: Field;
  @Input() regionId: number | number[];
  @Input() hasMultipleRegions: boolean = false;

  selected: Location[];
  config: LocationSelectorConfig;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.field) return;
    const value = this.field.defaultValue;
    const multiple = Array.isArray(value);

    this.config = {
      multiple: multiple || this.hasMultipleRegions,
      placeholder: this.field.attributes.placeholder
    };

    if (!changes.field.isFirstChange()) return;
    const { displayValue } = this.field.attributes;

    if (displayValue) {
      this.update(multiple ? displayValue : [displayValue]);
    } else {
      this.update([]);
    }
  }

  update = (selected: Location[]) => {
    this.selected = selected;

    if (this.config.multiple) {
      this.field.control.patchValue(selected.map(({ id }) => id));
    } else {
      this.field.control.patchValue(selected.length === 0 ? null : selected[0].id);
    }
  };
}
