import { Component, Input } from '@angular/core';
import { Airport } from './airport';

@Component({
  selector: 'airport-ingot',
  templateUrl: 'airport-ingot.component.html',
  styleUrls: ['airport-ingot.component.scss']
})
export class AirportIngotComponent {
  @Input() airport: Airport;
}
