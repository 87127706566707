import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@k2/common/entities-state/types';

@Component({
  selector: 'formatted-address',
  templateUrl: 'formatted-address.component.html',
  styleUrls: ['formatted-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormattedAddressComponent {
  @Input() address: Address;
  @Input() reducedFormat?: boolean = false;
  @Input() isAssignmentEncrypted: boolean = true;
}
