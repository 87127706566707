import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AssignmentServiceProgress,
  AssignmentServiceStatus
} from '@k2/common/entities-state/types';

@Component({
  selector: 'service-progress-status',
  templateUrl: 'service-progress-status.component.html',
  styleUrls: ['service-progress-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceProgressStatusComponent {
  @Input() progress: AssignmentServiceProgress;

  get status(): AssignmentServiceStatus {
    return this.progress.value;
  }

  get tooltip(): string {
    if (this.progress.label) return `${this.progress.label} - ${this.progress.value}`;
    return this.progress.value;
  }
}
