import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RemoteMutatedFile } from '@k2/common/file-jet/file';
import { UrlGenerator } from '@k2/common/file-jet/url-generator.service';

@Component({
  selector: 'file-jet-img',
  templateUrl: 'file-jet-img.component.html',
  styleUrls: ['file-jet-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileJetImgComponent {
  @Input() image: RemoteMutatedFile;
  @Input() width: number;
  @Input() height: number;
  @Input() alt: string;

  constructor(private urlGenerator: UrlGenerator) {}

  get src(): string {
    return this.toUrl(1);
  }

  get srcset(): string {
    return `${this.toUrl(2)} 2x, ${this.toUrl(3)} 3x, ${this.toUrl(4)} 4x`;
  }

  private toUrl = (pixelRatio: number) => {
    const width = this.width ? this.width * pixelRatio : '';
    const height = this.height ? this.height * pixelRatio : '';
    const mutation = `resize_${width}x${height},bg_white`;

    return this.urlGenerator.generateUrl(this.image, mutation);
  };
}
