export function toCropMutation(cropData, canvasData, aspectRatio: number): string {
  const mutations = [crop(cropData, canvasData), ...fill(cropData, aspectRatio)];

  return mutations.join(',');
}

function crop(cropData, canvasData): string {
  const width = (cropData.width / canvasData.naturalWidth).toFixed(4);
  const height = (cropData.height / canvasData.naturalHeight).toFixed(4);
  const x = (cropData.x / canvasData.naturalWidth).toFixed(4);
  const y = (cropData.y / canvasData.naturalHeight).toFixed(4);

  return `crop_${width}x${height}_${x}_${y}`;
}

function* fill(cropData, requiredRatio: number): IterableIterator<string> {
  const currentRatio = cropData.width / cropData.height;

  if (requiredRatio !== currentRatio) {
    yield 'pos_center';
  }

  if (requiredRatio > currentRatio) {
    // Adds left and right padding
    yield `fill_${((cropData.height * requiredRatio) / cropData.width).toFixed(4)}x1.0`;
  } else if (requiredRatio < currentRatio) {
    // Adds top and bottom padding
    yield `fill_1.0x${(cropData.width / requiredRatio / cropData.height).toFixed(4)}`;
  }
}
