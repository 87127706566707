import { replayLast } from '@k2/common/helpers';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

/**
 * A default paginator.
 *
 * Provides a current state and function, which can alternate this state.
 */
export abstract class Paginator {
  /**
   * Current paginator state.
   */
  readonly state: Observable<PaginatorState>;

  /**
   * Updates a paginator state.
   *
   * Provided function will be called with a current paginator state.
   */
  updateBy: (fn: (state: PaginatorState) => Instruction) => void;

  /**
   * Creates a paginator with a page size adapted for table.
   */
  static table() {
    return new VariableLengthPaginator({ pageSize: 25 });
  }

  /**
   * Creates a paginator with a page size adapted for small table.
   */
  static smallTable() {
    return new VariableLengthPaginator({ pageSize: 6 });
  }

  /**
   * Creates a paginator with a page size adapted for components list.
   */
  static components() {
    return new VariableLengthPaginator({ pageSize: 10 });
  }

  /**
   * Creates a paginator with a page size adapted for ingots list.
   */
  static ingots() {
    return new VariableLengthPaginator({ pageSize: 9 });
  }

  /**
   * Creates a paginator with a custom page size.
   */
  static custom(config: VariableLengthPaginatorConfig) {
    return new VariableLengthPaginator(config);
  }
}

/**
 * Returns TRUE, when paginator represents items, which are paginated.
 *
 * If a number of items is lower then a single page length,
 * then items are not paginated and this function will return FALSE.
 */
export function isPaginated(state: PaginatorState): boolean {
  return state.length > state.limit;
}

/**
 * Returns TRUE, when a number of paginator pages is 2 or more.
 */
export function hasMultiplePages(state: PaginatorState): boolean {
  return toNumberOfPages(state) > 1;
}

/**
 * Returns total number of paginator pages.
 */
export function toNumberOfPages(state: PaginatorState): number {
  return Math.ceil(state.length / state.pageSize);
}

/**
 * Returns a current page number.
 */
export function toActivePage(state: PaginatorState): number {
  return Math.ceil(state.offset / state.pageSize);
}

/**
 * A default Paginator implementation.
 *
 * Usually constructed by a static Paginator's method.
 * Represents a paginator with a variable length.
 * Each length update leads to new paginator state.
 */
export class VariableLengthPaginator implements Paginator {
  readonly state: Observable<PaginatorState>;

  private stateSubject: Subject<PaginatorState>;

  constructor(private config: VariableLengthPaginatorConfig) {
    const { pageSize } = config;

    this.stateSubject = new BehaviorSubject({ offset: 0, limit: pageSize, pageSize, length: null });

    this.state = this.stateSubject.pipe(
      filter(state => state.length != null),
      replayLast()
    );
  }

  updateBy = (fn: (state: PaginatorState) => Instruction) => {
    this.stateSubject.pipe(first()).subscribe(state => {
      this.stateSubject.next({ ...state, ...fn(state) });
    });
  };

  updateLength = (newLength: number) => {
    this.stateSubject.pipe(first()).subscribe(({ pageSize, length, offset }) => {
      if (length === newLength) return;

      const shouldKeepOffset = this.config.keepOffsetAfterLengthChange && offset < newLength;

      this.stateSubject.next({
        offset: shouldKeepOffset ? offset : 0,
        limit: pageSize,
        length: newLength,
        pageSize
      });
    });
  };
}

export interface VariableLengthPaginatorConfig {
  readonly pageSize: number;
  readonly keepOffsetAfterLengthChange?: boolean;
}

/**
 * Represents a paginator state.
 */
export interface PaginatorState extends Instruction {
  readonly length: number;
  readonly pageSize: number;
}

/**
 * Represents a pagination instruction.
 *
 * Could be used to instruct a paginator to update its state.
 */
export interface Instruction {
  readonly offset: number;
  readonly limit: number;
}
