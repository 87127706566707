import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'deletable-item-options',
  styleUrls: ['deletable-item-options.component.scss'],
  templateUrl: 'deletable-item-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeletableItemOptionsComponent {
  @Output() delete = new EventEmitter();
  @Input() deleteLink?: string;

  showConfirmation = false;

  confirmDelete = () => {
    this.showConfirmation = false;
    this.delete.emit();
  };
}
