import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telephone'
})
export class TelephonePipe implements PipeTransform {
  transform(number: string | number): string {
    if (!number) return;

    const allCountriesData = intlTelInputGlobals.getCountryData();
    const defaultFormat = intlTelInputUtils.numberFormat.INTERNATIONAL;

    const countryData = allCountriesData.find(countryData => {
      return number.toString().startsWith('+' + countryData.dialCode);
    });

    if (countryData) {
      return intlTelInputUtils.formatNumber(number, countryData.iso2, defaultFormat);
    }
    return number.toString();
  }
}
