import { Component, Input } from '@angular/core';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import { normalizeValueOption } from '@k2/common/k2-forms/utils';

@Component({
  selector: 'select-control',
  templateUrl: 'select-control.component.html'
})
export class SelectControlComponent extends WithFormControl<number | string> {
  @Input() field: Field<number | string>;

  get options(): ValueOption[] {
    return this.field.attributes.valueOptions.map(normalizeValueOption);
  }

  get disabled(): boolean {
    return this.field.control.disabled;
  }
}
