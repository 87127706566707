import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * A single bit of an information with a label.
 */
@Component({
  selector: 'bit',
  templateUrl: 'bit.component.html',
  styleUrls: ['bit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BitComponent {
  @Input() label: string;
  @Input() multiLine: '' | boolean | undefined;
  @Input() standalone: '' | boolean | undefined;

  get isMultiLine(): boolean {
    return this.multiLine === '' || this.multiLine === true;
  }

  get isStandalone(): boolean {
    return this.standalone === '' || this.standalone === true;
  }
}
