import jsep from 'jsep';
import { both, complement, either, identity, map, memoizeWith, pipe } from 'ramda';

export const toPermissionsGuard = memoizeWith(identity, createPermissionsGuard);

function createPermissionsGuard(rawExpression: string): PermissionsGuard {
  const expression = jsep(normalizePermission(rawExpression));

  const guard = pipe(map(normalizePermission), asGuard(expression));

  return memoizeWith(identity, guard);
}

function normalizePermission(permission: string): string {
  return permission.replace(/\./g, '_');
}

/**
 * Transforms parsed expression to a function, which accepts available permissions.
 *
 * Permissions must be in the following format: "staff_user_edit"
 */
function asGuard(expression): PermissionsGuard {
  switch (expression.type) {
    case 'Identifier': {
      return (availablePermissions: string[]) => availablePermissions.includes(expression.name);
    }
    case 'UnaryExpression': {
      if (expression.operator === '!') return complement(asGuard(expression.argument));
      throw new Error(`${expression.operator} is not a valid unary operator!`);
    }
    case 'LogicalExpression': {
      if (expression.operator === '||')
        return either(asGuard(expression.left), asGuard(expression.right));
      if (expression.operator === '&&')
        return both(asGuard(expression.left), asGuard(expression.right));
      throw new Error(`${expression.operator} is not a valid logical operator!`);
    }
    default: {
      throw new Error(`${expression.type} is not a valid expression type!`);
    }
  }
}

export type PermissionsGuard = (availablePermissions: string[]) => boolean;
