import { isNotNil } from '@k2/common/helpers';
import { Field } from '@k2/common/k2-forms-state/types';

export function addressFormatter(field: Field): string {
  const value = field.control.value || {};

  const address = [
    value.address1,
    value.address2,
    value.address3,
    value.address4,
    value.city,
    value.county,
    value.postal_code
  ];

  return address.filter(isNotNil).join('\n');
}
