<h2 mat-dialog-title>Are you still here?</h2>

<mat-dialog-content>
  <p><strong>Your session will expire soon.</strong></p>
  <p>You will be logged out in {{ countdown }} seconds.</p>
</mat-dialog-content>

<mat-dialog-actions>
  <submit-control
    (onSubmit)="ping()"
    (cancel)="logout()"
    cancelLabel="log me out"
  >
    I'm still working
  </submit-control>
</mat-dialog-actions>
