import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() progress: number;

  get width(): string {
    return `${Number.isNaN(this.progress) ? 0 : this.progress}%`;
  }
}
