import { Pipe, PipeTransform } from '@angular/core';
import { alwaysPrimitive } from '@k2/common/helpers';
import { Office } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

@Pipe({ name: 'officeLink' })
export class OfficeLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(office: Office | number): any {
    if (office == null) return;

    return this.generator.generate({
      type: 'office',
      data: {
        officeId: alwaysPrimitive(office, o => o.id)
      }
    });
  }
}
