import { NgModule } from "@angular/core";
import { AngularChecklistReplacementDirective } from "./angular-checklist-replacement.directive";


@NgModule({
  imports: [],
  declarations: [AngularChecklistReplacementDirective],
  exports: [AngularChecklistReplacementDirective]
})
export class AngularChecklistReplacementModule {
}
