import { environment } from '@k2/environments/environment';

export const appConfig = {
  ...environment,
  supportEmail: 'ascentsupport@k2corporatemobility.com',
  dialog: {
    maxDelay: 300,
    width: '600px'
  },
  password: {
    min_length: 14,
    min_numbers: 1,
    min_symbols: 1
  }
};
