<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <div *ngFor="let pair of pairs">
    <span>{{ pair[0] }}:</span>
    <br *ngIf="pair[1].length > 16" />
    {{ pair[1] }}
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>
