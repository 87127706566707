import Color from 'color';

export const k2ChartColors = [
  '#97BACD',
  '#A0A7D5',
  '#9CD8B6',
  '#FFE3B1',
  '#6693AA',
  '#737CB5',
  '#6FBC90',
  '#FECC6D',
  '#41738C',
  '#4E579A',
  '#449E6B',
  '#EAA629'
];

// to draw graph for 2022 with orange color
export const k2ChartSpecialColors = [
  '#97BACD',
  '#A0A7D5',
  '#EAA629',
  '#9CD8B6',
  '#FFE3B1',
  '#6693AA',
  '#737CB5',
  '#6FBC90',
  '#FECC6D',
  '#41738C',
  '#4E579A',
  '#449E6B'
];

const basicColors = k2ChartColors.map(color => Color(color));

export const doughnutColors = basicColors.map(_ => ({
  borderColor: basicColors.map(c => c.lighten(0.7).string()),
  backgroundColor: basicColors.map(c => c.string()),
  hoverBackgroundColor: basicColors.map(c => c.string()),
  hoverBorderColor: basicColors.map(c => c.string())
}));

export const lineColors = basicColors.map(color => ({
  borderColor: color.string(),
  backgroundColor: color.string(),
  pointBorderColor: color.string(),
  pointBackgroundColor: color.string(),
  pointHoverBorderColor: color.string(),
  pointHoverBackgroundColor: color.string()
}));

export const lineYearlyInitiationsColors = k2ChartSpecialColors.map(color => {
  const COLOR = new Color(color);

  return {
    borderColor: COLOR.string(),
    backgroundColor: COLOR.string(),
    pointBorderColor: COLOR.string(),
    pointBackgroundColor: COLOR.string(),
    pointHoverBorderColor: COLOR.string(),
    pointHoverBackgroundColor: COLOR.string()
  };
});

export const barColors = basicColors.map(color => ({
  borderColor: color.string(),
  backgroundColor: color.string()
}));
