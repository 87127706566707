<ng-container *ngIf="!underConfirmation">
  <div class="options">
    <ng-content></ng-content>
    <a *ngIf="removable" class="remove" (click)="requestConfirmation()">
      <action>remove</action>
    </a>
  </div>
</ng-container>

<ng-container *ngIf="underConfirmation">
  <div class="confirm-question">Are you sure?</div>
  <div class="options remove">
    <a (click)="confirm()"><action type="ok">yes</action></a>
    <a (click)="cancel()" class="cancel"><action type="cancel">no</action></a>
  </div>
</ng-container>
