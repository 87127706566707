import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@k2/common/entities-state/types';

@Component({
  selector: 'location-ingot',
  templateUrl: 'location-ingot.component.html',
  styleUrls: ['location-ingot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationIngotComponent {
  @Input() location: Location;
}
