import { A11yModule } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { DocumentsModule } from '@k2/common/documents/documents.module';
import { FieldControlModule } from '@k2/common/k2-forms/field-control/field-control.module';
import { FieldErrorsComponent } from '@k2/common/k2-forms/field-errors/field-errors.component';
import { FieldLabelComponent } from '@k2/common/k2-forms/field-label/field-label.component';
import { FieldNotesComponent } from '@k2/common/k2-forms/field-notes/field-notes.component';
import { FieldSpecModule } from '@k2/common/k2-forms/field-spec/field-spec.module';
import { FieldTemplateComponent } from '@k2/common/k2-forms/field-template/field-template.component';
import { FieldTooltipComponent } from '@k2/common/k2-forms/field-tooltip/field-tooltip.component';
import { FieldValueModule } from '@k2/common/k2-forms/field-value/field-value.module';
import { FieldComponent } from '@k2/common/k2-forms/field/field.component';
import { FieldsComponent } from '@k2/common/k2-forms/fields/fields.component';
import { CustomFormFieldsComponent } from '@k2/common/k2-forms/fields/custom-form-fields.component';
import { PickCustomFieldsPipe } from '@k2/common/k2-forms/fields/pick-custom-fields.pipe';
import { DocumentUploadFieldsComponent } from '@k2/common/k2-forms/fieldsets/document-upload-fields/document-upload-fields.component';
import { EmailFieldsComponent } from '@k2/common/k2-forms/fieldsets/email-fields/email-fields.component';
import { LocalityFieldsComponent } from '@k2/common/k2-forms/fieldsets/locality-fields/locality-fields.component';
import { FormDialogComponent } from '@k2/common/k2-forms/form-dialog/form-dialog.component';
import { FormDialogService } from '@k2/common/k2-forms/form-dialog/form-dialog.service';
import { SelfSubmittingFieldViewComponent } from '@k2/common/k2-forms/self-submitting-field/self-submitting-field-view.component';
import { SelfSubmittingFieldComponent } from '@k2/common/k2-forms/self-submitting-field/self-submitting-field.component';
import { SelfSubmittingNameFieldsComponent } from '@k2/common/k2-forms/self-submitting-field/self-submitting-name-fields.component';
import { SelfSubmittingPasswordFieldComponent } from '@k2/common/k2-forms/self-submitting-field/self-submitting-password-field.component';
import { FloatValidatorDirective } from '@k2/common/k2-forms/validator-directives/float-validator.directive';
import { SharedModule } from '@k2/common/shared.module';
import { UploadsModule } from '@k2/common/uploads/uploads.module';

const components = [
  FieldComponent,
  FieldTemplateComponent,
  FieldsComponent,
  CustomFormFieldsComponent,
  SelfSubmittingFieldComponent,
  SelfSubmittingFieldViewComponent,
  SelfSubmittingNameFieldsComponent,
  SelfSubmittingPasswordFieldComponent,
  FieldErrorsComponent,
  FieldLabelComponent,
  FieldTooltipComponent,
  FieldNotesComponent,
  FormDialogComponent,
  LocalityFieldsComponent,
  DocumentUploadFieldsComponent,
  EmailFieldsComponent
];

const directives = [FloatValidatorDirective];

const pipes = [PickCustomFieldsPipe];

@NgModule({
  imports: [
    SharedModule,
    A11yModule,
    FieldControlModule,
    FieldValueModule,
    FieldSpecModule,
    DocumentsModule,
    UploadsModule
  ],
  declarations: [...components, ...directives, ...pipes],
  exports: [
    FieldControlModule,
    FieldValueModule,
    FieldSpecModule,
    ...components,
    ...directives,
    ...pipes
  ],
  providers: [FormDialogService]
})
export class K2FormsModule {}
