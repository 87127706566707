import { Actions, ADD_PERMISSIONS } from '@k2/common/permissions-state/actions';
import { RootState, State } from '@k2/common/permissions-state/state';
import { normalizeUrl } from '@k2/common/permissions-state/utils';
import { getCurrentUrl } from '@k2/common/routing/selectors';
import { createSelector } from 'reselect';

export function featureReducer(state: State = {}, action: Actions): State {
  switch (action.type) {
    case ADD_PERMISSIONS: {
      const existing = state[action.normalizedUrl] || [];
      const permissions = new Set([...existing, ...action.permissions]);

      return { ...state, [action.normalizedUrl]: [...permissions] };
    }
    default: {
      return state;
    }
  }
}

export const getPermissions = createSelector(
  [getCurrentUrl, (state: RootState) => state.permissions],
  (currentUrl, permissionsState) => {
    // TODO: Redirect to a page with guard will update a currentUrl.
    // However, visible page is still the old one so, permissions for an old page will be wrong for a while.

    const normalizedUrl = normalizeUrl(currentUrl);
    return permissionsState[normalizedUrl] || [];
  }
);
