import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'k2-logo',
  templateUrl: 'k2-logo.component.html',
  styleUrls: ['k2-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2LogoComponent {
  @Input() link: any;
}
