<div class="list">
  <label>{{ deselectedLabel }}:</label>
  <ul>
    <li
      *ngFor="let region of notSelectedRegions"
      (click)="toggleInLeftSelection(region.id)"
      [class.selected]="leftSelection.has(region.id)"
    >
      <country-flag [code]="region.country_iso"></country-flag>
      {{ region.name }}
    </li>
  </ul>
</div>

<div *ngIf="!readonly" class="buttons">
    <btn (click)="selectAll()" [disabled]="disabled" title="Make all available">
      <span>&gt;&gt;</span>
    </btn>
    <btn (click)="select()" [disabled]="disabled" title="Make selected available">
      <span>&gt;</span>
    </btn>
    <btn
      (click)="deselect()"
      [disabled]="disabled"
      title="Make selected not-available"
    >
      <span>&lt;</span>
    </btn>
    <btn
      (click)="deselectAll()"
      [disabled]="disabled"
      title="Make all not-available"
    >
      <span>&lt;&lt;</span>
    </btn>
</div>

<div *ngIf="readonly" class="blank"></div>

<div class="list">
  <label>{{ selectedLabel }}:</label>
  <ul>
    <li
      *ngFor="let region of selectedRegions"
      (click)="toggleInRightSelection(region.id)"
      [class.selected]="rightSelection.has(region.id)"
    >
      <country-flag [code]="region.country_iso"></country-flag>
      {{ region.name }}
    </li>
  </ul>
</div>
