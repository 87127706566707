import { Injectable } from '@angular/core';
import { appConfig } from '@k2/common/app-config';
import { isNotNil } from '@k2/common/helpers';
import { RemoteMutatedFile } from '@k2/common/file-jet/file';

const { storageId, customDomain } = appConfig.filejet;

/**
 * Url generator service.
 * Generates a real URL from file's identifier and mutation.
 */
@Injectable({ providedIn: 'root' })
export class UrlGenerator {
  /**
   * Generates a file's URL.
   *
   * `file.mutation` can be extended by `mutation`.
   */
  generateUrl = (file: RemoteMutatedFile, additionalMutation?: string): string => {
    if (file == null || file.identifier == null) return;

    const mutation = [file.mutation, additionalMutation, 'auto'].filter(isNotNil).join(',');
    const domain = customDomain || `${storageId}.5gcdn.net`;
    return `${domain}/${file.identifier}/${mutation}`;
  };
}
