<span class="description">Status:</span>

<span *ngIf="isEditable" class="items read-only">
  <span
    *ngFor="let status of uncompletedStatuses"
    class="btn {{ status.type_value | className }}"
    [class.active]="isActive(status)"
  >
    {{ status.type_label }}
  </span>

  <span
    class="btn {{ completedStatus.type_value | className }}"
    [class.active]="isActive(completedStatus)"
  >
    {{ completedStatus.type_label }}
  </span>
</span>

<span *ngIf="!isEditable" class="items">
  <button
    *ngFor="let status of uncompletedStatuses"
    [disabled]="status.type_value === 'INITIATED'"
    (click)="tryUpdate(status)"
    class="btn {{ status.type_value | className }}"
    [class.active]="isActive(status)"
  >
    {{ status.type_label }}
  </button>

  <button
    routerLink="options/close"
    class="btn {{ completedStatus.type_value | className }}"
    [class.active]="isActive(completedStatus)"
  >
    {{ completedStatus.type_label }}
  </button>
</span>
