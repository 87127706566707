import { NgModule } from '@angular/core';
import { TextSuggestionComponent } from '@k2/common/autocomplete/components/text-suggestion.component';
import { SharedModule } from '@k2/common/shared.module';

const components = [TextSuggestionComponent];

@NgModule({
  imports: [SharedModule],
  declarations: components,
  exports: components
})
export class AutocompleteModule {}
