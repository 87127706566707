import {
  Actions,
  CHANGE_ASSIGNMENT_STATUS,
  STORE_ASSIGNMENT,
  STORE_UNASSIGNED_ASSIGNMENTS
} from '@k2/common/assignments-state/actions';
import { assignmentSchema } from '@k2/common/assignments-state/normalizer-schemas';
import { LoadedAssignmentsState, RootState, State } from '@k2/common/assignments-state/state';
import { getDenormalized, getOneDenormalized } from '@k2/common/entities-state/selectors';
import { Assignment } from '@k2/common/entities-state/types';
import { combineReducers, Selector } from '@ngrx/store';
import { identity, mergeDeepRight } from 'ramda';
import { createSelector } from 'reselect';

export function rootReducer(state: RootState, action: Actions): RootState {
  switch (action.type) {
    case CHANGE_ASSIGNMENT_STATUS: {
      return mergeDeepRight(state, {
        entities: {
          assignments: {
            [action.assignmentId]: {
              status: action.newStatus
            }
          }
        }
      });
    }
    default: {
      return state;
    }
  }
}

export const featureReducer = combineReducers<State>({
  loaded: loadedReducer,
  unassigned: unassignedReducer
});

export function loadedReducer(
  state: LoadedAssignmentsState = {},
  action: Actions
): LoadedAssignmentsState {
  if (action.type === STORE_ASSIGNMENT) {
    return { ...state, [action.id]: { loaded: true } };
  }
  return state;
}

export function getAssignment(id: number): Selector<RootState, Assignment> {
  return createSelector(
    [(state: RootState) => state.assignments.loaded[id], identity],
    (assignmentState, state: RootState) => {
      if (assignmentState != null && assignmentState.loaded)
        return getOneDenormalized(assignmentSchema, id)(state);
    }
  );
}

export function unassignedReducer(state: number[], action: Actions): number[] {
  if (action.type === STORE_UNASSIGNED_ASSIGNMENTS) {
    return action.ids;
  }
  return state;
}

export const getUnassignedAssignments: Selector<RootState, Assignment[]> = createSelector(
  [(state: RootState) => state.assignments.unassigned, identity],
  (ids, state) => getDenormalized(assignmentSchema, ids)(state)
);
