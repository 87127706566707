import { DatePipe } from '@angular/common';
import { InjectionToken, Provider } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { FieldTextValueComponent } from '@k2/common/k2-forms/field-value/field-text-value.component';
import { addressFormatter } from '@k2/common/k2-forms/field-value/formatters/address';
import { booleanFormatter } from '@k2/common/k2-forms/field-value/formatters/boolean';
import { dateFormatter } from '@k2/common/k2-forms/field-value/formatters/date';
import { multiOptionsFormatter } from '@k2/common/k2-forms/field-value/formatters/multi-options';
import { multiSelectFormatter } from '@k2/common/k2-forms/field-value/formatters/multi-select';
import { optionsFormatter } from '@k2/common/k2-forms/field-value/formatters/options';
import { telephoneFormatter } from '@k2/common/k2-forms/field-value/formatters/telephone';
import { textFormatter } from '@k2/common/k2-forms/field-value/formatters/text';
import { textareaFormatter } from '@k2/common/k2-forms/field-value/formatters/textarea';
import { k2UserFormatter } from '@k2/common/k2-forms/field-value/formatters/user';
import { LinkGenerator } from '@k2/common/links/link-generator';
import { TelephonePipe } from '@k2/common/telephone/pipes/telephone.pipe';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { curry, fromPairs } from 'ramda';
import { PartnerFormatter } from '@k2/common/k2-forms/field-value/formatters/partner';

export const FIELD_VALUE_FORMATTERS = new InjectionToken('Field value formatters');
export const FIELD_VALUE_FORMATTERS_LIST = new InjectionToken('Field value formatters list');

export const FORMATTERS_PROVIDER: Provider = {
  provide: FIELD_VALUE_FORMATTERS,
  useFactory: createFormatters,
  deps: [FIELD_VALUE_FORMATTERS_LIST]
};

export function createFormatters(list: Array<{ type: string; formatter: Formatter<any> }>) {
  const pairs = list.map(formatter => [formatter.type, formatter.formatter]);
  return fromPairs(pairs as any);
}

export const FORMATTERS_LIST_PROVIDER: Provider[] = [
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: selectFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: select2FormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: multiSelectFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: textFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: emailFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: numberFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: multidateFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: datetimeFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: timeSlotFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: textareaFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: yesnoFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: addressFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: radioFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: checkboxFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: checkboxesFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: budgetFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: contactCardsFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: pricingCardsFormatterFactory, multi: true },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: labelFormatterFactory, multi: true },
  {
    provide: FIELD_VALUE_FORMATTERS_LIST,
    useFactory: airportSelectorFormatterFactory,
    multi: true
  },
  { provide: FIELD_VALUE_FORMATTERS_LIST, useFactory: hiddenFormatterFactory, multi: true },
  {
    provide: FIELD_VALUE_FORMATTERS_LIST,
    useFactory: telFormatterFactory,
    deps: [TelephonePipe],
    multi: true
  },
  {
    provide: FIELD_VALUE_FORMATTERS_LIST,
    useFactory: dateFormatterFactory,
    deps: [DatePipe],
    multi: true
  },
  {
    provide: FIELD_VALUE_FORMATTERS_LIST,
    useFactory: staffSelectorFormatterFactory,
    deps: [LinkGenerator],
    multi: true
  },
  {
    provide: FIELD_VALUE_FORMATTERS_LIST,
    useFactory: partnersSelectorFormatterFactory,
    deps: [LinkGenerator],
    multi: true
  }
];

export function selectFormatterFactory() {
  return { type: 'select', formatter: toTextFormatter(optionsFormatter) };
}

export function select2FormatterFactory() {
  return { type: 'select2', formatter: toTextFormatter(optionsFormatter) };
}

export function multiSelectFormatterFactory() {
  return { type: 'multiSelect', formatter: toTextFormatter(multiSelectFormatter) };
}

export function textFormatterFactory() {
  return { type: 'text', formatter: toTextFormatter(textFormatter) };
}

export function emailFormatterFactory() {
  return { type: 'email', formatter: toTextFormatter(textFormatter) };
}

export function numberFormatterFactory() {
  return { type: 'number', formatter: toTextFormatter(textFormatter) };
}

export function multidateFormatterFactory() {
  return { type: 'multidate', formatter: null };
}

export function datetimeFormatterFactory() {
  return { type: 'datetime', formatter: null };
}

export function timeSlotFormatterFactory() {
  return { type: 'timeSlot', formatter: null };
}

export function textareaFormatterFactory() {
  return { type: 'textarea', formatter: toTextFormatter(textareaFormatter) };
}

export function yesnoFormatterFactory() {
  return { type: 'yesno', formatter: toTextFormatter(booleanFormatter) };
}

export function addressFormatterFactory() {
  return { type: 'address', formatter: toTextFormatter(addressFormatter) };
}

export function radioFormatterFactory() {
  return { type: 'radio', formatter: toTextFormatter(optionsFormatter) };
}

export function checkboxFormatterFactory() {
  return { type: 'checkbox', formatter: toTextFormatter(booleanFormatter) };
}

export function checkboxesFormatterFactory() {
  return { type: 'checkboxes', formatter: toTextFormatter(multiOptionsFormatter) };
}

export function budgetFormatterFactory() {
  return { type: 'budget', formatter: null };
}

export function contactCardsFormatterFactory() {
  return { type: 'contactCards', formatter: null };
}

export function pricingCardsFormatterFactory() {
  return { type: 'pricingCards', formatter: null };
}

export function labelFormatterFactory() {
  return { type: 'label', formatter: null };
}

export function airportSelectorFormatterFactory() {
  return { type: 'airportSelector', formatter: null };
}

export function hiddenFormatterFactory() {
  return { type: 'hidden', formatter: null };
}

export function telFormatterFactory(pipe: TelephonePipe) {
  return { type: 'tel', formatter: toTextFormatter(curry(telephoneFormatter)(pipe)) };
}

export function dateFormatterFactory(pipe: DatePipe) {
  return { type: 'date', formatter: toTextFormatter(curry(dateFormatter)(pipe)) };
}

export function staffSelectorFormatterFactory(linkGenerator: LinkGenerator) {
  return { type: 'k2UsersSelector', formatter: curry(k2UserFormatter)(linkGenerator) };
}

export function partnersSelectorFormatterFactory(linkGenerator: LinkGenerator) {
  return { type: 'partnersSelector', formatter: curry(PartnerFormatter)(linkGenerator) };
}

function toTextFormatter(formatter): Formatter<FieldTextValueComponent> {
  return (field: Field) => {
    const text = `${field.attributes.prefix || ''} ${formatter(field)} ${
      field.attributes.suffix || ''
    }`;
    return {
      inputs: { text: text.trim() },
      component: FieldTextValueComponent
    };
  };
}

export interface Formatters {
  [type: string]: Formatter<any>;
}

export type Formatter<T> = (field: Field) => ComponentSpec<T>;
