<select [id]="id"
        [ngModel]="value"
        (ngModelChange)="valueChange.emit($event)"
        [attr.disabled]="disabled ? 'disabled' : null"
        (blur)="blur.emit()"
        [class.nothing]="isNoValueSelected"
        class="form-control">
  <optgroup label="test" [placeholder]="placeholder"></optgroup>
  <option *ngFor="let option of normalizedOptions" [ngValue]="option.id">
    {{ option.value }}
  </option>

  <optgroup *ngFor="let group of groups" [label]="group.label">
    <option *ngFor="let option of group.options" [ngValue]="option.id">
      {{ option.value }}
    </option>
  </optgroup>
</select>
