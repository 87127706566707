import { ApiClient } from '@k2/common/backend/api-client';
import { Assignment } from '@k2/common/entities-state/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AssignmentsEndpoint {
  constructor(private api: ApiClient) {}

  fetchUnassigned = (): Observable<Assignment[]> => {
    return this.api.get(`/assignments/unassigned`).pipe(map(payload => payload.assignments));
  };

  isFollowedByCurrentUser = (assignmentId: number): Observable<boolean> => {
    return this.api
      .get(`/staff/assignment/${assignmentId}/follow`)
      .pipe(map(payload => payload.enabled));
  };

  followByCurrentUser = (assignmentId: number) => {
    return this.api.put(`/staff/assignment/${assignmentId}/follow`, null);
  };

  unfollowByCurrentUser = (assignmentId: number) => {
    return this.api.delete(`/staff/assignment/${assignmentId}/follow`);
  };

  deleteAssignment = (clientsId: number, assignmentId: number) => {
    return this.api.delete(`/staff/clients/${clientsId}/assignments/${assignmentId}`);
  };

  createAssignmentFromImmigrationRequest = (clientId: number, immigrationId: number) => {
    return this.api
        .get(`/assignments/clients/${clientId}/immigrations/${immigrationId}/new`);
  }

  createImmigrationAndAttachItToAssignment = (clientId: number, assignmentId: number, services: any) => {
    const data = {services};
    return this.api
        .post(`/assignments/${assignmentId}/clients/${clientId}/immigrations`, data);
  }
}
