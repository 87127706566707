import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserSession, UserSessionsHistory } from '@k2/common/entities-state/types';
import { Paginator } from '@k2/common/pagination/paginator';
import { DeleteSession } from '@k2/common/sessions-state/actions';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';

@Component({
  selector: 'user-sessions-history',
  templateUrl: 'user-sessions-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSessionsHistoryComponent {
  @Input() userId: number;
  @Input() userName: string;
  @Input() history: UserSessionsHistory;
  @Input() loginType: number;
  currentPaginator = Paginator.table();
  historyPaginator = Paginator.table();

  constructor(private actions: ActionDispatcher) {}

  deleteSession = (session: UserSession) => {
    this.actions.dispatch(new DeleteSession(this.userId, session.id));
  };
}
