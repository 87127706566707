import { NgModule } from '@angular/core';
import { DocumentLinkComponent } from '@k2/common/documents/components/document-link/document-link.component';
import { DocumentsDialogComponent } from '@k2/common/documents/components/documents-dialog/documents-dialog.component';
import { DocumentsDialogService } from '@k2/common/documents/components/documents-dialog/documents-dialog.service';
import { DocumentUrlPipe } from '@k2/common/documents/pipes/document-url.pipe';
import { SharedModule } from '@k2/common/shared.module';

const components = [DocumentsDialogComponent, DocumentLinkComponent];

const pipes = [DocumentUrlPipe];

@NgModule({
  imports: [SharedModule],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: [DocumentsDialogService]
})
export class DocumentsModule {}
