<div *ngIf="chart">
  <canvas
    baseChart
    [data]="chart.data"
    [labels]="chart.labels"
    [options]="chart.options"
    [legend]="false"
    [type]="chart.type"
    height="65"
  ></canvas>
</div>
