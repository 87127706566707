import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { equals } from 'ramda';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'telephone-input',
  templateUrl: 'telephone-input.component.html',
  styleUrls: ['telephone-input.component.scss']
})
export class TelephoneInputComponent implements AfterViewInit {
  @Input() id: string;
  @Input() initialCountry: string;
  @Input() value: string;
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() blur = new EventEmitter();

  currentValue = '';
  validNumber = true;
  intlTelInputInstance;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    let element; // need for checking if data sets to a new telephone input
    for (let item of document.getElementsByClassName('telephone-input')) {
      if (item.id === this.id && item.getAttribute('data-intl-tel-input-id') === null) {
        element = item;
      }
    }
    this.intlTelInputInstance = intlTelInput(element, {
      nationalMode: true,
      formatOnDisplay: true,
      separateDialCode: true,
      initialCountry: this.normalizeCountryCode(this.initialCountry),
      customPlaceholder(selectedCountryPlaceholder, _) {
        return selectedCountryPlaceholder.replaceAll('-', ' ');
      }
    });
    if (this.value) {
      this._setValue(this.value);
    }
    element.addEventListener('countrychange', () => this.onInputChange());
  }

  onInputChange() {
    this.valueChange.emit(
      this.currentValue
        ? `+${
            this.intlTelInputInstance.getSelectedCountryData().dialCode
          }${this.currentValue.replace(' ', '')}`
        : ''
    );
    this._validate(this.currentValue.replace(' ', ''));
  }

  private _setValue(value: string) {
    this.intlTelInputInstance.setNumber(value);
    this.currentValue = this.intlTelInputInstance
      .getNumber()
      .replace(`+${this.intlTelInputInstance.getSelectedCountryData().dialCode}`, '');
    this.cdr.detectChanges();
    this._validate(
      value.replace(`+${this.intlTelInputInstance.getSelectedCountryData().dialCode}`, '')
    );
  }

  private _validate(value: string) {
    const DIAL_CODE = this.intlTelInputInstance.getSelectedCountryData().dialCode;
    const VAL = intlTelInputUtils
      .formatNumber(`+${DIAL_CODE}${this.currentValue}`)
      .replaceAll(`+${DIAL_CODE}`, '');

    this.validNumber =
      !value.includes('-') &&
      !isNaN(Number(value)) &&
      VAL.length === value.length &&
      intlTelInputUtils.isValidNumber(`+${DIAL_CODE}${this.currentValue}`);
    this.isValid.emit(this.validNumber);
  }

  /**
   * Returns a normalized country code.
   *
   * If a given `code` is not supported, then the 'gb' will be returned.
   */
  normalizeCountryCode(iso: string): string {
    if (!iso) return 'gb';

    const availableIso = intlTelInputGlobals.getCountryData().map(c => c.iso2);
    const NORMALIZED = iso.toLowerCase();

    return availableIso.some(equals(NORMALIZED)) ? NORMALIZED : 'gb';
  }
}
