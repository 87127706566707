<div [hidden]="fileSrc != null">
  <div class="main">
    <div class="image" [style.height]="imageHeight + 'px'">
      <file-jet-img
        [image]="image"
        [width]="255"
        [height]="imageHeight"
        [alt]="placeholder"
      ></file-jet-img>
    </div>

    <div>
      <div class="controls">
        <div #fineUploader></div>

        <a *ngIf="image" (click)="removeImage()" class="remove">
          <icon type="remove"></icon>
          Remove image
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="fileSrc != null" class="cropper">
  <div class="img-wrapper"><img #cropper [src]="fileSrc" /></div>

  <div class="controls">
    <btn (click)="crop()" icon="ok">Crop</btn>
    &nbsp;or&nbsp;
    <a (click)="cancel()">cancel</a>
  </div>
</div>

<div id="qq-image-uploader" style="display: none">
  <div class="qq-uploader-selector">
    <div class="btn btn-default qq-upload-button-selector">
      <div>
        <icon type="open"></icon>
        Upload new image
      </div>
    </div>
    <ul style="display: none" class="qq-upload-list-selector qq-upload-list">
      <li></li>
    </ul>

    <dialog class="qq-alert-dialog-selector">
      <div class="qq-dialog-message-selector"></div>
      <div class="qq-dialog-buttons">
        <button type="button" class="qq-cancel-button-selector">Close</button>
      </div>
    </dialog>
  </div>
</div>
