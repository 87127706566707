<annotated-control [field]="field">
  <input
    type="text"
    class="form-control"
    [id]="field.attributes.id"
    [ngModel]="momentValue"
    [disabled]="disabled"
    [matDatepicker]="datepicker"
    [placeholder]="field.attributes.placeholder || 'Click to choose'"
    [min]="min"
    [max]="max"
    (focus)="datepicker.open()"
    (click)="datepicker.open()"
    (ngModelChange)="onValueChange($event)"
  />
</annotated-control>

<mat-datepicker
  #datepicker
  [startView]="startView"
  (closed)="touch()"
></mat-datepicker>
