import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { asyncIdentity } from '@k2/common/helpers';
import { AddFormError } from '@k2/common/k2-forms-state/actions';
import { Fields, FieldsSpec } from '@k2/common/k2-forms-state/types';
import { fieldsToForm } from '@k2/common/k2-forms/fields';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { SubmitControlComponent } from '@k2/common/ui/components/submit-control/submit-control.component';
import { Observable } from 'rxjs';
import { first, flatMap, withLatestFrom } from 'rxjs/operators';

@Component({
  templateUrl: 'form-dialog.component.html',
  styleUrls: ['form-dialog.component.scss']
})
export class FormDialogComponent {
  readonly form: Observable<UntypedFormGroup>;

  @ViewChild(SubmitControlComponent) submitControl: SubmitControlComponent;

  constructor(
    private dialogRef: MatDialogRef<FormDialogComponent, FormDialogResult>,
    private actions: ActionDispatcher,
    @Inject(MAT_DIALOG_DATA) public data: Observable<FormDialogData>
  ) {
    this.form = this.data.pipe(
      flatMap(data => data.fields),
      fieldsToForm
    );
  }

  submit = async () => {
    this.form.pipe(first(), withLatestFrom(this.data)).subscribe(async ([form, data]) => {
      if (!form.valid) return this.actions.dispatch(new AddFormError());
      this.submitControl.loading = true;
      const onSubmit = data.onSubmit || asyncIdentity;

      try {
        const response = await onSubmit(data.rawValues ? form.getRawValue() : form.value);
        this.dialogRef.close({ reason: 'submitted', value: response });
      } catch (e) {
        console.error(e);
      }

      this.submitControl.loading = false;
    });
  };

  cancel = () => {
    this.form.pipe(first(), withLatestFrom(this.data)).subscribe(async ([form, data]) => {
      const onCancel = data.onCancel || asyncIdentity;

      try {
        const response = await onCancel(form.value);
        this.dialogRef.close({ reason: 'cancelled', value: response });
      } catch (e) {
        console.error(e);
      }
    });
  };
}

export interface FormDialogData<T = any, S = any> {
  title: string;
  fieldsCmp: ComponentSpec;
  fields: Observable<Fields>;
  templateFieldsSpec?: FieldsSpec;
  submitLabel?: string;
  submitIcon?: string;
  notes?: string;
  rawValues?: boolean;
  onSubmit?: (value: T) => Promise<S>;
  onCancel?: (value: T) => Promise<S>;
}

export interface FormDialogResult<T = any> {
  readonly reason: 'submitted' | 'cancelled';
  readonly value: T;
}
