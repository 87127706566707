import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HComponent } from '@k2/common/ui/components/heading/h.component';

@Component({
  selector: 'h3',
  templateUrl: 'h.component.html',
  styleUrls: ['h.component.scss', 'h3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class H3Component extends HComponent {}
