import { ApiClient } from '@k2/common/backend/api-client';
import { OfficeInitiationStats } from '@k2/common/entities-state/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class StatsEndpoint {
  constructor(private api: ApiClient) {}

  fetchOfficesInitiationStats = (): Observable<OfficeInitiationStats[]> => {
    return this.api.get(`/stats/office/initiations`).pipe(map(payload => payload.stats));
  };
}
