<annotated-control [field]="field">
  <div class="row m-0">
    <input type="text"
           [id]="field.attributes.id"
           [ngModel]="date"
           (ngModelChange)="date = $event"
           [disabled]="disabled"
           [matDatepicker]="datepicker"
           [placeholder]="field.attributes.placeholder || 'Choose date'"
           [min]="min"
           [max]="max"
           (focus)="datepicker.open()"
           (click)="datepicker.open()"
           class="time-input form-control"
           [ngClass]="full ? 'col-4' : 'col-6'" />

    <input [ngxTimepicker]="picker"
           [value]="time"
           [format]="timeFormat"
           [disabled]="disabled"
           placeholder="Choose time"
           class="time-input form-control"
           [ngClass]="full ? 'col-4' : 'col-6'" />

    <div class="col-4 p-0">
      <select *ngIf="full"
              class="form-control w-100"
              [(ngModel)]="timezone"
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="propagateMomentValueChange()">
        <option *ngFor="let option of timezones" [ngValue]="option">
          GMT{{ option }}
        </option>
      </select>
    </div>
  </div>
</annotated-control>

<mat-datepicker #datepicker [startView]="startView" (closed)="touch()"></mat-datepicker>

<ngx-material-timepicker #picker
                         [cancelBtnTmpl]="cancelTmpl"
                         [confirmBtnTmpl]="okTmpl"
                         [disableAnimation]="true"
                         (opened)="timePickerOpened = true"
                         (closed)="timePickerOpened = false"
                         (timeChanged)="time = $event"></ngx-material-timepicker>

<ng-template #cancelTmpl>
  <button class="btn datetime-btn" (click)="cancel()">Cancel</button>
</ng-template>

<ng-template #okTmpl>
  <button class="btn datetime-btn" (click)="ok()">Ok</button>
</ng-template>
