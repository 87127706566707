import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appConfig } from '@k2/common/app-config';
import { ReplayLastSubject, safe } from '@k2/common/helpers';
import { equals } from 'ramda';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const { apiUrl } = appConfig;
const maxCacheSize = 200;
const staleTTL = 2000;
const expiresTTL = 30000;
const requestOptions = { withCredentials: true };

/**
 * HTTP client which communicates with API.
 *
 * Client caches all GET requests in in-memory map.
 * Every cache entry includes the `staleAt` and `expiresAt` timestamps
 * to determine if it can be served directly from cache and if a new HTTP request is required.
 *
 * Staled entries will be returned immediately and a returned observable will emit
 * the second, "fresh" value, later as API responds.
 *
 * On the other hand, expired entries will be removed
 * from cache and a returned observable will emit only the "fresh" value.
 *
 * Non-staled and non-expired entries will be returned immediately and no further HTTP request will be made.
 */
@Injectable({ providedIn: 'root' })
export class ApiClient {
  private _cache: Cache;

  constructor(private http: HttpClient) {}

  /**
   * Makes a GET request to API. Emits max. two values or fails.
   *
   * Returned Observable could emit the stale value from cache at first
   * with the following fresh value from API.
   */
  get<T = any>(path: string): Observable<T> {
    const url = toUrl(path);
    const cached = this.getFromCache(path);

    if (cached != null && !cached.stale) return cached.request;

    const freshRequest = this.http
      .get(url, requestOptions)
      .pipe(map(extractPayload), shareReplay({ bufferSize: 1, refCount: false }));

    this.putToCache(path, freshRequest);

    if (cached == null) return freshRequest;

    let staleValue: T;
    const subject = new ReplayLastSubject<T>();

    cached.request.subscribe(value => {
      staleValue = value;
      subject.next(value);
    });

    freshRequest.subscribe({
      next: value => {
        if (!equals(staleValue, value)) subject.next(value);
        subject.complete();
      },
      error: error => subject.error(error),
      complete: () => subject.complete()
    });

    return subject.asObservable();
  }

  getWithoutCache<T = any>(path: string): Observable<T> {
    const url = toUrl(path);

    return this.http.get(url, requestOptions)
      .pipe(map(extractPayload), shareReplay({bufferSize: 1, refCount: false}));
  }

  /**
   * Makes a PATCH request to API. Emits one value or fails.
   *
   * A related cached GET requests may be invalidated.
   */
  patch<T = any>(path: string, body: any | null): Observable<T> {
    const response = this.http.patch(toUrl(path), body, requestOptions).pipe(map(extractPayload));
    this.invalidateRelated(path);
    return response;
  }

  /**
   * Makes a POST request to API. Emits one value or fails.
   *
   * A related cached GET requests may be invalidated.
   */
  post<T = any>(path: string, body: any | null): Observable<T> {
    const response = this.http.post(toUrl(path), body, requestOptions).pipe(map(extractPayload));
    this.invalidateRelated(path);
    return response;
  }

  /**
   * Makes a PUT request to API. Emits one value or fails.
   *
   * A related cached GET requests may be invalidated.
   */
  put<T = any>(path: string, body: any | null): Observable<T> {
    const response = this.http.put(toUrl(path), body, requestOptions).pipe(map(extractPayload));
    this.invalidateRelated(path);
    return response;
  }

  /**
   * Makes a DELETE request to API. Emits one value or fails.
   *
   * A related cached GET requests may be invalidated.
   */
  delete<T = any>(path: string): Observable<T> {
    const response = this.http.delete(toUrl(path), requestOptions).pipe(map(extractPayload));
    this.invalidateRelated(path);
    return response;
  }

  /**
   * Returns a cached request if exists or if it is not expired.
   */
  private getFromCache = (path: string) => {
    const entry = this.cache.get(path);
    if (entry == null) return;

    const now = Date.now();
    const { staleAt, expiresAt, request } = entry;
    if (expiresAt > now) return { stale: now > staleAt, request };

    this.cache.delete(path);
  };

  /**
   * Puts a request into cache.
   *
   * Old cache entries may be removed if cache contains too much requests.
   */
  private putToCache = (path: string, request: Request) => {
    const now = Date.now();
    const { cache } = this;

    cache.set(path, {
      staleAt: now + staleTTL,
      expiresAt: now + expiresTTL,
      request
    });

    if (cache.size > maxCacheSize) {
      cache.delete(cache.keys().next().value);
    }
  };

  /**
   * Invalidates all GET requests, which could be related to the given `path`.
   *
   * EXAMPLE:
   *   `/staff/1/profile/details` will invalidate all paths which starts with `/staff/1`.
   */
  private invalidateRelated = (path: string) => {
    const isId = value => !isNaN(value) || value.length > 16;

    const parts = path.split('/');
    const lastIdIndex = [...parts]
      .reverse()
      .findIndex((part, index) => (index !== 0 && isId(part)) || index === parts.length - 1);

    const toInvalidate = parts.slice(0, parts.length - lastIdIndex).join('/');

    const { cache } = this;
    const cachedPaths = [...cache.keys()];
    cachedPaths.forEach(cachedPath => {
      if (cachedPath.startsWith(toInvalidate)) cache.delete(cachedPath);
    });
  };

  get cache() {
    if (this._cache == null) this._cache = new Map();
    return this._cache;
  }

  set cache(cache: Cache) {
    this._cache = cache;
  }

  readonly toUrl = toUrl;
}

/**
 * Ensures that all cache entries will be "fresh" after the given `ttl`.
 *
 * "Fresh" entry is an entry which is valid and not stale.
 */
export function ensureFreshEntriesAfter(ttl: number, cache: Cache): Cache {
  const newCache: Cache = new Map();
  const freshUntil = Date.now() + ttl;

  cache.forEach((entry, key) => {
    const { staleAt, expiresAt, request } = entry;

    newCache.set(key, {
      staleAt: freshUntil > staleAt ? freshUntil : staleAt,
      expiresAt: freshUntil > expiresAt ? freshUntil : expiresAt,
      request
    });
  });

  return newCache;
}

function toUrl(path: string) {
  if (path.startsWith('http://') || path.startsWith('https://')) {
    throw Error(`ApiClient supports only relative paths!`);
  }
  if (!path.startsWith('/')) {
    throw Error(`ApiClient must be called with path which begins with "/"!`);
  }
  return `${apiUrl}/api${path}`;
}

function extractPayload(response: any) {
  return safe(() => response.payload);
}

export type Cache = Map<string, CacheEntry>;

interface CacheEntry {
  readonly staleAt: number;
  readonly expiresAt: number;
  readonly request: Request;
}

type Request = Observable<any>;
