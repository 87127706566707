import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

/**
 * Try to reuse the common parent components between two routes
 * but never reuse the component itself.
 */
@Injectable()
export class AppRouteStrategy implements RouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    if (toUrl(current) === toUrl(future)) {
      return true;
    }

    // Reuse if the component is the same and path match everything.
    // Necessary especially for legacy app wrappers to avoid AngularJS re-initialization!
    if (
      current.component === future.component &&
      current.routeConfig.path === '**' &&
      future.routeConfig.path === '**'
    ) {
      return true;
    }

    return false;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return null;
  }
}

function toUrl(route: ActivatedRouteSnapshot) {
  return route.pathFromRoot
    .map(snapshot =>
      snapshot.url
        .map(s => s.toString())
        .filter(isNotEmptyString)
        .join('/')
    )
    .filter(isNotEmptyString)
    .join('/');
}

function isNotEmptyString(value: string): boolean {
  return value !== '';
}
