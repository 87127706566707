import { getDenormalized } from '@k2/common/entities-state/selectors';
import { K2User } from '@k2/common/entities-state/types';
import { RootState } from '@k2/common/k2-users-state/state';
import { k2UserSchema } from '@k2/common/users-state/normalizer-schemas';
import { Selector } from '@ngrx/store';
import { identity } from 'ramda';
import { createSelector } from 'reselect';

export function getK2UserTeam(userId: number): Selector<RootState, K2User[]> {
  return createSelector(
    [(state: RootState) => state.k2Users.teams[userId], identity],
    (ids, state) => {
      if (ids != null) return getDenormalized(k2UserSchema, ids)(state);
    }
  );
}
