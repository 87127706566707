import { NgModule } from '@angular/core';
import { AddressBlockComponent } from '@k2/common/addresses/components/address-block/address-block.component';
import { AddressBlocksListComponent } from '@k2/common/addresses/components/address-blocks-list/address-blocks-list.component';
import { FormattedAddressComponent } from '@k2/common/addresses/components/formatted-address/formatted-address.component';
import { InlinedAddressPipe } from '@k2/common/addresses/pipes/inlined-address.pipe';
import { SharedModule } from '@k2/common/shared.module';
import { AddressSelectorDialogComponent } from './components/address-selector/address-selector-dialog.component';

const declarations = [
  AddressBlockComponent,
  AddressBlocksListComponent,
  FormattedAddressComponent,
  AddressSelectorDialogComponent,
  InlinedAddressPipe
];

@NgModule({
  imports: [SharedModule],
  declarations,
  exports: declarations
})
export class AddressesModule {}
