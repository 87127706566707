<div [id]="field.attributes.id" [class.compact]="hasALotOptions" class="group">
  <div
    (click)="updateModel(option.id)"
    *ngFor="let option of options"
    class="bordered-box"
    [class.active]="field.control.value.id === option.id"
  >
    <label>
      <input
        type="radio"
        name="contactChoice"
        [value]="option.id"
        [ngModel]="field.control.value.id"
        (ngModelChange)="updateModel(option.id)"
      />
    </label>
    <div class="option-text">
      <h3>{{ option.first_name }} {{ option.last_name }}</h3>
      <div>{{ option.job_title }}</div>
      <div>{{ option.responsibility }}</div>
      <div *ngIf="option.telephone_work">
        <icon type="phone-alt"></icon>
        {{ option.telephone_work | telephone }}
      </div>
      <div *ngIf="option.email" class="email">
        <icon type="envelope"></icon>
        {{ option.email }}
      </div>
    </div>
  </div>

  <!-- CUSTOM -->
  <div *ngIf="field.attributes.support_custom" class="bordered-box">
    <div (click)="updateModelWithCustom()" [class.active]="isCustomSelected()">
      <label>
        <input [checked]="isCustomSelected()" type="radio" />
      </label>
      <div class="custom-option option-text">
        <label>
          <input
            (change)="updateModelWithCustom()"
            [formControl]="name"
            type="text"
            placeholder="Name"
            [disabled]="!isCustomSelected()"
            [required]="true"
            class="form-control"
          />
        </label>

        <label>
          <input
            (change)="updateModelWithCustom()"
            [formControl]="telephone"
            type="text"
            placeholder="Telephone"
            [disabled]="!isCustomSelected()"
            [required]="true"
            class="form-control"
          />
        </label>

        <label>
          <input
            [(ngModel)]="email"
            (change)="updateModelWithCustom()"
            [formControl]="email"
            type="email"
            placeholder="Email"
            [disabled]="!isCustomSelected()"
            [required]="true"
            class="form-control"
          />
        </label>
      </div>
    </div>
  </div>
</div>
