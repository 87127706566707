<div class="status-section" *ngIf="statuses.length !== 0">
  <h2>
    Milestone progress
    <small
      ><icon type="warning-sign"></icon> needs to be updated manually</small
    >
  </h2>

  <div *ngFor="let status of statuses">
    <strong> {{ status.label }}</strong>

    <milestones
      [milestones]="toMilestonesModel(status.milestones)"
      [initialValue]="findCurrentMilestoneId(status.milestones)"
      [disabled]="status.disabled"
      (update)="
        updateMilestoneStatus(status.collection_id, status.element_id, $event)
      "
    ></milestones>
  </div>
</div>
