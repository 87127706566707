import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlashMessagesComponent } from '@k2/common/flash-messages/components/flash-messages.component';
import { FlashMessageEffects } from '@k2/common/flash-messages/effects';
import { featureReducer } from '@k2/common/flash-messages/reducers';
import { httpInterceptorProvider } from '@k2/common/flash-messages/services/http-interceptor';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('flashMessages', featureReducer),
    EffectsModule.forFeature([FlashMessageEffects])
  ],
  declarations: [FlashMessagesComponent],
  exports: [FlashMessagesComponent],
  providers: [httpInterceptorProvider]
})
export class FlashMessagesModule {}
