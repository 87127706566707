import { ApiClient } from '@k2/common/backend/api-client';
import { Location } from '@k2/common/entities-state/types';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CountriesEndpoint {
  constructor(private http: ApiClient) {}

  queryLocation = (regionIds: number | number[], query: string): Observable<Location[]> => {
    if (typeof regionIds === 'number') {
      return this.http
        .get(`/countries/${regionIds}/locations/${encodeURIComponent(query)}`)
        .pipe(map(payload => payload.locations));
    }

    return forkJoin(regionIds.map(id => this.http
        .get(`/countries/${id}/locations/${encodeURIComponent(query)}`)
        .pipe(map(payload => payload.locations))
    )).pipe(map(results => results.reduce((acc, val) => acc.concat(val), [])));
  };
}
