import { Inject, Injectable } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { FIELD_VALUE_FORMATTERS, Formatters } from '@k2/common/k2-forms/field-value/formatters';
import { ComponentSpec } from '@k2/common/ui/component-spec';

@Injectable({ providedIn: 'root' })
export class FieldValueFormatter {
  constructor(@Inject(FIELD_VALUE_FORMATTERS) private formatters: Formatters) {}

  format(field: Field): ComponentSpec {
    const type = field.attributes.display_type;
    const formatter = this.formatters[type];

    if (formatter == null)
      throw new Error(`Failed to format field's value! "${type}" formatter doesn't exists.`);

    return formatter(field);
  }
}
