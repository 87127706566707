import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { appConfig } from '@k2/common/app-config';
import { Partner } from '@k2/common/entities-state/types';
import { ValueOption } from '@k2/common/k2-forms-state/types';
import {
  PartnerFinderDialogComponent,
  PartnerFinderDialogData
} from '@k2/common/partners/component/partner-finder/partner-finder-dialog.component';

@Component({
  selector: 'partner-finder',
  templateUrl: 'partner-finder.component.html',
  styleUrls: ['partner-finder.component.scss']
})
export class PartnerFinderComponent {
  @Input() selected: Partner;
  @Input() placeholder = 'Click to find a partner';
  @Input() disabled = false;
  @Input() services: ValueOption[];
  @Input() searchParams: { [name: string]: string } = {};
  @Output() selectedChange = new EventEmitter<Partner>();

  constructor(private dialog: MatDialog) {}

  openDialog = () => {
    const data: PartnerFinderDialogData = {
      searchParams: this.searchParams,
      services: this.services
    };

    this.dialog
      .open(PartnerFinderDialogComponent, {
        width: appConfig.dialog.width,
        minHeight: '310px',
        data
      })
      .afterClosed()
      .subscribe((partner?: Partner | null) => {
        if (partner !== undefined) {
          this.update(partner);
        }
      });
  };

  clear = () => {
    this.update(null);
  };

  private update = (partner: Partner | null) => {
    this.selectedChange.emit(partner);
  };
}
