<annotated-control [field]="field">
  <div
    *ngFor="let value of momentValues; let last = last; let i = index; trackBy: trackByIndex;"
    class="inputs"
  >
    <input
      type="text"
      id="{{ field.name }}-{{ i }}"
      [ngModel]="value"
      (ngModelChange)="setMomentValue(i, $event)"
      [disabled]="disabled"
      [matDatepicker]="datepicker"
      [placeholder]="field.attributes.placeholder || 'Click to choose'"
      [min]="min"
      [max]="max"
      (focus)="datepicker.open()"
      (click)="datepicker.open()"
      class="form-control"
    />

    <mat-datepicker
      #datepicker
      [startView]="startView"
      (closed)="touch()"
    ></mat-datepicker>

    <array-field-controls
      [canRemove]="canRemove"
      [canAdd]="last && canAdd"
      (add)="addEmptyValue()"
      (remove)="removeValue(i)"
    ></array-field-controls>
  </div>
</annotated-control>
