import { Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'password-control',
  templateUrl: 'password-control.component.html'
})
export class PasswordControlComponent {
  @Input() field: Field<string>;
}
