import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNotNil } from '@k2/common/helpers';
import { Field } from '@k2/common/k2-forms-state/types';

/**
 * Adds a given `validators` to a given control.
 */
export function addValidators(control: AbstractControl, ...validators: ValidatorFn[]) {
  const allValidators = [control.validator, ...validators].filter(isNotNil);
  control.setValidators(allValidators);
}

/**
 * Returns function, which will disable/enable a given `control` based on an input.
 */
export function toggleDisabled(control: AbstractControl) {
  return (disabled: boolean) => {
    if (disabled) control.disable();
    control.enable();
  };
}

/**
 * Acts as a bridge between FormControl and custom control components.
 */
export class WithFormControl<T = any> {
  protected field: Field<T>;

  get value(): T {
    return this.field.control.value;
  }

  set value(value: T) {
    this.field.control.setValue(value);
  }

  touch() {
    this.field.control.markAsTouched();
  }

  markDirty() {
    this.field.control.markAsDirty();
  }

  get disabled() {
    return this.field.control.disabled;
  }

  get readonly() {
    return this.field.attributes.readonly;
  }
}
