<div mat-dialog-content [ngClass]="data?.alignTextLeft ? 'confirmation-modal left' : 'confirmation-modal'">
  <div *ngIf="data.text" class="mb-3">{{ data.text }}</div>
  <div *ngIf="data.subtext" class="mb-3">{{ data.subtext }}</div>
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-raised-button
          type="submit"
          aria-label="submit"
          class="btn-confirm"
          (click)="dialogRef.close(true)">
    {{ data.confirmButtonText ? data.confirmButtonText : 'OK' }}
  </button>
  <button mat-raised-button *ngIf="!data.hideCancelBtn"
          type="button"
          aria-label="cancel"
          class="ml-3"
          (click)="dialogRef.close(false)">
    {{ data.cancelButtonText ? data.cancelButtonText : 'Cancel' }}
  </button>
</div>
