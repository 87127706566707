import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { K2User } from '@k2/common/entities-state/types';

@Component({
  selector: 'k2-user-contact-ingot',
  templateUrl: 'k2-user-contact-ingot.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2UserContactIngotComponent {
  @Input() user: K2User;
}
