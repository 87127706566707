import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { appConfig } from '@k2/common/app-config';
import { ensureObservable } from '@k2/common/helpers';
import { DeleteDocumentFiles } from '@k2/common/documents-state/actions';
import { FieldsComponent } from '@k2/common/k2-forms/fields/fields.component';
import { EmailFieldsComponent } from '@k2/common/k2-forms/fieldsets/email-fields/email-fields.component';
import {
  FormDialogComponent,
  FormDialogData,
  FormDialogResult
} from '@k2/common/k2-forms/form-dialog/form-dialog.component';
import { AppStore } from '@k2/common/state/services/app-store';
import { Omit } from 'ramda';
import { interval, Observable, race, switchMap } from 'rxjs';
import { filter, first, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';

const config = appConfig.dialog;

@Injectable()
export class FormDialogService {
  constructor(private dialog: MatDialog, private store: AppStore<any>, private overlay: Overlay) {}

  /**
   * Opens a form with customizable fields rendering.
   *
   * Provide a `data.fieldsCmp` to control a fields rendering.
   */
  openForm = <T>(data: FormDialogData | Observable<FormDialogData>): Observable<T> => {
    const formData = ensureObservable(data).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: false
      })
    );

    return this.open(formData).pipe(
      switchMap(dialog => dialog.afterClosed()),
      filter(wasSubmitted),
      map(toResultValue)
    );
  };

  /**
   * Opens a general form with an automatic fields rendering.
   */
  openGeneralForm = <T>(
    data: GeneralFormDialogData | Observable<GeneralFormDialogData>
  ): Observable<T> => {
    const formData = ensureObservable(data).pipe(
      map(data => ({
        ...data,
        fieldsCmp: {
          component: FieldsComponent,
          inputs: { fields: data.fields }
        }
      })),
      shareReplay({ bufferSize: 1, refCount: false })
    );
    return this.openForm(formData);
  };

  /**
   * Opens an Email form.
   */
  openEmailForm = <T>(
    data: GeneralFormDialogData | Observable<GeneralFormDialogData>
  ): Observable<T> => {
    const formData = ensureObservable(data).pipe(
      map(data => ({
        ...data,
        submitLabel: data.submitLabel || 'Send email',
        submitIcon: data.submitIcon || 'send',
        fieldsCmp: {
          component: EmailFieldsComponent,
          inputs: { fields: data.fields }
        }
      })),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    return this.open(formData).pipe(
      switchMap(dialog => dialog.afterClosed()),
      withLatestFrom(formData.pipe(switchMap(data => data.fields))),
      tap(([result, fields]) => {
        if (result == null) return;

        if (result.reason === 'cancelled') {
          this.store.dispatch(
            DeleteDocumentFiles.from(result.value.attachments, fields.attachments.defaultValue)
          );
        } else {
          this.store.dispatch(
            DeleteDocumentFiles.from(fields.attachments.defaultValue, result.value.attachments)
          );
        }
      }),
      map(([result]) => result),
      filter(wasSubmitted),
      map(toResultValue)
    );
  };

  /**
   * Opens the dialog when fields observable emits the first value or after `config.maxDelay`.
   */
  private open = (data: Observable<FormDialogData>): Observable<MatDialogRef<FormDialogComponent, FormDialogResult>> => {
    return race(data.pipe(switchMap(data => data.fields)), interval(config.maxDelay)).pipe(
      first(),
      map(() =>
        this.dialog.open(FormDialogComponent, {
          width: config.width,
          data,
          disableClose: true,
          autoFocus: false,
          scrollStrategy: this.overlay.scrollStrategies.reposition()
        })
      )
    );
  };
}

function wasSubmitted(result: FormDialogResult): boolean {
  return result != null && result.reason === 'submitted';
}

function toResultValue<T>(result: FormDialogResult<T>): T {
  return result.value;
}

type GeneralFormDialogData = Omit<FormDialogData, 'fieldsCmp'>;
