<annotated-control [field]="field">
  <div *ngFor="let value of values; let last = last" class="inputs">
    <div *ngFor="let option of options; let i = index"
         class="input input-{{ options[i].display_type }}"
         [class.input-group]="options[i].prefix || options[i].suffix">
      <span *ngIf="options[i].prefix" class="input-group-addon">{{ options[i].prefix }}</span>

      <basic-select *ngIf="option.display_type === 'select' || option.display_type === 'select2'"
                    [id]="field.attributes.id"
                    [value]="value[i]"
                    (valueChange)="value[i] = $event; propagateValueChange()"
                    [disabled]="disabled"
                    [options]="option.items"></basic-select>

      <input *ngIf="option.display_type === 'checkbox'"
             type="checkbox"
             [ngModel]="value[i]"
             [disabled]="disabled"
             (ngModelChange)="value[i] = $event; propagateValueChange()" />

      <input *ngIf="option.display_type === 'text' || option.display_type === 'number'"
             [type]="option.display_type"
             class="form-control"
             [ngModel]="value[i]"
             [disabled]="disabled"
             (ngModelChange)="value[i] = $event; propagateValueChange()" />

      <textarea *ngIf="option.display_type === 'textarea'"
                class="form-control"
                [ngModel]="value[i]"
                [disabled]="disabled"
                (ngModelChange)="value[i] = $event; propagateValueChange()"></textarea>

      <div *ngIf="option.display_type === 'date'">
        <input type="text"
               [ngModel]="value[i]"
               [disabled]="disabled"
               [matDatepicker]="datepicker"
               (focus)="datepicker.open()"
               (click)="datepicker.open()"
               (ngModelChange)="value[i] = $event; propagateValueChange()"
               class="form-control" />

        <mat-datepicker #datepicker (closed)="touch()"></mat-datepicker>
      </div>

      <span *ngIf="options[i].suffix" class="input-group-addon">{{ options[i].suffix }}</span>
    </div>

    <array-field-controls *ngIf="!disabled"
                          [canRemove]="canRemove"
                          [canAdd]="last && canAdd"
                          (add)="addEmptyValue()"
                          (remove)="removeValue(value)"></array-field-controls>
  </div>
</annotated-control>
