import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp, isNotEmpty, isNotString } from '@k2/common/helpers';

/**
 * Finds and highlight a given text.
 */
@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, query: string, fullMatch = false): string {
    if (isNotString(text) || isNotString(query)) return text;

    if (fullMatch) return highlight(text, query);

    const queryParts = query.split(' ').filter(isNotEmpty);

    return text
      .split(' ')
      .filter(isNotEmpty)
      .map(textPart =>
        queryParts.reduce((current, queryPart) => highlight(current, queryPart), textPart)
      )
      .join(' ');
  }
}

function highlight(text: string, query: string): string {
  const pattern = `(?!<.*[^>]*?>)(${escapeRegExp(query)})(?!(([^<]*?</.*>)|(.*>)))`;
  const regExp = new RegExp(pattern, 'gi');

  return text.replace(regExp, `<span class="highlighted">$1</span>`);
}
