import { Partner } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { LinkGenerator } from '@k2/common/links/link-generator';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { ComponentsListComponent } from '@k2/common/ui/components/components-list/components-list.component';
import { PartnerIngotComponent } from '../../../partners/component/partner-ingot/partner-ingot.component';

type Result = ComponentSpec<Partial<ComponentsListComponent> | PartnerIngotComponent>;

export function PartnerFormatter(linkGenerator: LinkGenerator, field: Field): Result {
  const { value } = field.control;

  if (Array.isArray(value)) {
    return {
      inputs: {
        specs: value.map(partner => toPartnerIngotSpec(partner))
      },
      component: ComponentsListComponent
    };
  }
  return value ? toPartnerIngotSpec(value) : null;

  function toPartnerIngotSpec(partner: Partner): ComponentSpec<PartnerIngotComponent> {
    const link = linkGenerator.generate({
      type: 'partner',
      data: { partnerId: partner.id }
    });

    return {
      inputs: { partner },
      component: PartnerIngotComponent
    };
  }
}
