import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Partner } from '@k2/common/entities-state/types';

@Component({
  selector: 'partner-autocomplete-option',
  templateUrl: 'partner-autocomplete-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerAutocompleteOptionComponent {
  @Input() partner: Partner;
  @Input() highlight: string;
}
