import { Pipe, PipeTransform } from '@angular/core';
import { Assignment } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

@Pipe({ name: 'assignmentLink' })
export class AssignmentLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(assignment: Assignment): any {
    if (assignment == null) return;

    if (assignment.client != null) {
      return this.generator.generate({
        type: 'assignment',
        data: {
          clientId: assignment.client.id,
          assignmentId: assignment.id
        }
      });
    }

    return this.generator.generate({
      type: 'assignment',
      data: {
        assignmentId: assignment.id
      }
    });
  }
}
