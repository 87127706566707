import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationAutocompleteOptionComponent } from '@k2/common/locations/components/location-autocomplete-option/location-autocomplete-option.component';
import { LocationIngotComponent } from '@k2/common/locations/components/location-ingot/location-ingot.component';
import { LocationsSelectorComponent } from '@k2/common/locations/components/locations-selector/locations-selector.component';
import { UIModule } from '@k2/common/ui/ui.module';

const components = [
  LocationsSelectorComponent,
  LocationIngotComponent,
  LocationAutocompleteOptionComponent
];

@NgModule({
  imports: [CommonModule, UIModule],
  declarations: components,
  exports: components
})
export class LocationsModule {}
