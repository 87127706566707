<div [id]="field.attributes.id" (blur)="touch()">
  <div *ngFor="let option of options; let last = last" class="option">
    <div class="selects">
      <select
        [(ngModel)]="option.variationId"
        (change)="onVariationChange()"
        [disabled]="disabled"
        class="form-control"
      >
        <optgroup placeholder></optgroup>
        <option
          *ngFor="let variation of associativeGroups[option.group].variations"
          [ngValue]="variation.id"
        >
          {{ variation.value }}
        </option>
      </select>

      <select
        [(ngModel)]="option.group"
        (change)="onGroupChange(option)"
        [disabled]="disabled"
        class="form-control"
      >
        <option *ngFor="let group of groups" [ngValue]="group.type">
          {{ group.typeLabel || group.type }}
        </option>
      </select>
    </div>

    <array-field-controls
      *ngIf="!disabled"
      [canRemove]="canRemove"
      [canAdd]="last"
      (add)="addEmptyOption()"
      (remove)="removeOption(option)"
    ></array-field-controls>
  </div>
</div>
