import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isNotNil } from '@k2/common/helpers';
import { Field } from '@k2/common/k2-forms-state/types';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { addValidators } from '../utils';

type Moment = moment.Moment;

@Component({
  selector: 'multi-date-control',
  templateUrl: 'multi-date-control.component.html',
  styleUrls: ['multi-date-control.component.scss']
})
export class MultiDateControlComponent implements OnChanges {
  @Input() field: Field<string[]>;
  public momentValues: Array<Moment | EmptyValue>;
  private lastControlValue: string[];
  private controlValueSubscription: Subscription | undefined;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnChanges(): void {
    const { control } = this.field;

    if (!Array.isArray(control.value)) {
      control.setValue([]);
    }

    this.propagateControlValueChange();

    if (this.controlValueSubscription) {
      this.controlValueSubscription.unsubscribe();
    }

    this.controlValueSubscription = control.valueChanges.subscribe(
      this.propagateControlValueChange
    );

    addValidators(control, this.customValidator);
  }

  trackByIndex = (index, item) => index;

  setMomentValue = (index: number, value: Moment | EmptyValue): void => {
    this.momentValues[index] = value;
    this.propagateMomentValueChange();
  };

  private customValidator = (c: AbstractControl): ValidationErrors | null => {
    for (const value of this.momentValues.filter(isNotNil)) {
      if (this.min && this.min.isAfter(value)) {
        return { futureOnlyDate: 'Date needs to be in the future' };
      }
      if (this.max && this.max.isBefore(value)) {
        return { pastOnlyDate: 'Date needs to be in the past' };
      }
    }
    return null;
  };

  addEmptyValue = () => {
    this.momentValues = [...this.momentValues, emptyValue];
  };

  removeValue = (index: number) => {
    this.momentValues.splice(index, 1);

    if (this.momentValues.length === 0) {
      this.momentValues = [emptyValue];
    }

    this.propagateMomentValueChange();
  };

  private propagateMomentValueChange = () => {
    this.lastControlValue = this.momentValues.filter(isNotNil).map(momentToString);
    this.field.control.setValue(this.lastControlValue);
  };

  private propagateControlValueChange = () => {
    const { value } = this.field.control;
    if (this.lastControlValue === value) return;

    this.momentValues = value.map(stringToMoment);
    if (this.momentValues.length === 0) {
      this.momentValues = [emptyValue];
    }

    this.cd.markForCheck();
  };

  get startView(): 'month' | 'year' {
    const expected = this.field.attributes.default_view;
    if (['month', 'year'].includes(expected)) return expected;
    return 'month';
  }

  get canAdd(): boolean {
    return this.field.attributes.max ? this.momentValues.length < this.field.attributes.max : true;
  }

  get min() {
    const rule = this.dateValidatorRule;
    if (rule && rule.params && rule.params.future_only) return moment.utc().startOf('day');
  }

  get max() {
    const rule = this.dateValidatorRule;
    if (rule && rule.params && rule.params.past_only) return moment.utc().startOf('day');
  }

  private get dateValidatorRule() {
    const validators = this.field.validators || [];
    return validators.find(validator => validator.rule === 'IsDate');
  }

  get canRemove(): boolean {
    return this.momentValues.length > 1;
  }

  touch() {
    this.field.control.markAsTouched();
  }

  get disabled() {
    return this.field.control.disabled;
  }
}

type EmptyValue = null;
const emptyValue: EmptyValue = null;

function stringToMoment(value: string): Moment {
  return moment.utc(value);
}

function momentToString(value: Moment): string {
  return value.toISOString();
}
