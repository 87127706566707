import { ApiClient } from '../api-client';
import { Observable } from 'rxjs';
import { AgreeTermsStatus } from '@k2/common/agree-terms/types';


export class AgreeTermsEndpoint {
  constructor(private api: ApiClient) {
  }

  fetchAgreeTermsStatus = (agreementHash: string): Observable<AgreeTermsStatus> => {
    return this.api.get(`/agree-terms/${agreementHash}/status`);
  };

  updateAgreeTermsStatus = (agreementHash: string, data: any): Observable<AgreeTermsStatus> => {
    return this.api.patch(`/agree-terms/${agreementHash}/status`, data);
  };

  generateAgreeTermsPdf = (data: any) => {
    return this.api.post(`/agree-terms/generate-document`, data);
  };
}
