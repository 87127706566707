import { Injectable } from '@angular/core';
import { AppStore } from '@k2/common/state/services/app-store';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActionDispatcher {
  constructor(private store: AppStore<any>) {}

  dispatch = (action: Action): Observable<any> => this.store.dispatch(action);
}
