import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Subscriptions } from '@k2/common/helpers';
import { getSessionUserAvatar, getSessionUserId } from '@k2/common/sessions-state/reducers';
import { AppStore } from '@k2/common/state/services/app-store';
import { ClientAssignmentComponent } from '@k2/staff/content/clients/client/assignment/client-assignment.component';
import { StaffAppState } from '@k2/staff/staff-app-state';
import { select } from '@ngrx/store';

@Component({
  selector: 'services-messages',
  templateUrl: 'services-messages.component.html',
  styleUrls: ['services-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesMessagesComponent implements OnInit, OnDestroy {
  @Input() messages: any[];
  @Input() service?: { name: string };
  @Input() services?: any[];
  @Input() serviceId?: number;

  private readonly subscriptions = new Subscriptions();
  clientId: number;
  assignmentId: number;
  userId: number;
  currentUserAvatar: any;
  filterService: '' | { id: number; name: string };
  selectedService: any;
  draftText = '';

  constructor(
    private store: AppStore<StaffAppState>,
    private assignmentRef: ClientAssignmentComponent,
    private backend: Backend
  ) {
    this.clientId = assignmentRef.identity.clientId;
    this.assignmentId = assignmentRef.identity.assignmentId;

    this.subscriptions.add(
      store.pipe(select(getSessionUserId)).subscribe(userId => {
        this.userId = userId;
      })
    );

    this.subscriptions.add(
      store.pipe(select(getSessionUserAvatar)).subscribe(avatar => {
        this.currentUserAvatar = avatar;
      })
    );

    this.filterService = null;
  }

  ngOnInit(): void {
    this.filterService = '';
    this.selectedService = this.services ? this.services[0] : null;
  }

  findService = (serviceId: number) => {
    return this.service
      ? this.service
      : this.services.find(service => {
          return service.id === serviceId;
        });
  };

  addMessage = () => {
    if (this.draftText.trim().length === 0) return;

    const message = this.createNewMessage();
    this.messages.push(message);
    this.postMessage(message);
    this.draftText = '';
  };

  createNewMessage = () => {
    return {
      postState: 'non-persisted',
      text: this.draftText,
      created_at: new Date().toISOString(),
      author: {
        id: this.userId,
        avatar: this.currentUserAvatar
      },
      services_id: this.serviceId ? this.serviceId : this.selectedService.id
    };
  };

  postMessage = (message: any) => {
    const toPost = {
      message_text: message.text,
      services_id: message.services_id
    };

    this.subscriptions.add(
      this.backend.assignmentAnalysis
        .createNewAnalysisMessage(this.assignmentRef.identity, toPost)
        .subscribe(
          () => {
            message.postState = 'persisted';
          },
          () => {
            message.postState = 'failed';
            this.scheduleMessagePost(message);
          }
        )
    );
  };

  scheduleMessagePost = (message: any) => {
    setTimeout(function () {
      this.postMessage(message);
    }, 15000);
  };

  synchroniseServiceSelectors(newService: '' | { id: number; name: string }) {
    if (newService != '') {
      this.filterService = newService;
      this.selectedService = newService;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
