import { CurrencyPipe, DatePipe } from '@angular/common';
import { InjectionToken, Provider } from '@angular/core';
import { FieldSpec } from '@k2/common/k2-forms-state/types';
import { addressFormatter } from '@k2/common/k2-forms/field-spec/formatters/address';
import { booleanFormatter } from '@k2/common/k2-forms/field-spec/formatters/boolean';
import { budgetFormatter } from '@k2/common/k2-forms/field-spec/formatters/budget';
import { dateFormatter } from '@k2/common/k2-forms/field-spec/formatters/date';
import { datetimeFormatter } from '@k2/common/k2-forms/field-spec/formatters/datetime';
import { multiOptionsFormatter } from '@k2/common/k2-forms/field-spec/formatters/multi-options';
import { multiSelectFormatter } from '@k2/common/k2-forms/field-spec/formatters/multi-select';
import { multidateFormatter } from '@k2/common/k2-forms/field-spec/formatters/multidate';
import { optionsFormatter } from '@k2/common/k2-forms/field-spec/formatters/options';
import { telephoneFormatter } from '@k2/common/k2-forms/field-spec/formatters/telephone';
import { textFormatter } from '@k2/common/k2-forms/field-spec/formatters/text';
import { textareaFormatter } from '@k2/common/k2-forms/field-spec/formatters/textarea';
import { TelephonePipe } from '@k2/common/telephone/pipes/telephone.pipe';
import { curry, fromPairs } from 'ramda';

export const FIELD_SPEC_VALUE_FORMATTERS = new InjectionToken('Field spec value formatters');
export const FIELD_SPEC_VALUE_FORMATTERS_LIST = new InjectionToken(
  'Field spec value formatters list'
);

export const FORMATTERS_PROVIDER: Provider = {
  provide: FIELD_SPEC_VALUE_FORMATTERS,
  useFactory: createFormatters,
  deps: [FIELD_SPEC_VALUE_FORMATTERS_LIST]
};

export function createFormatters(list: Array<{ type: string; formatter: Formatter<any> }>) {
  const pairs = list.map(formatter => [formatter.type, formatter.formatter]);
  return fromPairs(pairs as any);
}

export const FORMATTERS_LIST_PROVIDER: Provider[] = [
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: selectFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: select2FormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: multiSelectFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: textFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: emailFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: numberFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: multidateFormatterFactory,
    deps: [DatePipe],
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: datetimeFormatterFactory,
    deps: [DatePipe],
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: timeSlotFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: textareaFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: yesnoFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: addressFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: radioFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: checkboxFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: checkboxesFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: budgetFormatterFactory,
    deps: [CurrencyPipe],
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: contactCardsFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: pricingCardsFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: labelFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: airportSelectorFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: hiddenFormatterFactory,
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: telFormatterFactory,
    deps: [TelephonePipe],
    multi: true
  },
  {
    provide: FIELD_SPEC_VALUE_FORMATTERS_LIST,
    useFactory: dateFormatterFactory,
    deps: [DatePipe],
    multi: true
  }
];

export function selectFormatterFactory() {
  return { type: 'select', formatter: optionsFormatter };
}

export function select2FormatterFactory() {
  return { type: 'select2', formatter: optionsFormatter };
}

export function multiSelectFormatterFactory() {
  return { type: 'multiSelect', formatter: multiSelectFormatter };
}

export function textFormatterFactory() {
  return { type: 'text', formatter: textFormatter };
}

export function emailFormatterFactory() {
  return { type: 'email', formatter: textFormatter };
}

export function numberFormatterFactory() {
  return { type: 'number', formatter: textFormatter };
}

export function multidateFormatterFactory(pipe: DatePipe) {
  return { type: 'multidate', formatter: curry(multidateFormatter)(pipe) };
}

export function datetimeFormatterFactory(pipe: DatePipe) {
  return { type: 'datetime', formatter: curry(datetimeFormatter)(pipe) };
}

export function timeSlotFormatterFactory() {
  return { type: 'timeSlot', formatter: null };
}

export function textareaFormatterFactory() {
  return { type: 'textarea', formatter: textareaFormatter };
}

export function yesnoFormatterFactory() {
  return { type: 'yesno', formatter: booleanFormatter };
}

export function addressFormatterFactory() {
  return { type: 'address', formatter: addressFormatter };
}

export function radioFormatterFactory() {
  return { type: 'radio', formatter: optionsFormatter };
}

export function checkboxFormatterFactory() {
  return { type: 'checkbox', formatter: booleanFormatter };
}

export function checkboxesFormatterFactory() {
  return { type: 'checkboxes', formatter: multiOptionsFormatter };
}

export function budgetFormatterFactory(pipe: CurrencyPipe) {
  return { type: 'budget', formatter: curry(budgetFormatter)(pipe) };
}

export function contactCardsFormatterFactory() {
  return { type: 'contactCards', formatter: null };
}

export function pricingCardsFormatterFactory() {
  return { type: 'pricingCards', formatter: null };
}

export function labelFormatterFactory() {
  return { type: 'label', formatter: null };
}

export function airportSelectorFormatterFactory() {
  return { type: 'airportSelector', formatter: null };
}

export function hiddenFormatterFactory() {
  return { type: 'hidden', formatter: null };
}

export function telFormatterFactory(pipe: TelephonePipe) {
  return { type: 'tel', formatter: curry(telephoneFormatter)(pipe) };
}

export function dateFormatterFactory(pipe: DatePipe) {
  return { type: 'date', formatter: curry(dateFormatter)(pipe) };
}

export interface Formatters {
  [type: string]: Formatter<any>;
}

export type Formatter<T> = (fieldSpec: FieldSpec) => string;
