<div class="collapsible-block-headline">
  <div class="heading">
    <ng-content select="[header]"></ng-content>
  </div>

  <a (click)="changeCollapsed(!collapsed)" class="toggle pull-right">
    <span *ngIf="collapsed" class="glyphicon glyphicon-plus"> </span>
    <span *ngIf="!collapsed" class="glyphicon glyphicon-minus"> </span>
  </a>
</div>

<div [ngClass]="collapsed ? 'd-none' : 'collapsible-block-content'">
  <ng-content *ngIf="!collapsed" select="[content]"></ng-content>
</div>
