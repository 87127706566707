import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { Subscriptions } from '@k2/common/helpers';

@Component({
  selector: 'annotated-control',
  templateUrl: 'annotated-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotatedControlComponent implements AfterViewInit, OnDestroy {
  @Input() field: Field;
  private readonly subscriptions = new Subscriptions();

  constructor(private changeDetector: ChangeDetectorRef) {}

  get prefix(): string {
    return this.field.attributes.prefix;
  }

  get suffix(): string {
    return this.field.attributes.suffix;
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.field.attributeChanges.subscribe(() => this.changeDetector.markForCheck())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
