<div *ngIf="reducedFormat; else extended">
  <div *ngIf="!isAssignmentEncrypted" class="address-line">
    <div *ngIf="address?.address1" class="d-inline">{{ address?.address1 }}</div>
    <div *ngIf="address?.address2" class="d-inline">{{ ", " + address?.address2 }}</div>
    <div *ngIf="address?.address3" class="d-inline">{{ ", " + address?.address3 }}</div>
    <div *ngIf="address?.address4" class="d-inline">{{ ", " + address?.address4 }}</div>
  </div>
  <div class="address-line">
    <div *ngIf="address?.city" class="d-inline">{{ address?.city }}</div>
    <div *ngIf="address?.county" class="d-inline">{{ ", " + address?.county }}</div>
    <div *ngIf="address?.postal_code" class="d-inline">{{ ", " + address?.postal_code }}</div>
    <div *ngIf="address?.country_name" class="d-inline">{{ ", " + address?.country_name }}</div>
  </div>
</div>

<ng-template #extended>
  <span class="name">{{ address?.name }}</span>
  <span class="address-line">{{ address?.salutation }}</span>
  <span *ngIf="!isAssignmentEncrypted" class="address-line">{{ address?.address1 }}</span>
  <span *ngIf="!isAssignmentEncrypted" class="address-line">{{ address?.address2 }}</span>
  <span *ngIf="!isAssignmentEncrypted" class="address-line">{{ address?.address3 }}</span>
  <span *ngIf="!isAssignmentEncrypted" class="address-line">{{ address?.address4 }}</span>
  <span class="address-line">{{ address?.city }}</span>
  <span class="address-line">{{ address?.county }}</span>
  <span class="address-line">{{ address?.postal_code }}</span>
  <span class="address-line">{{ address?.country_name }}</span>
</ng-template>
