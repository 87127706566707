<div *ngIf="data.datasets.length > 0">
  <canvas
    baseChart
    [data]="data"
    [labels]="labels"
    [options]="options"
    [legend]="false"
    [type]="'doughnut'"
    [height]="height"
  ></canvas>

  <div [innerHtml]="legend" class="legend"></div>
</div>

<no-data *ngIf="data.datasets.length === 0">No data available</no-data>
