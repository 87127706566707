<ng-container *ngSubscribe="data as data">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <alert-bar *ngIf="data.notes">{{ data.notes }}</alert-bar>

  <ng-container *ngSubscribe="form as form">
    <form [formGroup]="form" (submit)="submit()" class="standard">
      <mat-dialog-content>
        <ng-template component-host [spec]="data.fieldsCmp"></ng-template>
      </mat-dialog-content>

      <mat-dialog-actions>
        <submit-control (cancel)="cancel()" [icon]="data.submitIcon || 'ok'">
          {{ data.submitLabel || 'Save changes' }}
        </submit-control>
      </mat-dialog-actions>
    </form>
  </ng-container>
</ng-container>
