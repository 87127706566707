import { DocumentFile } from '@k2/common/entities-state/types';
import { Action } from '@ngrx/store';
import { contains } from 'ramda';

export const DELETE_DOCUMENT_FILES = '[Documents] Delete files';

export type Actions = DeleteDocumentFiles;

export class DeleteDocumentFiles implements Action {
  readonly type = DELETE_DOCUMENT_FILES;

  constructor(readonly files: DocumentFile[]) {}

  /**
   * Creates action, which will delete all document files, which are not desired.
   */
  static from(desired: DocumentFile[] = [], files: DocumentFile[] = []): DeleteDocumentFiles {
    const toBeDeleted = files.filter(file => !contains(file, desired));
    return new DeleteDocumentFiles(toBeDeleted);
  }
}
