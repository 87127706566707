import { NgModule } from '@angular/core';
import { K2TeamIngotComponent } from '@k2/common/k2-users/components/k2-team-ingot/k2-team-ingot.component';
import { K2UserAssignmentsIngotComponent } from '@k2/common/k2-users/components/k2-user-ingot/k2-user-assignments-ingot.component';
import { K2UserContactIngotComponent } from '@k2/common/k2-users/components/k2-user-ingot/k2-user-contact-ingot.component';
import { K2UserIngotComponent } from '@k2/common/k2-users/components/k2-user-ingot/k2-user-ingot.component';
import { K2UsersSelectorComponent } from '@k2/common/k2-users/components/k2-users-selector/k2-users-selector.component';
import { K2UserDialogs } from '@k2/common/k2-users/dialogs/k2-user-dialogs.service';
import { SharedModule } from '@k2/common/shared.module';
import { UsersModule } from '@k2/common/users/users.module';

const components = [
  K2UsersSelectorComponent,
  K2UserIngotComponent,
  K2TeamIngotComponent,
  K2UserContactIngotComponent,
  K2UserAssignmentsIngotComponent
];

@NgModule({
  imports: [SharedModule, UsersModule],
  declarations: components,
  exports: [...components, UsersModule],
  providers: [K2UserDialogs]
})
export class K2UsersModule {}
