<div>
  <div class="name">Total containers:</div>
  <div class="value">{{ containers }}</div>
</div>

<div>
  <div class="name">Total volume:</div>
  <div>
    <span class="value">{{ volume | number: '1.0-0' }}</span>
    <span class="unit">CbFT</span>
  </div>
</div>

<div>
  <div class="name">Assignments:</div>
  <div class="value">{{ assignments }}</div>
</div>
