import { Component, Input, OnInit } from '@angular/core';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'radio-control',
  templateUrl: 'radio-control.component.html',
  styleUrls: ['radio-control.component.scss']
})
export class RadioControlComponent implements OnInit {
  @Input() field: Field<string | number>;
  private readonly random: number;
  inline: boolean = false;

  constructor() {
    this.random = Math.floor(Math.random() * 10e6);
  }
  ngOnInit() {
    this.inline = this.field.attributes.inline;
  }

  // Necessary for avoiding an Angular issue, when radio groups are
  // bleeding their values outside of their parent forms.
  // https://github.com/angular/material2/issues/5983#issuecomment-317540719
  get randomizedFieldName() {
    return `${this.field.name}-${this.random}`;
  }

  get hasALotOptions(): boolean {
    return this.options.length > 8;
  }

  get options(): ValueOption[] {
    return this.field.attributes.valueOptions;
  }
}
