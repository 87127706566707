import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Partner } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import {
  PartnerTransitServiceFinderDialogComponent,
  PartnerTransitServiceFinderDialogData
} from '@k2/common/partners/component/partner-transit-service-finder/partner-transit-service-finder-dialog.component';

@Component({
  selector: 'partner-transit-service-finder',
  templateUrl: 'partner-transit-service-finder.component.html',
  styleUrls: ['partner-transit-service-finder.component.scss']
})
export class PartnerTransitServiceFinderComponent {
  @Input() field: Field<Partner>;
  selected: Partner;
  placeholder = 'Click to find a partner';
  disabled = false;
  private searchParams: { [name: string]: string } = {};
  private _isValid: boolean;

  constructor(private dialog: MatDialog) {}

  openDialog = () => {
    const data: PartnerTransitServiceFinderDialogData = {
      searchParams: this.searchParams
    };

    this.dialog
      .open(PartnerTransitServiceFinderDialogComponent, {
        width: '600px',
        minHeight: '310px',
        data
      })
      .afterClosed()
      .subscribe((partner?: Partner | null) => {
        if (partner !== undefined) {
          this.selected = partner;
          this.field.control.setValue(partner);
          this.field.control.updateValueAndValidity();
        }
      });
  };

  clear = () => {
    this.selected = null;
    this.field.control.setValue(null);
    this.field.control.updateValueAndValidity();
  };

  set isValid(isValid: boolean) {
    this._isValid = isValid;
    this.field.control.updateValueAndValidity();
  }

  get isValid(): boolean {
    return this._isValid;
  }
}
