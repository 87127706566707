<div [id]="field.attributes.id" (blur)="touch()">
  <div *ngFor="let group of groups" class="group">
    <div class="head">
      {{ group.name }}
      <input
        type="checkbox"
        [ngModel]="areAllChecked(group)"
        (ngModelChange)="toggleAll(group)"
      />
    </div>

    <div *ngFor="let option of group.options" class="option">
      <label>
        <span>{{ option.name }}</span>
        <input
          type="checkbox"
          [(checklist)]="value"
          [checklistValue]="option.id"
        />
      </label>
    </div>
  </div>
</div>
