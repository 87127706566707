import { Action } from '@ngrx/store';

export const ADD_PERMISSIONS = '[Permissions] Add permissions';

export type Actions = AddPermissions;

export class AddPermissions implements Action {
  readonly type = ADD_PERMISSIONS;

  constructor(readonly normalizedUrl: string, readonly permissions: string[]) {}
}
