<canvas
  *ngIf="chart != null"
  baseChart
  height="300"
  [data]="chart.data"
  [labels]="chart.labels"
  [options]="chart.options"
  [legend]="true"
  [type]="chart.type"
></canvas>
