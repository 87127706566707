import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AssigneeGuard, ClientGuard, StaffGuard } from '@k2/app-guards';
import { AppRouteStrategy } from '@k2/app-route-strategy';
import { AppComponent } from '@k2/app.component';
import { EntitiesStateModule } from '@k2/common/entities-state/entities-state.module';
import { FileJetModule } from '@k2/common/file-jet/file-jet.module';
import { FlashMessagesModule } from '@k2/common/flash-messages/flash-messages.module';
import { K2BrandModule } from '@k2/common/k2-brand/k2-brand.module';
import { PageNotFoundComponent } from '@k2/common/k2-brand/page-not-found/page-not-found.component';
import { LocaleModule } from '@k2/common/locale.module';
import { PermissionsStateModule } from '@k2/common/permissions-state/permissions-state.module';
import { RoutingModule } from '@k2/common/routing/routing.module';
import { SessionsStateModule } from '@k2/common/sessions-state/sessions-state.module';
import { SessionsModule } from '@k2/common/sessions/sessions.module';
import { rootReducerFactory } from '@k2/common/state/reducer-utils';
import { rootMetaReducers, StateModule } from '@k2/common/state/state.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { BidSummaryReportComponent } from './bid-summary-report/bid-summary-report.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { K2FormsModule } from '@k2/common/k2-forms/k2-forms.module';
import { AgreeTermsComponent } from '@k2/agree-terms/agree-terms.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UIModule } from '@k2/common/ui/ui.module';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

const routes: Routes = [
  // Support deprecated "/dashboard/*" routes
  { path: 'dashboard', pathMatch: 'full', redirectTo: 'auth' },
  { path: 'dashboard/assignee', redirectTo: 'assignee' },
  { path: 'dashboard/client', redirectTo: 'client' },
  { path: 'dashboard/staff', redirectTo: 'staff' },

  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  { path: 'login', pathMatch: 'full', redirectTo: 'auth/login' },
  {
    path: 'auth',
    loadChildren: () => import('@k2/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'sso',
    loadChildren: () => import('@k2/sso/sso.module').then(m => m.SsoModule)
  },
  {
    path: 'assignee',
    canActivate: [AssigneeGuard],
    loadChildren: () => import('@k2/assignee/assignee-app.module').then(m => m.AssigneeAppModule)
  },
  {
    path: 'client',
    canActivate: [ClientGuard],
    loadChildren: () => import('@k2/client/client-app.module').then(m => m.ClientAppModule)
  },
  {
    path: 'staff',
    canActivate: [StaffGuard],
    loadChildren: () => import('@k2/staff/staff-app.module').then(m => m.StaffAppModule)
  },
  {
    path: 'bid-summary/:offerHash',
    pathMatch: 'full',
    redirectTo: 'bid-summary/:offerHash/#'
  },
  {
    path: 'bid-summary/:offerHash/:id',
    component: BidSummaryReportComponent
  },
  {
    path: 'agree-terms/:agreementHash',
    pathMatch: 'full',
    component: AgreeTermsComponent
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LocaleModule,
    RouterModule.forRoot(routes, {}),
    StoreModule.forRoot(
      {},
      {
        metaReducers: rootMetaReducers,
        reducerFactory: rootReducerFactory,
        runtimeChecks: {
          strictActionImmutability: false,
          strictActionSerializability: false,
          strictStateImmutability: false,
          strictStateSerializability: false
        }
      }
    ),
    RoutingModule,
    EffectsModule.forRoot([]),
    StateModule.forRoot(),
    HttpClientModule,
    NgProgressModule,
    NgProgressHttpModule,
    FileJetModule,
    K2BrandModule,
    FlashMessagesModule,
    EntitiesStateModule,
    SessionsStateModule,
    PermissionsStateModule,
    SessionsModule,
    MatButtonModule,
    MatIconModule,
    K2FormsModule,
    ReactiveFormsModule,
    UIModule,
    AngularSignaturePadModule
  ],
  declarations: [AppComponent, BidSummaryReportComponent, AgreeTermsComponent],
  bootstrap: [AppComponent],
  providers: [{ provide: RouteReuseStrategy, useClass: AppRouteStrategy }]
})
export class AppModule {
}
