import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isNotNil } from '@k2/common/helpers';
import { Field, FieldTooltip } from '@k2/common/k2-forms-state/types';
import { equals } from 'ramda';

/**
 * Wrapper for FieldTemplate component.
 *
 * Component will construct a normalized field based on a given Field definition.
 *
 * Additional sub-fields may be provided. Any additional field will be inlined with a main field automatically.
 * Errors from an additional fields will be merged with main field errors.
 */
@Component({
  selector: 'field',
  templateUrl: 'field.component.html',
  styleUrls: ['field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FieldComponent implements OnChanges {
  @Input('field') mainField: Field;
  @Input() subFields: Field[] = [];
  @Input() changeServiceToUniversal: EventEmitter<any>;
  @Output() onFieldUpdate = new EventEmitter<string>();

  fields: Field[];
  controls: UntypedFormControl[];

  ngOnChanges() {
    this.fields = [this.mainField, ...this.subFields].filter(isNotNil);
    this.controls = this.fields.map(f => f.control);
  }

  onFieldControlChange(field: Field): void {
    this.onFieldUpdate.emit(field.control.value);
  }

  get isVisible(): boolean {
    if (this.mainField.attributes.display_if_null === undefined) {
      return true;
    } else if (this.mainField.defaultValue === null) {
      return this.mainField.attributes.display_if_null;
    }
    return true;
  }

  get tooltip(): FieldTooltip {
    return this.mainField.attributes.tooltip;
  }

  get shouldCollapseHorizontally() {
    return ['checkbox', 'checkboxes', 'radio', 'yesno'].some(
      equals(this.mainField.attributes.display_type)
    );
  }

  get isIdInvoiceAddresses() {
    return this.mainField.attributes.id === 'invoiceAddresses';
  }
}
