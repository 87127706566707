import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements AfterContentInit {
  @Input() autofocus: string | boolean;

  public constructor(private el: ElementRef) {}

  public ngAfterContentInit() {
    if (this.autofocus === '' || this.autofocus === true) {
      setTimeout(() => this.el.nativeElement.focus(), 300);
    }
  }
}
