export interface RootState {
  entities: State;
}

export interface State {
  [entityType: string]: EntitiesMap;
}

export interface EntitiesMap<T = any> {
  [entityId: string]: T;
}

export const initialState: State = {};
