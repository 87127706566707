import { NgModule } from '@angular/core';
import { SharedModule } from '@k2/common/shared.module';
import { DocumentsUploaderComponent } from '@k2/common/uploads/components/documents-uploader/documents-uploader.component';
import { ExistingDocumentsListComponent } from '@k2/common/uploads/components/existing-documents-list/existing-documents-list.component';
import { ImageUploaderComponent } from '@k2/common/uploads/components/image-uploader/image-uploader.component';
import { NoFileMsgComponent } from '@k2/common/uploads/components/no-file-msg/no-file-msg.component';
import '@k2/common/uploads/fine-uploader/fine-uploader.js';

const components = [
  DocumentsUploaderComponent,
  ImageUploaderComponent,
  ExistingDocumentsListComponent,
  NoFileMsgComponent
];

@NgModule({
  imports: [SharedModule],
  declarations: components,
  exports: components
})
export class UploadsModule {}
