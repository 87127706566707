import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gender-bar',
  templateUrl: 'gender-bar.component.html',
  styleUrls: ['gender-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenderBarComponent {
  @Input() keyGender: number;
  @Input() anotherGender: number;
  @Input() genderIcon: 'male' | 'female' | 'unknown';

  get percentage(): number {
    return (100 / (this.keyGender + this.anotherGender)) * this.keyGender;
  }
}
