<div class="exceptions-section">
  <k2-table [items]="exceptions" [paginator]="paginator">
    <ng-container header><h1>Exceptions</h1></ng-container>

    <ng-container buttons>
      <btn *ngIf="!isAssignmentEncrypted" icon="plus" (click)="addException(serviceId)">Add exception</btn>
    </ng-container>

    <table>
      <thead>
      <tr>
        <th>Exception summary</th>
        <th>Recipient of the exception</th>
        <th>Request date</th>
        <th>Request cost</th>
        <th>Exception status</th>
        <th>Decision date</th>
        <th>Client contacts</th>
        <th class="options">Options</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let exception of exceptions | paginate : paginator | async">
        <td>{{ exception.summary }}</td>
        <td>{{ isAssignmentEncrypted ? '' : exception.exceptionFor }}</td>
        <td>
          <date [iso]="exception.requested_at"></date>
        </td>
        <td>
          <span [class.negative]="exception.item_amount < 0">
            {{ exception.cost.currency.iso3 }}&nbsp;{{ exception.cost.amount | number: '1.2-2' }}
          </span>
        </td>
        <td>{{ exception.status.label }}</td>
        <td>
          <date [iso]="exception.decision_date"></date>
        </td>
        <td>{{ exception.client_contact }}</td>
        <td class="options">
          <inline-options *ngIf="!isAssignmentEncrypted" (remove)="deleteException(exception.id)">
            <a (click)="updateExceptions(exception.id)"><action>edit</action></a>
          </inline-options>
        </td>
      </tr>
      </tbody>
    </table>

    <no-data ifNothing>There are no exceptions for this service.</no-data>
  </k2-table>
</div>
