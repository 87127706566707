import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isEmpty } from 'ramda';
import { AssignmentServiceProgress, Workflow } from "../../../entities-state/types";

@Component({
  selector: 'assignment-services',
  templateUrl: 'assignment-services.component.html',
  styleUrls: ['assignment-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentServicesComponent {
  @Input() workflows: Workflow[];
  @Input() hhgWorkflow: Workflow;

  clickedServiceId: number;

  toServiceLink = (serviceId: number, serviceName: string) => {
    if (typeof serviceName !== undefined && serviceName === 'Real Estate') {
      return './real-estate';
    }

    switch (serviceId) {
      case 27:
        return './hhg/storage';
      case 28:
        return './hhg/insurance';
      case 26:
        return './hhg';
      default:
        return ['./services', serviceId];
    }
  };

  toServiceLinkByName = (serviceName: string) => {
    switch (serviceName) {
      default:
        console.log(serviceName);
        return ['./services', serviceName];
    }
  }

  get hasServices(): boolean {
    return this.hhgWorkflow != null || this.workflows.some(workflow => !isEmpty(workflow.services));
  }

  isVisaImmigrationService(id: number): boolean {
    return id === 7;
  }

  showAllServiceStatuses(id: number): boolean {
    return this.clickedServiceId === id;
  }

  onServiceOpenClick(id: number) {
    this.clickedServiceId = this.showAllServiceStatuses(id) ? null : id;
  }

  getServiceStatus(progress: AssignmentServiceProgress[]): string {
    if (!progress.length || progress.every(s => s.value === 'Not started')) {
      return 'Not started';
    } else if (progress.every(s => s.value === 'Completed')) {
      return 'Completed';
    } else {
      return 'In progress';
    }
  }
}
