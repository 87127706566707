import { Inject, Injectable, InjectionToken } from '@angular/core';

export const LINK_GENERATORS = new InjectionToken<LinkGenerators>('linkGenerators');

export interface LinkGenerators {
  assignee(data: { clientId: number; assignmentId: number; assigneeId: number }): Link;
  assignment(data: { clientId?: number; assignmentId: number }): Link;
  linkedAssignment(data: { clientId: number; assignmentId: number }): Link;
  client(data: { clientId: number }): Link;
  k2User(data: { userId: number }): Link;
  clientUser(data: { userId: number }): Link;
  office(data: { officeId: number }): Link;
  partner(data: { partnerId: number }): Link;
  hhgOriginAddress(data: { clientId: number; assignmentId: number }): Link;
  hhgDestinationAddress(data: { clientId: number; assignmentId: number }): Link;
  hhgTransits(data: { clientId: number; assignmentId: number }): Link;
  hhgSurveyCreate(data: { clientId: number; assignmentId: number }): Link;
  hhgSurveyDateConfirmed(data: { clientId: number; assignmentId: number }): Link;
  hhgPostSurveyCall(data: { clientId: number; assignmentId: number }): Link;
  hhgSurveyPropertyDetails(data: { clientId: number; assignmentId: number }): Link;
  hhgSurveyVolumes(data: { clientId: number; assignmentId: number }): Link;
  hhgSurveySubmitted(data: { clientId: number; assignmentId: number }): Link;
  hhgGenerateCostRequests(data: { clientId: number; assignmentId: number }): Link;
  hhgPartnerQuotes(data: { clientId: number; assignmentId: number }): Link;
  hhgGenerateBidSummary(data: { clientId: number; assignmentId: number }): Link;
  hhgBidSummaryApproved(data: { clientId: number; assignmentId: number }): Link;
  hhgConfirmPartners(data: { clientId: number; assignmentId: number }): Link;
}

export interface LinkSpec {
  readonly type: keyof LinkGenerators;
  readonly data: any;
}

@Injectable()
export class LinkGenerator {
  constructor(@Inject(LINK_GENERATORS) private generators: LinkGenerators) {}

  generate({ type, data }: ExtractLinkSpec<LinkGenerators>) {
    const generator: any = this.generators[type];
    if (generator == null) throw Error(`Link type "${type}" is not supported!`);
    return generator(data);
  }
}

type Link = string;

type ParametersOrNot<T> = T extends (...args: any[]) => any ? Parameters<T> : never;

type ExtractLinkSpec<T extends any> = {
  [K in keyof T]: { type: K; data: ParametersOrNot<T[K]>[0] };
}[keyof T];
