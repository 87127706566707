<profile-ingot>
  <div>
    <a [routerLink]="linkedAssignment | linkedAssignmentLink" tabindex="-1">
      {{ linkedAssignment.assignmentId | k2Id }}
    </a>
  </div>
  <div>
    Client:
    <a [routerLink]="linkedAssignment.client.id | clientLink" tabindex="-1">
      {{ linkedAssignment.client.name }}
    </a>
  </div>
  <div *ngIf="linkedAssignment.assignee?.name">
    Assignee: {{ linkedAssignment.assignee.name }}
  </div>
</profile-ingot>
