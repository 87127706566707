import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { K2User } from '@k2/common/entities-state/types';
import { K2UserIngotComponent } from '@k2/common/k2-users/components/k2-user-ingot/k2-user-ingot.component';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { AutocompleteSelectorConfig } from '@k2/common/ui/components/autocomplete-selector/autocomplete-selector.component';
import { toUserName } from '@k2/common/ui/pipes/user-name.pipe';
import { StoreUsers } from '@k2/common/users-state/actions';
import { UserAutocompleteOptionComponent } from '@k2/common/users/components/user-autocomplete-option/user-autocomplete-option.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'k2-users-selector',
  templateUrl: 'k2-users-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K2UsersSelectorComponent implements OnChanges {
  @Input() selected: K2User[];
  @Input() config: K2UsersSelectorConfig;
  @Input() onlyTeam?: boolean = false;
  @Output() selectedChange = new EventEmitter<K2User[]>();

  selectorConfig: AutocompleteSelectorConfig<K2User>;

  constructor(private backend: Backend, private actions: ActionDispatcher) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config == null) return;

    this.selectorConfig = {
      toId: (user: K2User) => user.id,
      toName: toUserName,
      query: (query: string) => {
        return this.backend.staff
          .findUsers(query, this.onlyTeam)
          .pipe(map(users => users.filter(user => !this.config.excluded.includes(user.id))));
      },
      toSelectedSpec: (user: K2User): ComponentSpec<K2UserIngotComponent> => ({
        component: K2UserIngotComponent,
        inputs: { user }
      }),
      toOptionSpec: (
        user: K2User,
        query: string
      ): ComponentSpec<Partial<UserAutocompleteOptionComponent>> => ({
        component: UserAutocompleteOptionComponent,
        inputs: { user, highlight: query }
      }),
      multiple: this.config.multiple,
      placeholder: this.config.placeholder,
      minQueryLength: 2
    };
  }

  update = (selected: K2User[]) => {
    this.selectedChange.emit(selected);

    if (selected.length > 0) {
      this.actions.dispatch(new StoreUsers(selected));
    }
  };
}

export interface K2UsersSelectorConfig {
  readonly multiple: boolean;
  readonly excluded: number[];
  readonly placeholder?: string;
}
