import { Actions, STORE_FORM_SPEC, UPDATE_FORM_VALUES } from '@k2/common/k2-forms-state/actions';
import { RootState, State } from '@k2/common/k2-forms-state/state';
import { FieldsSpec, FormSpecId } from '@k2/common/k2-forms-state/types';
import { Selector } from '@ngrx/store';
import { mapObjIndexed, mergeDeepRight, pipe } from 'ramda';

export function featureReducer(state: State = {}, action: Actions): State {
  switch (action.type) {
    case STORE_FORM_SPEC: {
      return {
        ...state,
        [stringifySpecId(action.specId)]: action.spec.fields
      };
    }
    case UPDATE_FORM_VALUES: {
      const id = stringifySpecId(action.specId);
      const fields = state[id];
      if (fields == null) return state;

      const values = mapObjIndexed(value => ({ value }), action.values);

      return {
        ...state,
        [id]: mergeDeepRight(fields, values)
      };
    }
    default: {
      return state;
    }
  }
}

export function getFieldsSpec(specId: FormSpecId): Selector<RootState, FieldsSpec> {
  return (state: RootState) => state.forms[stringifySpecId(specId)];
}

export function getFieldValue(specId: FormSpecId, fieldName: string): Selector<RootState, any> {
  return pipe(getFieldsSpec(specId), fields => fields[fieldName].value);
}

function stringifySpecId(specId: FormSpecId): string {
  return specId.join('/');
}
