<profile-ingot>
  <ng-container image>
    <file-jet-img
      [image]="user.avatar"
      [width]="40"
      [height]="40"
    ></file-jet-img>
  </ng-container>

  <a *ngIf="link" [routerLink]="link" tabindex="-1">{{ user | userName }}</a>
  <span *ngIf="!link">{{ user | userName }}</span>

  <ng-content></ng-content>
</profile-ingot>
