<div [id]="field.attributes.id" (blur)="touch()">
  <input [(formControl)]="customControl"
         type="email"
         class="form-control"
         placeholder="Enter emails or choose from existing" />

  <div *ngFor="let option of options">
    <label>
      <input type="checkbox" [(checklist)]="value" [checklistValue]="option.id" [disabled]="disabled" />
      <span>{{ option.value }}</span>
    </label>
  </div>
</div>
