import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { NumIndexed, Service, Workflow } from '@k2/common/entities-state/types';
import { clone, pickBy } from 'ramda';
import { AppStore } from '@k2/common/state/services/app-store';
import { ClientAppState } from '@k2/client/client-app-state';
import { select } from '@ngrx/store';
import { getSessionUser } from '@k2/common/sessions-state/reducers';
import { first } from 'rxjs/operators';
import { isNotNil } from '@k2/common/helpers';

const noVariation = { id: null, description: 'Not currently included' };

@Component({
  selector: 'additional-services-selector',
  templateUrl: 'additional-services-selector.component.html',
  styleUrls: ['additional-services-selector.component.scss', 'style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalServicesSelectorComponent implements OnChanges {
  @Input() services: NumIndexed<Service[]>;
  @Input('workflows') allWorkflows: Workflow[];
  @Output() servicesChange = new EventEmitter<NumIndexed<Service[]>>();

  workflows: Workflow[];
  isClientAdmin = false;

  constructor(private store: AppStore<ClientAppState>) {
    this.store.pipe(select(getSessionUser), first(isNotNil)).subscribe(user => {
      this.isClientAdmin = user.role_name === 'CLIENT_ADMIN';
    });
  }

  isVisible(id: number): boolean {
    return id !== 38 || !this.isClientAdmin;
  }

  ngOnChanges(): void {
    this.services = clone(this.services);
    this.workflows = pickBy(workflow => this.services[workflow.id] != null, this.allWorkflows);
  }

  toNormalizedVariations = (service: Service) => {
    return [noVariation, ...service.variations];
  };

  findSelectedVariation = (service: Service) => {
    return service.variations.find(({ enabled }) => enabled) || noVariation;
  };

  toggleService = (service: Service) => {
    service.enabled = !service.enabled;
    this.servicesChange.emit(this.services);
  };

  enableServiceVariation = (service: Service, variationId: number | null) => {
    service.variations = service.variations.map(variation => ({
      ...variation,
      enabled: variation.id === variationId
    }));

    this.servicesChange.emit(this.services);
  };
}
