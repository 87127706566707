import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

/**
 * Subscribes to the given observable and renders the inner template when that
 * observable emits.
 *
 * Resolved value will be passed into the inner template.
 *
 * EXAMPLE:
 *   <span *ngAsync="report.itemsCount as count">({{ count }})</span>
 */
@Directive({
  selector: '[ngAsync]'
})
export class NgAsyncDirective implements OnDestroy {
  private observable: Observable<any>;
  private context: Context;
  private subscription: Subscription;

  constructor(
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private templateRef: TemplateRef<any>
  ) {}

  @Input()
  set ngAsync(inputObservable: Observable<any>) {
    if (this.observable === inputObservable) return;

    this.observable = inputObservable;
    this.subscribe();
  }

  private subscribe = () => {
    this.context = null;
    this.unsubscribe();

    this.subscription = this.observable.subscribe(
      value => {
        if (this.context == null) {
          this.context = new Context();
          this.viewContainer.clear();
          this.viewContainer.createEmbeddedView(this.templateRef, this.context);
        }

        this.context.ngAsync = value;
        this.cdr.markForCheck();
      },
      error => {
        console.error(error);
        this.viewContainer.clear();
      }
    );
  };

  private unsubscribe = () => {
    if (this.subscription) this.subscription.unsubscribe();
  };

  ngOnDestroy = this.unsubscribe;
}

class Context {
  ngAsync = null;
}
