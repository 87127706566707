import { Component, Input } from '@angular/core';

@Component({
  selector: 'feedback-responses',
  templateUrl: 'feedback-responses.component.html',
  styleUrls: ['feedback-responses.component.scss']
})
export class FeedbackResponsesComponent {
  @Input() responses: Array<{ question: string; response: string }>;
}
