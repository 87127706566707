<profile-ingot>
  <ng-container image>
    <country-flag
      [code]="airport.iso_country"
      [matTooltip]="airport.iso_country"
    ></country-flag>
  </ng-container>

  <div class="name">{{ airport.name }}</div>

  <div>
    <span class="code">{{ airport.iata_code }}</span>
  </div>
</profile-ingot>
