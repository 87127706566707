import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DialogsModule } from '@k2/common/dialogs/dialogs.module';
import { K2SelectModule } from '@k2/common/k2-select/k2-select.module';
import { LinksModule } from '@k2/common/links/links.module';
import { MaterialModule } from '@k2/common/material/material.module';
import { PaginationModule } from '@k2/common/pagination/pagination.module';
import { PermissionsModule } from '@k2/common/permissions/permissions.module';
import { ActionComponent } from '@k2/common/ui/components/action/action.component';
import { AlertBarComponent } from '@k2/common/ui/components/alert-bar/alert-bar.component';
import { AnswerComponent } from '@k2/common/ui/components/answer/answer.component';
import {
  AutocompleteOptionComponent
} from '@k2/common/ui/components/autocomplete-option/autocomplete-option.component';
import {
  AutocompleteSelectorComponent
} from '@k2/common/ui/components/autocomplete-selector/autocomplete-selector.component';
import { BasicSelectComponent } from '@k2/common/ui/components/basic-select/basic-select.component';
import { PersonIconComponent } from '@k2/common/ui/components/being-icons/person-icon.component';
import { PetIconComponent } from '@k2/common/ui/components/being-icons/pet-icon.component';
import { BitComponent } from '@k2/common/ui/components/bit/bit.component';
import { BtnComponent } from '@k2/common/ui/components/btn/btn.component';
import { CardComponent } from '@k2/common/ui/components/card/card.component';
import { CardsListComponent } from '@k2/common/ui/components/card/cards-list.component';
import { CheckboxesTreeComponent } from '@k2/common/ui/components/checkboxes-tree/checkboxes-tree.component';
import { CollapsibleBlockComponent } from '@k2/common/ui/components/collapsible-block/collapsible-block.component';
import { ComponentsListComponent } from '@k2/common/ui/components/components-list/components-list.component';
import { CountryFlagComponent } from '@k2/common/ui/components/country-flag/country-flag.component';
import { FeedbackResponsesComponent } from '@k2/common/ui/components/feedback/feedback-responses.component';
import { FlexComponent } from '@k2/common/ui/components/flex/flex.component';
import { GenderBarComponent } from '@k2/common/ui/components/gender-bar/gender-bar.component';
import { HeadingModule } from '@k2/common/ui/components/heading/heading.module';
import { HeadlineComponent } from '@k2/common/ui/components/headline/headline.component';
import { HighlightedComponent } from '@k2/common/ui/components/highlighted/highlighted.component';
import { IconComponent } from '@k2/common/ui/components/icon/icon.component';
import { ImageOverlayModule } from '@k2/common/ui/components/image-overlay/image-overlay.module';
import { IngotComponent } from '@k2/common/ui/components/ingot/ingot.component';
import {
  InitiationNavButtonsComponent
} from '@k2/common/ui/components/initiation-nav-buttons/initiation-nav-buttons.component';
import { InlineOptionsComponent } from '@k2/common/ui/components/inline-options/inline-options.component';
import { InlineTextComponent } from '@k2/common/ui/components/inline-text/inline-text.component';
import { IntroComponent } from '@k2/common/ui/components/intro/intro.component';
import { K2TableComponent } from '@k2/common/ui/components/k2-table/k2-table.component';
import { LabeledComponent } from '@k2/common/ui/components/labeled/labeled.component';
import { LastUpdatedComponent } from '@k2/common/ui/components/last-updated/last-updated.component';
import { LinkIngotComponent } from '@k2/common/ui/components/link-ingot/link-ingot.component';
import { NoticeboardComponent } from '@k2/common/ui/components/noticeboard/noticeboard.component';
import { NotificationBadgeComponent } from '@k2/common/ui/components/notification-badge/notification-badge.component';
import { NumberInputComponent } from '@k2/common/ui/components/number-input/number-input.component';
import { PasswordStrengthComponent } from '@k2/common/ui/components/password-strength/password-strength.component';
import { PlaceSelectorComponent } from '@k2/common/ui/components/place-selector/place-selector.component';
import { EmptyComponent } from '@k2/common/ui/components/placeholders/empty.component';
import { NoDataComponent } from '@k2/common/ui/components/placeholders/no-data.component';
import { SelectPlaceholderComponent } from '@k2/common/ui/components/placeholders/select-placeholder.component';
import { ProfileIngotComponent } from '@k2/common/ui/components/profile-ingot/profile-ingot.component';
import { ProgressBarComponent } from '@k2/common/ui/components/progress-bar/progress-bar.component';
import { RegionsSelectorComponent } from '@k2/common/ui/components/regions-selector/regions-selector.component';
import { RemovableComponent } from '@k2/common/ui/components/removable/removable.component';
import { SearchComponent } from '@k2/common/ui/components/search/search.component';
import { SectionComponent } from '@k2/common/ui/components/section/section.component';
import {
  ServiceStatusIndicatorComponent
} from '@k2/common/ui/components/service-status-indicator/service-status-indicator.component';
import {
  SideNavigatedContentComponent
} from '@k2/common/ui/components/side-navigated-content/side-navigated-content.component';
import { SimpleTabComponent } from '@k2/common/ui/components/simple-tabs/simple-tab.component';
import { SimpleTabsComponent } from '@k2/common/ui/components/simple-tabs/simple-tabs.component';
import { StarRatingComponent } from '@k2/common/ui/components/star-rating/star-rating.component';
import { StatusLabelComponent } from '@k2/common/ui/components/status-label/status-label.component';
import { SubmitControlComponent } from '@k2/common/ui/components/submit-control/submit-control.component';
import { TabsComponent } from '@k2/common/ui/components/tabs/tabs.component';
import { TagComponent } from '@k2/common/ui/components/tag/tag.component';
import { DateTimeComponent } from '@k2/common/ui/components/time/date-time.component';
import { DateComponent } from '@k2/common/ui/components/time/date.component';
import { RelativeTimeComponent } from '@k2/common/ui/components/time/relative-time.component';
import { TodoComponent } from '@k2/common/ui/components/todo/todo.component';
import { TrueFalseIconComponent } from '@k2/common/ui/components/true-false-icon/true-false-icon.component';
import { UntrustedLinkComponent } from '@k2/common/ui/components/untrusted-link/untrusted-link.component';
import { StarIconComponent } from '@k2/common/ui/components/star-icon/star-icon.component';
import { YesNoLabelComponent } from '@k2/common/ui/components/yes-no-label/yes-no-label.component';
import { AutofocusDirective } from '@k2/common/ui/directives/autofocus.directive';
import { ComponentHostDirective } from '@k2/common/ui/directives/component-host.directive';
import { NgAsyncDirective } from '@k2/common/ui/directives/ng-async.directive';
import { ScrollToDirective } from '@k2/common/ui/directives/scroll-to.directive';
import {
  NgSubscribeDirective,
  ngSubscribeStateComponents
} from '@k2/common/ui/directives/subscribe/ng-subscribe.directive';
import { CashPipe } from '@k2/common/ui/pipes/cash';
import { ChunksPipe } from '@k2/common/ui/pipes/chunk-by.pipe';
import { ClassNamePipe } from '@k2/common/ui/pipes/class-name.pipe';
import { ConfigPropPipe } from '@k2/common/ui/pipes/config-prop.pipe';
import { CurrencyNamePipe } from '@k2/common/ui/pipes/currency-name.filter';
import { DateSuffixPipe } from '@k2/common/ui/pipes/date-suffix.pipe';
import { DaysPipe } from '@k2/common/ui/pipes/days.pipe';
import { EmbedGMapLinkPipe, GMapLinkPipe } from '@k2/common/ui/pipes/google-map.pipe';
import { HighlightPipe } from '@k2/common/ui/pipes/highlight.pipe';
import { IsEmptyPipe } from '@k2/common/ui/pipes/is-empty.pipe';
import { IsLoadedPipe } from '@k2/common/ui/pipes/is-loaded.pipe';
import { IsNotEmptyPipe } from '@k2/common/ui/pipes/is-not-empty.pipe';
import { K2IdPipe } from '@k2/common/ui/pipes/k2-id.pipe';
import { LeftPadPipe } from '@k2/common/ui/pipes/left-pad.pipe';
import { NegatePipe } from '@k2/common/ui/pipes/negate.pipe';
import { ObjToArray } from '@k2/common/ui/pipes/obj-to-array';
import { UniqPipe } from '@k2/common/ui/pipes/uniq.pipe';
import { UnitPipe } from '@k2/common/ui/pipes/unit.pipe';
import { UserNamePipe } from '@k2/common/ui/pipes/user-name.pipe';
import { ValuesPipe } from '@k2/common/ui/pipes/values.pipe';
import { MomentModule } from 'ngx-moment';
import { AutosizeModule } from 'ngx-autosize';
import { NoticeboardExpandableComponent } from './components/noticeboard-expandable/noticeboard-expandable.component';
import { TodoListComponent } from './components/todo-list/todo-list.component';
import { MilestonesComponent } from '@k2/common/ui/components/milestones/milestones.component';
import { EditDeleteOptionsComponent } from '@k2/common/ui/components/edit-delete-options/edit-delete-options.component';
import {
  DeletableItemOptionsComponent
} from '@k2/common/ui/components/deletable-item-options/deletable-item-options.component';
import { FormattedValuePipe } from '@k2/common/ui/pipes/formatted-value.pipe';
import {
  AirportAutocompleteOptionComponent
} from '@k2/common/ui/components/airports/airport-autocomplete-option.component';
import { AirportSelectorComponent } from '@k2/common/ui/components/airports/airport-selector.component';
import { AirportNamePipe } from '@k2/common/ui/pipes/airport-name.pipe';
import { AirportIngotComponent } from '@k2/common/ui/components/airports/airport-ingot.component';
import { EmailMessageComponent } from '@k2/common/ui/components/emails/email-message.component';
import { EmailContactComponent } from '@k2/common/ui/components/emails/email-contact.component';
import { TextDialogComponent } from '@k2/common/dialogs/components/text/text-dialog.component';
import { FileJetModule } from '@k2/common/file-jet/file-jet.module';
import { TimecComponent } from './components/time/timec.component';
import { TooltipDirective } from '@k2/common/ui/directives/tooltip.directive';
import { TooltipComponent } from '@k2/common/ui/components/tooltip/tooltip.component';
import { StatusSectionComponent } from "@k2/common/ui/components/status-section/status-section.component";
import { ExceptionsSectionComponent } from "@k2/common/ui/components/exceptions/exceptions-section.component";
import { CostSavingsComponent } from '@k2/common/ui/components/cost-savings/cost-savings.component';
import { MarkdownEditorComponent } from '@k2/common/ui/components/markdown-editor/markdown-editor.component';
import { NgxEditorModule } from 'ngx-editor';


const components = [
  EmptyComponent,
  NoDataComponent,
  SelectPlaceholderComponent,
  K2TableComponent,
  ActionComponent,
  TagComponent,
  CollapsibleBlockComponent,
  InitiationNavButtonsComponent,
  CountryFlagComponent,
  StarIconComponent,
  DateComponent,
  DateTimeComponent,
  RelativeTimeComponent,
  PersonIconComponent,
  PetIconComponent,
  TabsComponent,
  SimpleTabsComponent,
  SimpleTabComponent,
  StarRatingComponent,
  AlertBarComponent,
  PasswordStrengthComponent,
  TrueFalseIconComponent,
  IntroComponent,
  YesNoLabelComponent,
  GenderBarComponent,
  InlineOptionsComponent,
  ProfileIngotComponent,
  LastUpdatedComponent,
  RegionsSelectorComponent,
  NoticeboardComponent,
  NoticeboardExpandableComponent,
  InlineTextComponent,
  NotificationBadgeComponent,
  ProgressBarComponent,
  ComponentsListComponent,
  SubmitControlComponent,
  AutocompleteSelectorComponent,
  SearchComponent,
  AutocompleteOptionComponent,
  CheckboxesTreeComponent,
  IngotComponent,
  LinkIngotComponent,
  SideNavigatedContentComponent,
  BitComponent,
  UntrustedLinkComponent,
  IconComponent,
  BasicSelectComponent,
  HeadlineComponent,
  PlaceSelectorComponent,
  RemovableComponent,
  SectionComponent,
  LabeledComponent,
  CardComponent,
  CardsListComponent,
  NumberInputComponent,
  AnswerComponent,
  HighlightedComponent,
  EmailMessageComponent,
  EmailContactComponent,
  BtnComponent,
  FlexComponent,
  TodoComponent,
  TodoListComponent,
  StatusLabelComponent,
  FeedbackResponsesComponent,
  ServiceStatusIndicatorComponent,
  MilestonesComponent,
  EditDeleteOptionsComponent,
  DeletableItemOptionsComponent,
  AirportAutocompleteOptionComponent,
  AirportSelectorComponent,
  AirportIngotComponent,
  TextDialogComponent,
  TimecComponent,
  TooltipComponent,
  StatusSectionComponent,
  ExceptionsSectionComponent,
  CostSavingsComponent,
  MarkdownEditorComponent,
  ...ngSubscribeStateComponents
];

const directives = [
  AutofocusDirective,
  ScrollToDirective,
  ComponentHostDirective,
  NgAsyncDirective,
  NgSubscribeDirective,
  TooltipDirective
];

const pipes = [
  IsLoadedPipe,
  IsNotEmptyPipe,
  IsEmptyPipe,
  ClassNamePipe,
  GMapLinkPipe,
  EmbedGMapLinkPipe,
  DaysPipe,
  LeftPadPipe,
  IsEmptyPipe,
  HighlightPipe,
  DateSuffixPipe,
  NegatePipe,
  ValuesPipe,
  CurrencyNamePipe,
  K2IdPipe,
  UserNamePipe,
  UniqPipe,
  ObjToArray,
  UnitPipe,
  CashPipe,
  ConfigPropPipe,
  ChunksPipe,
  FormattedValuePipe,
  AirportNamePipe
];

const exportedModules = [ImageOverlayModule, HeadingModule, MaterialModule];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    PaginationModule,
    MomentModule,
    DialogsModule,
    ReactiveFormsModule,
    FormsModule,
    AutosizeModule,
    PermissionsModule,
    K2SelectModule,
    LinksModule,
    ...exportedModules,
    FileJetModule,
    NgxEditorModule
  ],
  declarations: [...components, ...directives, ...pipes, NoticeboardExpandableComponent],
  exports: [...components, ...directives, ...pipes, ...exportedModules],
  providers: [CurrencyPipe, ...pipes]
})
export class UIModule {
}
