import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Backend } from '@k2/common/backend/backend';
import { Partner } from '@k2/common/entities-state/types';
import { Place } from '@k2/common/ui/components/place-selector/place-selector.component';

@Component({
  templateUrl: 'partner-transit-service-finder-dialog.component.html',
  styleUrls: ['partner-transit-service-finder-dialog.component.scss']
})
export class PartnerTransitServiceFinderDialogComponent {
  private readonly searchParams: { [name: string]: string };
  readonly services = [20, 21, 22, 23, 24, 25];
  place: Place = { country: null, state: null };
  name = '';
  results: Partner[];

  constructor(
    private backend: Backend,
    private dialogRef: MatDialogRef<PartnerTransitServiceFinderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) { searchParams }: PartnerTransitServiceFinderDialogData
  ) {
    this.searchParams = searchParams;
  }

  search = () => {
    this.backend.partners
      .searchMultiServices({
        name: this.name,
        regionCode: this.place.country,
        stateCode: this.place.state,
        service: this.services.join('|'),
        ...this.searchParams
      })
      .subscribe(partners => {
        this.results = partners;
      });
  };

  select = (partner: Partner) => {
    this.dialogRef.close(partner);
  };
}

export interface PartnerTransitServiceFinderDialogData {
  readonly searchParams: { [name: string]: string };
}
