import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListDialogComponent } from '@k2/common/dialogs/components/list/list-dialog.component';
import { MapDialogComponent } from '@k2/common/dialogs/components/map/map-dialog.component';
import { MaterialModule } from '@k2/common/material/material.module';
import { ConfirmationDialogComponent } from '@k2/common/dialogs/components/confirmation-dialog/confirmation-dialog.component';

const components = [
  ListDialogComponent,
  MapDialogComponent,
  ConfirmationDialogComponent
];

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: components,
  exports: components
})
export class DialogsModule {}
