<div>
  <a
    class="btn btn-default point-left"
    (click)="onBack.emit()"
    [routerLink]="fallbackLink"
    ><icon type="arrow-left"></icon>Back
  </a>

  <btn
    class="pull-right"
    htmlType="submit"
    [loading]="isLoading"
    (click)="onSubmit.emit()"
    >{{ submitLabel ? submitLabel : 'Next'
    }}<icon class="point-right" type="arrow-right"></icon>
  </btn>
</div>
