import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'assignment-progress',
  templateUrl: 'assignment-progress.component.html',
  styleUrls: ['assignment-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentProgressComponent {
  @Input() startDate: string;
  @Input() endDate: string;

  get isVisible(): boolean {
    return this.end > this.now;
  }

  get hasStarted(): boolean {
    return this.start < this.now;
  }

  get progress(): number {
    const one = 100 / (this.end - this.start);
    return Math.round(100 - (this.end - this.now) * one);
  }

  private get now(): number {
    return new Date().getTime();
  }

  private get start(): number {
    return new Date(this.startDate).getTime();
  }

  private get end(): number {
    return new Date(this.endDate).getTime();
  }
}
