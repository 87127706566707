import { addressSchema } from '@k2/common/addresses-state/normalizer-schemas';
import { clientAdminSchema } from '@k2/common/client-admins-state/normalizer-schemas';
import { clientSchema } from '@k2/common/clients-state/normalizer-schemas';
import { subsidiarySchema } from '@k2/common/clients-state/subsidiaries/normalizer-schemas';
import { officeSchema } from '@k2/common/offices-state/normalizer-schemas';
import { packageSchema } from '@k2/common/packages-state/normalizer-schemas';
import { k2UserSchema, userSchema } from '@k2/common/users-state/normalizer-schemas';
import { workflowsListSchema } from '@k2/common/workflows-state/normalizer-schemas';
import { schema } from 'normalizr';

export const assignmentSchema = new schema.Entity('assignments', {
  // person: assigneeSchema, // disabled normalization as this should be a value fixed in time
  contact_user: clientAdminSchema,
  relocation_manager: k2UserSchema,
  client: clientSchema,
  initiated_by: userSchema,
  office: officeSchema,
  package: packageSchema,
  billing: {
    address: addressSchema,
    subsidiary: subsidiarySchema
  },
  workflows: workflowsListSchema
});

export const assignmentsListSchema = new schema.Array(assignmentSchema);
