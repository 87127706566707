import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TelephoneInputComponent } from '@k2/common/telephone/components/telephone-input.component';
import { TelephonePipe } from '@k2/common/telephone/pipes/telephone.pipe';
import 'intl-tel-input/build/js/intlTelInput.js';
import 'intl-tel-input/build/js/utils.js';

declare global {
  const intlTelInputUtils: any;
  const intlTelInputGlobals: { getCountryData: Function };
}

const components = [TelephoneInputComponent];

const pipes = [TelephonePipe];

@NgModule({
  imports: [FormsModule],
  declarations: [...components, ...pipes],
  exports: [...components, ...pipes],
  providers: pipes
})
export class TelephoneModule {}
