<div class="text-dialog">
  <h2
    mat-dialog-title
    *ngIf="data.title"
    [ngStyle]="data.centeredTitle && {'text-align': 'center'}"
  >
    {{ data.title }}
  </h2>

  <mat-dialog-content>
    <p
      [class.big-text]="!data.title"
      [ngStyle]="data.centeredText && {'text-align': 'center'}"
    >{{ data.text }}</p>

    <p *ngIf="data.subText">{{ data.subText }}</p>

    <ng-container *ngIf="data.usersToRedirect">
      <profile-ingot *ngFor="let user of data.usersToRedirect" class="mb-1">
        <ng-container image>
          <file-jet-img [image]="user.avatar" [width]="40" [height]="40"></file-jet-img>
        </ng-container>

        <a (click)="onLinkClick(user.id)">{{ user | userName }}</a>
        <span>{{ user.job_title }}</span>
      </profile-ingot>
    </ng-container>
  </mat-dialog-content>
  <div class="d-flex justify-content-center">
    <input id="confirmation-checkbox"
           *ngIf="data.checkboxText"
           [(ngModel)]="confirmCheckbox"
           type="checkbox"
           class="checkbox pull-left styled-checkbox" />
    <label class="pull-left ml-2 mb-0" for="confirmation-checkbox">{{ data.checkboxText }}</label>
  </div>
  <mat-dialog-actions class="buttons" [class.centered]="data.centeredBtns">
    <button mat-button
            class="btn btn-default"
            tabindex="-1"
            (click)="dialogRef.close('cancel')">
      {{ data.cancelBtnText ? data.cancelBtnText : 'Close' }}
    </button>
    <button mat-button
            class="btn btn-default"
            tabindex="-1"
            *ngIf="data.submitBtnText"
            [disabled]="data.checkboxText && !confirmCheckbox"
            (click)="dialogRef.close('submit')">
      {{ data.submitBtnText }}
    </button>
  </mat-dialog-actions>
</div>
