import {
  assignmentSchema,
  assignmentsListSchema
} from '@k2/common/assignments-state/normalizer-schemas';
import { toNormalizedUpdate } from '@k2/common/entities-state/actions';
import { Assignment, RefType } from '@k2/common/entities-state/types';
import { BatchAction } from '@k2/common/state/batch-action';
import { Action } from '@ngrx/store';

export const FETCH_ASSIGNMENT = '[Assignments] Fetch assignment';
export const STORE_ASSIGNMENT = '[Assignments] Store assignment';

export const CHANGE_ASSIGNMENT_STATUS = '[Assignments] Change status';

export const FETCH_UNASSIGNED_ASSIGNMENTS = '[Assignments] Fetch unassigned';
export const STORE_UNASSIGNED_ASSIGNMENTS = '[Assignments] Store unassigned';

export type Actions =
  | StoreAssignment
  | FetchAssignment
  | ChangeAssignmentStatus
  | FetchUnassignedAssignments
  | StoreUnassignedAssignments;

export class FetchAssignment implements Action {
  readonly type = FETCH_ASSIGNMENT;

  constructor(readonly clientId: number, readonly assignmentId: number) {}
}

export class StoreAssignment implements BatchAction {
  readonly type = STORE_ASSIGNMENT;
  readonly id: number;
  readonly actions: Action[];

  constructor(assignment: Assignment) {
    const normalized = toNormalizedUpdate(assignmentSchema, assignment);
    this.id = normalized.result;
    this.actions = normalized.actions;
  }
}

export class ChangeAssignmentStatus implements Action {
  readonly type = CHANGE_ASSIGNMENT_STATUS;

  constructor(readonly assignmentId: number, readonly newStatus: RefType) {}
}

export class FetchUnassignedAssignments implements Action {
  readonly type = FETCH_UNASSIGNED_ASSIGNMENTS;
}

export class StoreUnassignedAssignments implements BatchAction {
  readonly type = STORE_UNASSIGNED_ASSIGNMENTS;
  readonly actions: Action[];
  readonly ids: number[];

  constructor(assignments: Assignment[]) {
    const normalized = toNormalizedUpdate(assignmentsListSchema, assignments);
    this.actions = normalized.actions;
    this.ids = normalized.result;
  }
}
