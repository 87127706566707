import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
  selector: 'field-notes',
  templateUrl: 'field-notes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldNotesComponent {
  @Input() field: Field;

  get notes(): string {
    return this.field.attributes.notes;
  }
}
