import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatestObj, ensureObservable, omitNils, replayLast } from '@k2/common/helpers';
import {
  CurrencyOption,
  Field,
  Fields,
  FieldSpec,
  FieldsSpec,
  FormSpec,
  MultiFieldOption
} from '@k2/common/k2-forms-state/types';
import { requiredValidator, toValidators } from '@k2/common/k2-forms/validators';
import { mapObjIndexed, toPairs, values } from 'ramda';
import { combineLatest, isObservable, Observable, of } from 'rxjs';
import { distinctUntilChanged, first, map, startWith, switchMap } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';

/**
 * Transforms a form specification to fields.
 *
 * A fields could be conditional, the output will always be an Observable.
 * This allows to conditionally update fields based on their state.
 */
export function formSpecToFields(source: FormSpec | Observable<FormSpec>): Observable<Fields> {
  const observable = ensureObservable(source);

  return observable.pipe(map(toFieldsSpec), fieldsSpecToFields);
}

/**
 * Transforms a fields specification to fields.
 *
 * A fields could be conditional, the output will always be an Observable.
 * This allows to conditionally update fields based on their state.
 */
export function fieldsSpecToFields(
  source: FieldsSpec | Observable<FieldsSpec>
): Observable<Fields> {
  const observable = ensureObservable(source);

  return observable.pipe(
    distinctUntilChanged(),
    map(mapObjIndexed(toField)),
    map(mapObjIndexed(toConditionalField)),
    switchMap(combineLatestObj),
    map(omitNils),
    replayLast()
  );
}

/**
 * Transforms a field specification to field.
 *
 * Returned field will contain a specification,
 * form control and visibility information.
 */
export function toField(spec: FieldSpec): Field {
  const { name, attributes, errors } = spec;
  const value = toInitialValue(spec);
  const validators = toValidators(spec.validators);
  const { disabled } = attributes;
  const control = new UntypedFormControl({ value, disabled }, validators);
  return {
    control,
    name,
    attributes,
    defaultValue: value === undefined ? null : JSON.parse(JSON.stringify(value)),
    errors,
    validators,
    attributeChanges: new EventEmitter<any>()
  };
}

function toInitialValue(spec: FieldSpec): any {
  const { value } = spec;
  const displayType = spec.attributes.display_type;
  if (displayType !== 'select' && displayType !== 'select2') return value;

  if (value != null && value !== '') return value;

  const { valueOptions } = spec.attributes;
  if (!Array.isArray(valueOptions) || valueOptions.length !== 1) return value;
  return valueOptions[0].id || value;
}

/**
 * Extracts a fields specification from form specification.
 */
export function toFieldsSpec(form: FormSpec): FieldsSpec {
  return form.fields;
}

/**
 * Extracts all form controls from a given fields and wraps them into a FormGroup.
 *
 * Returned Observable will cache the latest value until there is at least one subscriber.
 */
export function fieldsToForm(source: Fields): UntypedFormGroup;
export function fieldsToForm(source: Observable<Fields>): Observable<UntypedFormGroup>;
export function fieldsToForm(source) {
  const toFormControl = (fields: Fields) => {
    return new UntypedFormGroup(mapObjIndexed((field: Field) => field.control, fields));
  };

  if (isObservable(source)) {
    return source.pipe(map(toFormControl), replayLast());
  }

  return toFormControl(source);
}

/**
 * Transforms a given field to conditional field.
 *
 * A conditional field has the same structure as a normal field.
 * However, it can listen to value changes in its determinator field
 * and act as described in consequences.
 *
 * If also supports attribute conditionalPrefix, which provides additional
 * determinator field for updating currency prefix.
 *
 * A field with no conditional behavior will be returned without modifications.
 */
function toConditionalField(field: Field, _, fields: Fields): Observable<Field> {
  const { conditional } = field.attributes;
  const { conditionalPrefix } = field.attributes;
  let multiSelectFieldOption: MultiFieldOption;
  if (field.attributes.display_type === 'multiSelect') {
    multiSelectFieldOption = field.attributes.valueOptions.find(opt => opt.conditionalPrefix);
  }

  if (conditional == null && conditionalPrefix == null && !multiSelectFieldOption) return of(field);

  // only conditional
  if (conditionalPrefix == null && !multiSelectFieldOption) {
    const updateValidator = toValidatorMutator(field.control);
    const determinator = fields[conditional.determinator_field].control;

    if (conditional.determinator_field == 'lease_end_date') {
      return determinator.valueChanges.pipe(
        startWith(determinator.value),
        map(value => (value != null ? 'true' : null)),
        map(value => {
          const consequence = conditional.consequences[value] || conditional.default_state;
          updateValidator(consequence.required);

          if (consequence.disabled) {
            field.control.disable();
          } else {
            field.control.enable();
          }

          if (consequence.display) return field;
          return null;
        })
      );
    }

    return determinator.valueChanges.pipe(
      startWith(determinator.value),
      map(value => (value != null ? value.toString() : null)),
      map(value => {
        const consequence = conditional.consequences[value] || conditional.default_state;
        updateValidator(consequence.required);

        if (consequence.disabled) {
          field.control.disable();
        } else {
          field.control.enable();
        }

        if (consequence.display) return field;
        return null;
      })
    );
  }

  // conditionalPrefix || multiSelectValueConditionalPrefix
  if (conditional == null) {
    const determinatorName = (
      conditionalPrefix ? conditionalPrefix : multiSelectFieldOption.conditionalPrefix
    ).determinator_field;
    const determinator = fields[determinatorName];
    const determinatorCurrencyOptions: CurrencyOption[] = [...determinator.attributes.valueOptions];

    return determinator.control.valueChanges.pipe(
      startWith(determinator.control.value),
      map(value => (value != null ? value.toString() : null)),
      map(value => {
        const selectedCurrencyOption: CurrencyOption = determinatorCurrencyOptions.find(
          opt => opt.id.toString() === value
        );

        if (selectedCurrencyOption) {
          const newPrefix = `${selectedCurrencyOption.source.iso3} ${selectedCurrencyOption.source.symbol}`;

          if (multiSelectFieldOption) {
            multiSelectFieldOption.prefix = newPrefix;
          } else {
            field.attributes.prefix = newPrefix;
          }
          field.attributeChanges.emit();
        }

        return field;
      })
    );
  }

  // conditional && (conditionalPrefix || multiSelectFieldOption)
  const updateValidator = toValidatorMutator(field.control);
  const determinator = fields[conditional.determinator_field].control;
  const determinatorNamePrefix = (
    conditionalPrefix ? conditionalPrefix : multiSelectFieldOption.conditionalPrefix
  ).determinator_field;
  const determinatorPrefix = fields[determinatorNamePrefix];
  const determinatorCurrencyOptions: CurrencyOption[] = [
    ...determinatorPrefix.attributes.valueOptions
  ];

  const determinatorValueObs = determinator.valueChanges.pipe(
    startWith(determinator.value),
    map(value => (value != null ? value.toString() : null))
  );

  const determinatorPrefixValueObs = determinatorPrefix.control.valueChanges.pipe(
    startWith(determinatorPrefix.control.value),
    map(value => (value != null ? value.toString() : null))
  );

  return combineLatest([determinatorValueObs, determinatorPrefixValueObs]).pipe(
    map(([conditionalValue, conditionalPrefixValue]) => {
      const consequence = conditional.consequences[conditionalValue] || conditional.default_state;
      updateValidator(consequence.required);

      if (consequence.disabled) {
        field.control.disable();
      } else {
        field.control.enable();
      }

      const selectedCurrencyOption: CurrencyOption = determinatorCurrencyOptions.find(
        opt => opt.id.toString() === conditionalPrefixValue
      );

      if (selectedCurrencyOption) {
        const newPrefix = `${selectedCurrencyOption.source.iso3} ${selectedCurrencyOption.source.symbol}`;

        if (multiSelectFieldOption) {
          multiSelectFieldOption.prefix = newPrefix;
        } else {
          field.attributes.prefix = newPrefix;
        }
        field.attributeChanges.emit();
      }

      if (consequence.display) return field;
      return null;
    })
  );
}

/**
 * Returns a function, which is able to extends a
 * control's validators with a "required" validator.
 *
 * Calling a returned function will update a validity of a given control.
 */
function toValidatorMutator(control: UntypedFormControl) {
  const defaultValidator = control.validator || (() => null);

  return (required?: boolean) => {
    if (required) {
      control.setValidators([defaultValidator, requiredValidator]);
    } else {
      control.setValidators(defaultValidator);
    }
    control.updateValueAndValidity({ emitEvent: false });
  };
}

/**
 * Returns TRUE when a given `field` has an renderable display type.
 */
export function isRenderable(field: Field): boolean {
  return field.attributes.display_type !== 'hidden';
}

/**
 * Returns TRUE when a given `field` has flag 'custom' set  to true.
 */
export function isCustom(field: Field): boolean {
  return field.attributes.custom === true;
}

/**
 * Discards changes in a given `fields`.
 */
export function discardChanges(fields: Observable<Fields>) {
  fields.pipe(first(), map(values)).subscribe(fields => {
    fields.forEach(({ control, defaultValue }) => control.reset(defaultValue));
  });
}

/**
 * Updates the given values in the given Field spec.
 */
export function updateFieldsSpecValues(
  spec: FieldsSpec,
  newValues: Record<string, any>
): FieldsSpec {
  return toPairs(newValues).reduce(
    (acc, [name, value]) => ({ ...acc, [name]: { ...spec[name], value } }),
    spec
  );
}
