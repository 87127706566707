import { isAssignmentActive } from '@k2/common/assignments-state/utils';
import { Assignee } from '@k2/common/entities-state/types';

export function hasFamily(assignee: Assignee): boolean {
  const { party } = assignee;
  return party.partner != null || party.children.length > 0;
}

export function isOnAssignment(assignee: Assignee): boolean {
  return assignee.assignments.some(isAssignmentActive);
}
