import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Backend } from '@k2/common/backend/backend';
import { Partner } from '@k2/common/entities-state/types';
import { ValueOption } from '@k2/common/k2-forms-state/types';
import { Place } from '@k2/common/ui/components/place-selector/place-selector.component';

const noServiceValue = 'ALL';

@Component({
  templateUrl: 'partner-finder-dialog.component.html',
  styleUrls: ['partner-finder-dialog.component.scss']
})
export class PartnerFinderDialogComponent {
  readonly serviceOptions?: ValueOption[];
  private readonly searchParams: { [name: string]: string };
  place: Place = { country: null, state: null };
  name = '';
  results: Partner[];
  service?: string;

  constructor(
    private backend: Backend,
    private dialogRef: MatDialogRef<PartnerFinderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) { searchParams, services }: PartnerFinderDialogData
  ) {
    this.searchParams = searchParams;
    this.serviceOptions = services;

    if (services != null && services.some(({ id }) => id === noServiceValue)) {
      this.service = noServiceValue;
    }
  }

  search = () => {
    this.backend.partners
      .search({
        name: this.name,
        regionCode: this.place.country,
        stateCode: this.place.state,
        service: this.service === noServiceValue ? null : this.service,
        ...this.searchParams
      })
      .subscribe(partners => {
        this.results = partners;
      });
  };

  select = (partner: Partner) => {
    this.dialogRef.close(partner);
  };
}

export interface PartnerFinderDialogData {
  readonly searchParams: { [name: string]: string };
  readonly services?: ValueOption[];
}
