import { NgModule } from '@angular/core';
import { LogoutCountdownDialogComponent } from '@k2/common/sessions/components/logout-countdown-dialog.component';
import { httpInterceptorProvider } from '@k2/common/sessions/services/http-interceptor';
import { SharedModule } from '@k2/common/shared.module';

const components = [LogoutCountdownDialogComponent];

@NgModule({
  imports: [SharedModule],
  declarations: components,
  exports: components,
  providers: [httpInterceptorProvider]
})
export class SessionsModule {}
