import { ApiClient } from '@k2/common/backend/api-client';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

export class FeedbackEndpoint {
  constructor(private currentUserId: Observable<number>, private api: ApiClient) {}

  fetchFeedbackForms = (id: string) => {
    return this.currentUserId.pipe(
      map(toFeedbackPath(id)),
      flatMap(path => this.api.get(path)),
      map(payload => payload.forms)
    );
  };

  createFeedback = (
    id: string,
    data: any
  ): Observable<{ clientId: number; assignmentId: number }> => {
    return this.currentUserId.pipe(
      map(toFeedbackPath(id)),
      flatMap(path => this.api.post(path, data)),
      map(payload => ({
        clientId: payload.result.client_id,
        assignmentId: payload.result.assignment_id
      }))
    );
  };

  fetchInviteForm = (identifier: string) => {
    return this.api.get(`/feedback/invite/${identifier}`);
  };

  fetchSurveyInviteForm = (identifier: string) => {
    return this.api.get(`/feedback/invite/survey/${identifier}`);
  };

  invite = (identifier: string, data: any) => {
    return this.api.post(`/feedback/invite/${identifier}`, data);
  };

  inviteSurvey = (identifier: string, data: any) => {
    return this.api.post(`/feedback/invite/survey/${identifier}`, data);
  };

  deleteQuestionnaireInvite = (identifier: string) => {
    return this.api.delete(`/feedback/questionnaire/${identifier}`);
  };

  deleteSurveyQuestionnaireInvite = (identifier: string) => {
    return this.api.delete(`/feedback/questionnaire/survey/${identifier}/queue`);
  };

  editSurveyQuestionnaireInvite = (assignmentId: number, date: string) => {
    return this.api
      .patch(`/feedback/assignment/${assignmentId}/questionnaire/survey/queue/edit`, { send_date: date });
  };

  requestFeedbackResend = (assignmentId: number, questionnaireIdentifier: string, data: any) => {
    return this.api.put(
      `/feedback/assignment/${assignmentId}/resend/${questionnaireIdentifier}`,
      data
    );
  };

  cancelFeedbackResend = (assignmentId: number, emailAutoFeedbackId: number) => {
    return this.api.delete(
      `/feedback/assignment/${assignmentId}/cancel_resend/${emailAutoFeedbackId}`
    );
  };
}

function toFeedbackPath(feedbackId: string) {
  const assignmentId = toAssignmentId(feedbackId);

  return (userId: number) => {
    return `/feedback/assignment/${assignmentId}/respondent/${userId}/identifier/${feedbackId}`;
  };
}

function toAssignmentId(feedbackId: string): number {
  const assignmentId = feedbackId.substring(feedbackId.lastIndexOf(':') + 1, feedbackId.length);

  return Number(assignmentId);
}
