import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { extractIdFromRoute, Subscriptions } from '@k2/common/helpers';
import { ClientComponent } from '@k2/staff/content/clients/client/client.component';
import { map } from 'rxjs/operators';

@Component({
  template: '<router-outlet></router-outlet>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientAssignmentComponent implements OnDestroy {
  private _identity: AssignmentIdentity;
  private readonly subscriptions = new Subscriptions();

  constructor(clientRef: ClientComponent, route: ActivatedRoute) {
    const identity = extractIdFromRoute(route).pipe(
      map(assignmentId => ({ clientId: clientRef.id, assignmentId }))
    );

    this.subscriptions.add(identity.subscribe(i => (this._identity = i)));
  }

  get identity(): AssignmentIdentity {
    return this._identity;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
