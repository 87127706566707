<annotated-control [field]="field">
  <input
    type="password"
    [id]="field.attributes.id"
    [matAutocomplete]="suggestion.autocomplete"
    [formControl]="field.control"
    [maxlength]="255"
    [placeholder]="field.attributes.placeholder || field.attributes.label || ''"
    class="form-control"
  />
</annotated-control>

<text-suggestion
  #suggestion
  [resource]="field.attributes.textSuggestion"
  [value]="field.control.value"
></text-suggestion>
