import { NgModule } from '@angular/core';
import { AssigneeLinkPipe } from '@k2/common/links/assignee-link';
import { AssignmentLinkPipe } from '@k2/common/links/assignment-link';
import { ClientLinkPipe } from '@k2/common/links/client-link';
import { K2UserLinkPipe } from '@k2/common/links/k2-user-link';
import { LinkPipe } from '@k2/common/links/link';
import { LinkGenerator } from '@k2/common/links/link-generator';
import { OfficeLinkPipe } from '@k2/common/links/office-link';
import { PartnerLinkPipe } from '@k2/common/links/partner-link';
import { UserLinkPipe } from '@k2/common/links/user-link';
import { LinkedAssignmentLinkPipe } from '@k2/common/links/linked-assignment-link';
export { LinkGenerators, LINK_GENERATORS } from '@k2/common/links/link-generator';

const pipes = [
  LinkPipe,
  AssigneeLinkPipe,
  LinkedAssignmentLinkPipe,
  AssignmentLinkPipe,
  ClientLinkPipe,
  K2UserLinkPipe,
  UserLinkPipe,
  OfficeLinkPipe,
  PartnerLinkPipe
];

@NgModule({
  exports: pipes,
  declarations: pipes,
  providers: [LinkGenerator, ...pipes]
})
export class LinksModule {}
