import { clientsListSchema } from '@k2/common/clients-state/normalizer-schemas';
import { toNormalizedUpdate } from '@k2/common/entities-state/actions';
import { Client, CurrentK2UserAssignments, K2User } from '@k2/common/entities-state/types';
import { RemoteMutatedFile } from '@k2/common/file-jet/file';
import { StoreFormSpec, UpdateFormValues } from '@k2/common/k2-forms-state/actions';
import { FormSpec } from '@k2/common/k2-forms-state/types';
import { k2UserCurrentAssignmentsSchema } from '@k2/common/k2-users-state/normalizer-schemas';
import { K2UserCurrentAssignmentsState } from '@k2/common/k2-users-state/state';
import { BatchAction } from '@k2/common/state/batch-action';
import { UpdateUser, UpdateUserSecurity } from '@k2/common/users-state/actions';
import { k2UserSchema, k2UsersListSchema } from '@k2/common/users-state/normalizer-schemas';
import { Action } from '@ngrx/store';

export const FETCH_NEW_K2_USER_FORM = '[K2 Users] Fetch new user form';
export const STORE_NEW_K2_USER_FORM = '[K2 Users] Store new user form';
export const CREATE_K2_USER = '[K2 Users] Create user';

export const FETCH_ALL_K2_USERS = '[K2 Users] Fetch all users';
export const STORE_ALL_K2_USERS = '[K2 Users] Store all users';

export const STORE_AVAILABLE_DATA = '[K2 Users] Store available data';
export const ADD_AVAILABLE_DATA = '[K2 Users] Add available data';

export const FETCH_K2_USER = '[K2 Users] Fetch user';
export const STORE_K2_USER = '[K2 Users] Store user';
export const STORE_K2_USER_FORM = '[K2 Users] Store user form';
export const UPDATE_K2_USER = '[K2 Users] Update user';

export const FETCH_K2_USER_TEAM = '[K2 Users] Fetch team';
export const STORE_K2_USER_TEAM = '[K2 Users] Store team';

export const FETCH_K2_USER_CLIENTS = '[K2 Users] Fetch clients';
export const STORE_K2_USER_CLIENTS = '[K2 Users] Store clients';

export const FETCH_K2_USER_CURRENT_ASSIGNMENTS = '[K2 Users] Fetch current assignments';
export const STORE_K2_USER_CURRENT_ASSIGNMENTS = '[K2 Users] Store current assignments';

export const FETCH_K2_USER_WELCOME_FORM = '[K2 Users] Fetch welcome form';
export const STORE_K2_USER_WELCOME_FORM = '[K2 Users] Store welcome form';
export const SEND_K2_USER_WELCOME_EMAIL = '[K2 Users] Send welcome email';

export const FETCH_K2_USER_PASSWORD_RESET_FORM = '[K2 Users] Fetch password reset form';
export const STORE_K2_USER_PASSWORD_RESET_FORM = '[K2 Users] Store password reset form';
export const UPDATE_K2_USER_PASSWORD = '[K2 Users] Update password';
export const RESET_K2_USER_PASSWORD = '[K2 Users] Reset password';

export const UPDATE_K2_USER_AVATAR = '[K2 Users] Update avatar';
export const UPDATE_K2_USER_SECURITY = '[K2 UserSecurity] Update userSecurity';

export type Actions =
  | FetchAllK2Users
  | StoreAllK2Users
  | FetchK2User
  | StoreK2User
  | StoreK2UserForm
  | FetchK2UserTeam
  | StoreK2UserTeam
  | FetchK2UserClients
  | StoreK2UserClients
  | FetchK2UserCurrentAssignments
  | StoreK2UserCurrentAssignments
  | UpdateK2UserPassword
  | FetchK2UserPasswordResetForm
  | StoreK2UserPasswordResetForm
  | ResetK2UserPassword
  | FetchK2UserWelcomeForm
  | StoreK2UserWelcomeForm
  | UpdateK2UserSecurity
  | SendK2UserWelcomeEmail
  | StoreAvailableData
  | AddAvailableData;

export class FetchNewK2UserForm implements Action {
  type = FETCH_NEW_K2_USER_FORM;
}

export class StoreNewK2UserForm implements BatchAction {
  readonly type = STORE_NEW_K2_USER_FORM;
  readonly actions: Action[];

  constructor(form: FormSpec) {
    this.actions = [new StoreFormSpec(['k2User'], form)];
  }
}

export class CreateK2User implements Action {
  readonly type = CREATE_K2_USER;

  constructor(readonly values: any) {}
}

export class FetchAllK2Users implements Action {
  readonly type = FETCH_ALL_K2_USERS;
}

export class StoreAllK2Users implements BatchAction {
  readonly type = STORE_ALL_K2_USERS;
  readonly ids: number[];
  readonly actions: Action[];

  constructor(users: K2User[]) {
    const normalized = toNormalizedUpdate(k2UsersListSchema, users);
    this.ids = normalized.result;
    this.actions = normalized.actions;
  }
}

export class FetchK2User implements Action {
  readonly type = FETCH_K2_USER;

  constructor(readonly id: number) {}
}

export class StoreK2User implements BatchAction {
  readonly type = STORE_K2_USER;
  readonly id: number;
  readonly actions: Action[];

  constructor(user: K2User) {
    const normalized = toNormalizedUpdate(k2UserSchema, user);
    this.id = normalized.result;
    this.actions = normalized.actions;
  }
}

export class StoreK2UserForm implements BatchAction {
  readonly type = STORE_K2_USER_FORM;
  readonly actions: Action[];

  constructor(userId: number, form: FormSpec) {
    this.actions = [new StoreFormSpec(['k2User', userId], form)];
  }
}

export class UpdateK2User implements BatchAction {
  readonly type = UPDATE_K2_USER;
  readonly actions: Action[];

  constructor(readonly userId: number, readonly values: any) {
    this.actions = [
      new UpdateFormValues(['k2User', userId], values),
      new UpdateUser(userId, values)
    ];
  }
}

export class FetchK2UserTeam implements Action {
  readonly type = FETCH_K2_USER_TEAM;

  constructor(readonly userId: number) {}
}

export class StoreK2UserTeam implements BatchAction {
  readonly type = STORE_K2_USER_TEAM;
  readonly ids: number[];
  readonly actions: Action[];

  constructor(readonly userId: number, team: K2User[]) {
    const normalized = toNormalizedUpdate(k2UsersListSchema, team);
    this.ids = normalized.result;
    this.actions = normalized.actions;
  }
}

export class FetchK2UserClients implements Action {
  readonly type = FETCH_K2_USER_CLIENTS;

  constructor(readonly userId: number) {}
}

export class StoreK2UserClients implements BatchAction {
  readonly type = STORE_K2_USER_CLIENTS;
  readonly ids: number[];
  readonly actions: Action[];

  constructor(readonly userId: number, team: Client[]) {
    const normalized = toNormalizedUpdate(clientsListSchema, team);
    this.ids = normalized.result;
    this.actions = normalized.actions;
  }
}

export class FetchK2UserCurrentAssignments implements Action {
  readonly type = FETCH_K2_USER_CURRENT_ASSIGNMENTS;

  constructor(readonly userId: number) {}
}

export class StoreK2UserCurrentAssignments implements BatchAction {
  readonly type = STORE_K2_USER_CURRENT_ASSIGNMENTS;
  readonly current: K2UserCurrentAssignmentsState;
  readonly actions: Action[];

  constructor(readonly userId: number, current: CurrentK2UserAssignments) {
    const normalized = toNormalizedUpdate(k2UserCurrentAssignmentsSchema, current);
    this.current = normalized.result;
    this.actions = normalized.actions;
  }
}

export class UpdateK2UserPassword implements Action {
  readonly type = UPDATE_K2_USER_PASSWORD;

  constructor(
    readonly userId: number,
    readonly oldPassword: string,
    readonly newPassword: string
  ) {}
}

export class FetchK2UserPasswordResetForm implements Action {
  readonly type = FETCH_K2_USER_PASSWORD_RESET_FORM;

  constructor(readonly recipientEmail: string) {}
}

export class StoreK2UserPasswordResetForm implements BatchAction {
  readonly type = STORE_K2_USER_PASSWORD_RESET_FORM;
  readonly actions: Action[];

  constructor(recipientEmail: string, form: FormSpec) {
    this.actions = [new StoreFormSpec(['k2UserPasswordReset', recipientEmail], form)];
  }
}

export class ResetK2UserPassword implements Action {
  readonly type = RESET_K2_USER_PASSWORD;

  constructor(readonly values: any) {}
}

export class FetchK2UserWelcomeForm implements Action {
  readonly type = FETCH_K2_USER_WELCOME_FORM;

  constructor(readonly recipientEmail: string) {}
}

export class StoreK2UserWelcomeForm implements BatchAction {
  readonly type = STORE_K2_USER_WELCOME_FORM;
  readonly actions: Action[];

  constructor(recipientEmail: string, form: FormSpec) {
    this.actions = [new StoreFormSpec(['k2UserWelcome', recipientEmail], form)];
  }
}

export class SendK2UserWelcomeEmail implements Action {
  readonly type = SEND_K2_USER_WELCOME_EMAIL;

  constructor(readonly values: any) {}
}

export class UpdateK2UserAvatar implements BatchAction {
  readonly type = UPDATE_K2_USER_AVATAR;
  readonly actions: Action[];

  constructor(readonly userId: number, readonly avatar: RemoteMutatedFile) {
    this.actions = [new UpdateUser(userId, { avatar })];
  }
}

export class UpdateK2UserSecurity implements BatchAction {
  readonly type = UPDATE_K2_USER_SECURITY;
  readonly actions: Action[];

  constructor(readonly userId: number, readonly values: any) {
    this.actions = [new UpdateUserSecurity(userId, values)];
  }
}

export class StoreAvailableData implements BatchAction {
  readonly type = STORE_AVAILABLE_DATA;
  readonly actions: Action[];

  constructor(readonly clients: number[], readonly assignments: number[]) {}
}

export class AddAvailableData implements BatchAction {
  readonly type = ADD_AVAILABLE_DATA;
  readonly actions: Action[];

  constructor(readonly clients: number[], readonly assignments: number[]) {}
}
