import { ApiClient } from '@k2/common/backend/api-client';
import { DocumentFile } from '@k2/common/entities-state/types';

export class DocumentsEndpoint {
  constructor(private readonly api: ApiClient) {}

  deleteFile = (file: DocumentFile) => {
    return this.api.delete(`/documents/files/${file.id}/${file.identifier_hash}`);
  };
}
