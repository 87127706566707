<annotated-control [field]="field" class="quoted-product">
  <div *ngFor="let value of values; let i = index; trackBy: extractId" class="line flex-wrap">
    <div *ngIf="value.required" class="product">{{ value.product_name }}</div>

    <basic-select *ngIf="!value.required"
                  [value]="value.id"
                  (valueChange)="updateProduct(i, $event)"
                  placeholder="Product..."
                  [disabled]="disabled"
                  [options]="getProductOptions(value.id)"
                  (blur)="touch()"
                  class="product"></basic-select>

    <input [ngModel]="value.description"
           (ngModelChange)="updateDescription(i, $event)"
           maxlength="255"
           placeholder="Description"
           class="form-control description" />

    <div class="d-flex">
      <k2-select [value]="value.currencies_id"
                 (valueChange)="updateCurrency(i, $event)"
                 placeholder="Currency..."
                 [disabled]="disabled"
                 [options]="currencyOptions"
                 (blur)="touch()"
                 class="currency"></k2-select>

      <input min="0"
             type="number"
             [ngModel]="value.amount"
             (ngModelChange)="updateAmount(i, $event)"
             placeholder="Amount"
             class="amount form-control" />

      <div class="buttons">
        <a *ngIf="i === values.length - 1 && canAddEmptyValue()" (click)="addEmptyValue()">
          <icon type="plus"></icon>
        </a>

        <a *ngIf="isRemovableValue(value)" (click)="removeValue(i)" class="remove">
          <icon type="minus"></icon>
        </a>
      </div>
    </div>
  </div>
</annotated-control>
