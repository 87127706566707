<ng-container [ngSwitch]="field.attributes.display_type">
  <address-control *ngSwitchCase="'address'" [field]="field"></address-control>

  <multi-field-control *ngSwitchCase="'multiField'" [field]="field"></multi-field-control>

  <multi-select-control *ngSwitchCase="'multiSelect'" [field]="field"></multi-select-control>

  <text-control *ngSwitchCase="'text'" [field]="field"></text-control>

  <password-control *ngSwitchCase="'password'" [field]="field"></password-control>

  <number-control *ngSwitchCase="'number'" [field]="field"></number-control>

  <radio-control *ngSwitchCase="'radio'" [field]="field"></radio-control>

  <radiobar-control *ngSwitchCase="'radiobar'" [field]="field"></radiobar-control>

  <rating-control *ngSwitchCase="'rating'" [field]="field"></rating-control>

  <date-control *ngSwitchCase="'date'" [field]="field"></date-control>

  <multi-date-control *ngSwitchCase="'multidate'" [field]="field"></multi-date-control>

  <datetime-control *ngSwitchCase="'datetime'" [field]="field"></datetime-control>

  <datetime-control *ngSwitchCase="'full-datetime'" [field]="field" [full]="true"></datetime-control>

  <email-control *ngSwitchCase="'email'" [field]="field"></email-control>

  <select-control *ngSwitchCase="'select'" [field]="field"></select-control>

  <k2-select-control *ngSwitchCase="'select2'" [field]="field"></k2-select-control>

  <k2-select-control *ngSwitchCase="'dropdownMultiple'" [field]="field" [multiple]="true"></k2-select-control>

  <icon-select-control *ngSwitchCase="'iconselect'" [field]="field"></icon-select-control>

  <checkbox-control *ngSwitchCase="'checkbox'" [field]="field"></checkbox-control>

  <checkboxes-control *ngSwitchCase="'checkboxes'" [field]="field"></checkboxes-control>

  <recipients-control *ngSwitchCase="'recipients'" [field]="field"></recipients-control>

  <k2-users-selector-control *ngSwitchCase="'k2UsersSelector'" [field]="field"></k2-users-selector-control>

  <k2-users-selector-control *ngSwitchCase="'k2TeamUsersSelector'" [field]="field" [onlyTeam]="true"></k2-users-selector-control>

  <k2-links-selector-control *ngSwitchCase="'k2LinksSelector'" [field]="field"></k2-links-selector-control>

  <partners-selector-control *ngSwitchCase="'partnersSelector'" [field]="field"></partners-selector-control>

  <partner-finder-control *ngSwitchCase="'partnerFinder'" [field]="field"></partner-finder-control>

  <partner-transit-service-finder *ngSwitchCase="'partnerTransitServiceFinder'"
                                  [field]="field"></partner-transit-service-finder>

  <locations-selector-control *ngSwitchCase="'locationsSelector'" [field]="field"></locations-selector-control>

  <budget-control *ngSwitchCase="'budget'" [field]="field"></budget-control>

  <telephone-control *ngSwitchCase="'tel'" [field]="field"></telephone-control>

  <textarea-control *ngSwitchCase="'textarea'" [field]="field"></textarea-control>

  <markdown-control *ngSwitchCase="'markdown'" [field]="field"></markdown-control>

  <variations-control *ngSwitchCase="'variations'" [field]="field"></variations-control>

  <regions-control *ngSwitchCase="'regions'"
                   [field]="field"
                   [changeServiceToUniversal]="changeServiceToUniversal"></regions-control>

  <yes-no-control *ngSwitchCase="'yesno'" [field]="field"></yes-no-control>

  <grouped-checkboxes-control *ngSwitchCase="'groupedCheckboxes'"
                              [field]="field"></grouped-checkboxes-control>

  <workflow-service-variations-control *ngSwitchCase="'workflowServiceVariations'"
                                       [field]="field"></workflow-service-variations-control>

  <documents-uploader-control *ngSwitchCase="'documentsUploader'"
                              [field]="field"></documents-uploader-control>

  <quoted-product-control *ngSwitchCase="'quotedProduct'" [field]="field"></quoted-product-control>

  <contact-cards-control *ngSwitchCase="'contactCards'" [field]="field"></contact-cards-control>

  <airport-selector-control *ngSwitchCase="'airportSelector'" [field]="field"></airport-selector-control>

  <text-control *ngSwitchCase="'fileName'" [field]="field" [deleteExtension]="true"></text-control>

  <span *ngSwitchCase="'label'" class="label-control-value">
    {{ field.control.value }}
  </span>

  <ng-container *ngSwitchCase="'hidden'"></ng-container>

  <empty class="warning" *ngSwitchDefault>
    <span *ngIf="field.attributes.display_type | isEmpty">
      Failed to render the field with no display type!
    </span>
    <span *ngIf="field.attributes.display_type | isNotEmpty">
      "{{ field.attributes.display_type }}" is an unknown display type!
    </span>
  </empty>
</ng-container>
