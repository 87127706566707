import { ApiClient } from '@k2/common/backend/api-client';
import { map, take } from 'rxjs/operators';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { Observable } from 'rxjs';

export class AssigneeEndpoint {
  constructor(private api: ApiClient) {}

  fetchAssigneeDashboard = () => {
    return this.api.get('/assignee/dashboard').pipe(map(payload => payload.dashboard));
  };

  fetchEditAssigneePasswordForm = () => {
    return this.api.get('/assignee/password/form').pipe(map(payload => payload.form));
  };

  updateAssigneePassword = (data: any) => {
    return this.api.patch('/assignee/password', data);
  };

  dismissAssignmentLead = (assignmentId: number) => {
    return this.api
      .patch(`/assignee/assignment/${assignmentId}/lead/dismiss`, null)
      .pipe(map(payload => payload.dashboard));
  };

  fetchQuestionnaireSections = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(
      `/assignee/${clientId}/assignment/${assignmentId}/analysis/questionnaire/sections`
    );
  };

  fetchQuestionnaireSection = ({ clientId, assignmentId }: AssignmentIdentity, section: string) => {
    return this.api
      .get(`/assignee/${clientId}/assignment/${assignmentId}/analysis/questionnaire/${section}`)
      .pipe(map(payload => payload));
  };

  updateQuestionnaireSection = (
    { clientId, assignmentId }: AssignmentIdentity,
    section: string,
    data: any
  ) => {
    return this.api.patch(
      `/assignee/${clientId}/assignment/${assignmentId}/analysis/questionnaire/${section}`,
      data
    );
  };

  updateQuestionnaireCompleted = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.patch(
      `/assignee/${clientId}/assignment/${assignmentId}/analysis/questionnaire/complete`,
      null
    );
  };

  fetchFeedbackForms = (identifier: string) => {
    const assignmentId = toAssignmentId(identifier);

    return this.api
      .get(`/assignee/feedback/assignment/${assignmentId}/identifier/${identifier}`)
      .pipe(map(payload => payload.forms));
  };

  fetchSurveyFeedbackForms = (identifier: string) => {
    const assignmentId = toAssignmentId(identifier);

    return this.api
      .get(`/assignee/feedback/survey/assignment/${assignmentId}/identifier/${identifier}`)
      .pipe(take(1));
  };

  createFeedback = (
    identifier: string,
    data: any
  ): Observable<{ clientId: number; assignmentId: number }> => {
    const assignmentId = toAssignmentId(identifier);

    return this.api.post(
      `/assignee/feedback/assignment/${assignmentId}/identifier/${identifier}`,
      data
    );
  };

  createSurveyFeedback = (
    identifier: string,
    data: any
  ): Observable<{ clientId: number; assignmentId: number }> => {
    const assignmentId = toAssignmentId(identifier);

    return this.api.post(
      `/assignee/feedback/survey/assignment/${assignmentId}/identifier/${identifier}`,
      data
    );
  };
}

function toAssignmentId(feedbackId: string): number {
  const assignmentId = feedbackId.substring(feedbackId.lastIndexOf(':') + 1, feedbackId.length);

  return Number(assignmentId);
}
