import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { H1Component } from '@k2/common/ui/components/heading/h1.component';
import { H2Component } from '@k2/common/ui/components/heading/h2.component';
import { H3Component } from '@k2/common/ui/components/heading/h3.component';
import { H4Component } from '@k2/common/ui/components/heading/h4.component';
import { H5Component } from '@k2/common/ui/components/heading/h5.component';
import { H6Component } from '@k2/common/ui/components/heading/h6.component';
import { HeadingComponent } from '@k2/common/ui/components/heading/heading.component';

const components = [
  H1Component,
  H2Component,
  H3Component,
  H4Component,
  H5Component,
  H6Component,
  HeadingComponent
];

@NgModule({
  imports: [CommonModule],
  declarations: components,
  exports: components
})
export class HeadingModule {}
