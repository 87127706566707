<div>
  <span class="description">Follow:</span>
  <input
    id="follow-assignment-btn"
    type="checkbox"
    class="tgl tgl-ios"
    [checked]="following | async"
    (click)="toggle()"
  />
  <label for="follow-assignment-btn" class="tgl-btn"></label>
</div>
