import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'true-false-icon',
  templateUrl: 'true-false-icon.component.html',
  styleUrls: ['true-false-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrueFalseIconComponent {
  @Input('condition') isTrue: boolean;
}
