import { Assignment, AssignmentRunningSummary } from '@k2/common/entities-state/types';

export function isAssignmentActive(assignment: Assignment | AssignmentRunningSummary): boolean {
  if (isAssignment(assignment)) {
    const status = assignment.status.type_value;
    return status !== 'PAUSED' && status !== 'ON_ASSIGNMENT_PASSIVE';
  }

  if (assignment.is_running === false) return false;
  const start = Date.parse(assignment.start_date);
  const end = Date.parse(assignment.end_date);
  const now = Date.now();

  return start <= now && (!assignment.end_date || end > now);
}

function isAssignment(value): value is Assignment {
  return value.status !== undefined;
}
