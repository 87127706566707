import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'flex',
  template: '<ng-content></ng-content>',
  styles: [
    `
      :host {
        display: flex;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexComponent {
  /**
   * Defines how the browser distributes space between and around
   * content items along the main-axis.
   */
  @Input() main: JustifyContent = 'flex-start';

  /**
   * Defines the alignment of items on the cross-axis.
   */
  @Input() cross: AlignItems = 'stretch';

  /**
   * Defines how items are placed in the component defining
   * the main-axis and the direction (normal or reversed).
   */
  @Input() direction: FlexDirection = 'row';

  /**
   * Defines whether items are forced onto one line or can wrap onto multiple lines.
   */
  @Input() wrap: FlexWrap = 'nowrap';

  constructor(private sanitizer: DomSanitizer) {}

  @HostBinding('style')
  get style(): SafeStyle {
    const value = `flex-flow:${this.direction} ${this.wrap};justify-content:${this.main};align-items:${this.cross}`;
    return this.sanitizer.bypassSecurityTrustStyle(value);
  }
}

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'initial'
  | 'inherit';

type AlignItems =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'baseline'
  | 'initial'
  | 'inherit';

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit';
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit';
