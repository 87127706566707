<fieldset [class.expanded]="expanded">
  <div *ngIf="!expanded" class="content">
    <field-label [field]="field" [notRequired]="true"></field-label>

    <div #customValueView [hidden]="customValueView.children.length === 0">
      <ng-content select="[value]"></ng-content>
    </div>

    <ng-container *ngIf="customValueView.children.length === 0">
      <field-value class="line" [field]="field" emptyMessage="This field has not been set yet"></field-value>
    </ng-container>

    <div *ngIf="!disabled" class="edit"><a (click)="expand()">edit</a></div>
  </div>

  <div *ngIf="expanded" class="content" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <field-label [field]="field"></field-label>

    <form [formGroup]="form" (submit)="submit()">
      <field-notes [field]="field"></field-notes>

      <div class="control-wrap">
        <div class="control">
          <field-control [field]="field"
                         (changedField)="checkUnsavedField($event)"
                         [class]="controlSize"></field-control>
          <field-tooltip [tooltip]="field.attributes.tooltip"></field-tooltip>
        </div>

        <field-errors [controls]="form.controls[field.name]"></field-errors>
      </div>

      <submit-control [loading]="loading" (cancel)="cancel()" (onSubmit)="saveClicked()">Save changes</submit-control>
    </form>
  </div>
</fieldset>
