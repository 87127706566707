<section>
  <h2 class="pull-left mt0">Services</h2>
  <a *ngIf="statusLink" [routerLink]="statusLink" class="btn-link">View status &raquo;</a>

  <ng-container *ngIf="workflows">
    <div *ngFor="let workflow of workflows">
      <div *ngIf="workflow.services.length > 0" class="workflow">
        <h2 class="block-header">{{ workflow.name }}</h2>

        <div class="block-content">
          <ng-container *ngFor="let service of workflow.services">
            <div *ngIf="isVisible(service.id)" class="service">
              <a *ngIf="toServiceLink" [routerLink]="toServiceLink({ serviceId: service.id })">
                <service-progress-status *ngFor="let progress of service.progress"
                                         [progress]="progress"></service-progress-status>
                {{ service.name }}
              </a>

              <span *ngIf="!toServiceLink">
              <service-progress-status *ngFor="let progress of service.progress"
                                       [progress]="progress"></service-progress-status>
                {{ service.name }}
            </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="hhgWorkflow" class="workflow">
    <h2 class="block-header">{{ hhgWorkflow.name }}</h2>
    <div class="block-content">
      <div *ngFor="let service of hhgWorkflow.services" class="service">
        <a *ngIf="toServiceLink" [routerLink]="toServiceLink({ serviceId: service.id })">
          <service-progress-status *ngFor="let progress of service.progress"
                                   [progress]="progress"></service-progress-status>
          {{ service.name }}
        </a>

        <span *ngIf="!toServiceLink">
          <service-progress-status *ngFor="let progress of service.progress"
                                   [progress]="progress"></service-progress-status>
          {{ service.name }}
        </span>
      </div>
    </div>
  </div>

  <no-data *ngIf="!hasServices">
    <p>There are no services associated with this assignment.</p>
  </no-data>
</section>
