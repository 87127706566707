import { Pipe, PipeTransform } from '@angular/core';
import { LinkedAssignment } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

@Pipe({ name: 'linkedAssignmentLink' })
export class LinkedAssignmentLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(linkedAssignment: LinkedAssignment): any {
    if (linkedAssignment == null) return;

    return this.generator.generate({
      type: 'linkedAssignment',
      data: {
        clientId: linkedAssignment.client.id,
        assignmentId: linkedAssignment.assignmentId
      }
    });
  }
}
