import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'optgroup[placeholder]',
  templateUrl: 'select-placeholder.component.html',
  styleUrls: ['select-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPlaceholderComponent {
  @Input() placeholder: string;

  get normalized() {
    return this.placeholder || 'Please select...';
  }
}
