import { servicesListSchema } from '@k2/common/services-state/normalizer-schemas';
import { schema } from 'normalizr';

export const workflowSchema = new schema.Entity(
  'workflows',
  {
    services: servicesListSchema
  },
  { idAttribute: 'workflow_id' }
);

export const workflowsListSchema = new schema.Array(workflowSchema);
