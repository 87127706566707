import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leftPad'
})
export class LeftPadPipe implements PipeTransform {
  transform(value: number | string, size: number, padChar = '0'): string {
    if (value == null) return;

    const string = value.toString();

    if (string.length >= size) return string;

    return new Array(1 + size - string.length).join(padChar) + string;
  }
}
