import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StorageUsageChartData } from '@k2/common/entities-state/types';
import { lineColors } from '@k2/common/k2-charts/colors';
import { ChartConfigV4, toIntegerAxis } from '@k2/common/k2-charts/utils';
import { mergeDeepLeft, toPairs } from 'ramda';

@Component({
  selector: 'storage-usage-chart',
  templateUrl: 'storage-usage-chart.component.html',
  styleUrls: ['storage-usage-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorageUsageChartComponent {
  @Input() data: StorageUsageChartData;

  constructor(private date: DatePipe) {
  }

  get chart(): ChartConfigV4<'line'> {
    if (this.data === null) return null;

    const defaults = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0
    };

    return {
      data: {
        labels: Object.keys(defaults).map(this.toMonthName),
        datasets: toPairs(this.data).map(([year, values]) => ({
          label: year.toString(),
          data: toPairs(mergeDeepLeft(values, defaults))
            .sort((a, b) => Number(a[0]) - Number(b[0]))
            .map(pair => pair[1]),
          ...lineColors[0]
        })),
      },
      labels: Object.keys(defaults).map(this.toMonthName),
      hasData: true,
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true },
        },
        elements: {
          line: {
            fill: false,
            tension: 0
          }
        },
        scales: {
          y: toIntegerAxis({
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'Total volume (CbFT)'
            }
          })
        }
      },
      type: 'line'
    };
  }

  private toMonthName = (monthNumber: string): string => {
    return this.date.transform(new Date(2000, Number(monthNumber) - 1), 'MMM');
  };
}
