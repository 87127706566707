import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'field-text-value',
  template: '{{ text }}',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldTextValueComponent {
  @Input() text: string;
}
