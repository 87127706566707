import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RemoveAllMessages } from '@k2/common/flash-messages/actions';
import { FlashMessage } from '@k2/common/flash-messages/message';
import { getUniqFlashMessages } from '@k2/common/flash-messages/reducers';
import { RootState } from '@k2/common/flash-messages/state';
import { AppStore } from '@k2/common/state/services/app-store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';

const messageHeight = 43;

@Component({
  selector: 'flash-messages',
  templateUrl: 'flash-messages.component.html',
  styleUrls: ['flash-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlashMessagesComponent {
  public readonly messages: Observable<FlashMessage[]>;

  constructor(private readonly store: AppStore<RootState>) {
    this.messages = this.store.pipe(select(getUniqFlashMessages));
  }

  toOffset = (messageIndex: number) => `${messageIndex * messageHeight}px`;

  removeAllMessages = () => {
    this.store.dispatch(new RemoveAllMessages());
  };
}
