<fieldset [class.expanded]="expanded">
  <div *ngIf="!expanded" class="content brief">
    <label>Password</label>

    <ng-container *ngIf="hasPassword"><div>********</div></ng-container>

    <ng-container *ngIf="!hasPassword">
      <empty>No password has been set yet</empty>
    </ng-container>

    <div *ngIf="!disabled" class="edit">
      <a *ngIf="isEditable | async; else dialogLinks" (click)="expand()">
        edit
      </a>

      <ng-template #dialogLinks>
        <a *ngIf="hasPassword" (click)="config.openResetDialog()">
          send password reset email
        </a>

        <a *ngIf="!hasPassword" (click)="config.openWelcomeDialog()">
          send welcome email
        </a>
      </ng-template>
    </div>
  </div>

  <div
    *ngIf="expanded"
    class="content"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
  >
    <label>Password</label>
    <form [formGroup]="form" (submit)="submit()">
      <p>
        Password must contain at least {{ minPasswordLength }} characters,
        including {{ minPasswordNumbers }}
        <span> {{ minPasswordNumbers > 1 ? 'numbers' : 'number' }} </span> and
        {{ minPasswordSymbols }}
        <span> {{ minPasswordSymbols > 1 ? 'symbols' : 'symbol' }}. </span>
      </p>

      <div class="control-wrap">
        <div class="control">
          <input
            type="password"
            formControlName="oldPassword"
            placeholder="Old password"
            class="form-control"
          />
        </div>
        <field-errors [controls]="form.controls.oldPassword"></field-errors>

        <ng-container formGroupName="newPassword">
          <div class="control new-psw">
            <input
              type="password"
              formControlName="password"
              placeholder="New password"
              class="form-control"
            />
            <password-strength
              [password]="passwordControl.value"
            ></password-strength>
          </div>
          <field-errors [controls]="passwordControl"></field-errors>

          <div class="control">
            <input
              type="password"
              formControlName="confirm"
              placeholder="Confirm new password"
              class="form-control"
            />
          </div>
          <field-errors [controls]="confirmControl"></field-errors>
        </ng-container>
        <field-errors [controls]="form.controls.newPassword"></field-errors>
      </div>

      <submit-control (cancel)="cancel()">Save changes</submit-control>
    </form>
  </div>
</fieldset>
