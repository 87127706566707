import { Component, Input } from '@angular/core';
import { ReplayLastSubject } from '@k2/common/helpers';
import { Todo } from '../todo/todo.component';

@Component({
  selector: 'todo-list',
  templateUrl: 'todo-list.component.html',
  styleUrls: ['todo-list.component.scss']
})
export class TodoListComponent {
  @Input() todos: ReplayLastSubject<Todo[]>;
  @Input() service: string;

  constructor() {}
}
