import { EventEmitter, HostListener, Injectable, Input, Output, Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AddFormError } from '@k2/common/k2-forms-state/actions';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { equals } from 'ramda';

@Directive()
@Injectable()
export abstract class SelfSubmittingField {
  @Input() disabled = false;
  @Output() save = new EventEmitter();

  expanded = false;
  form: UntypedFormGroup;

  constructor(private actions: ActionDispatcher) {}

  expand = () => {
    this.expanded = true;
  };

  cancel = () => {
    this.expanded = false;
    this.form.reset(this.defaultValue);
  };

  submit = () => {
    if (!this.form.valid) return this.actions.dispatch(new AddFormError());
    if (!equals(this.form.value, this.defaultValue)) {
      this.save.next(this.submitValue);
    }
    this.expanded = false;
  };

  protected abstract get defaultValue();

  protected abstract get submitValue();

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.cancel();
    }
  }
}
