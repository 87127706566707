import { NgModule } from '@angular/core';
import { permissionsHttpInterceptorProvider } from '@k2/common/permissions-state/http-interceptor';
import { featureReducer } from '@k2/common/permissions-state/reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [StoreModule.forFeature('permissions', featureReducer)],
  declarations: [],
  exports: [],
  providers: [permissionsHttpInterceptorProvider]
})
export class PermissionsStateModule {}
