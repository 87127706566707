<h2>By subsidiary</h2>
<div *ngFor="let subsidiary of subsidiaries">
  <div class="row">
    <div class="col-xs-3">
      {{ subsidiary.name }}
    </div>
    <div class="col-xs-6">
      <progress-bar
        [progress]="
          (subsidiary.totalRecieved / subsidiary.totalRequested) * 100
        "
      ></progress-bar>
    </div>
    <div class="col-xs-1">
      {{
        (subsidiary.totalRecieved / subsidiary.totalRequested) * 100
          | number: '1.0-0'
      }}%
    </div>
  </div>
</div>
