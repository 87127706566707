import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FilterOption, ignoreValue } from '@k2/common/filters';


@Component({
  selector: 'multi-select-filter',
  templateUrl: 'multi-select-filter.component.html',
  styleUrls: ['multi-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectFilterComponent {
  @Input() control: UntypedFormControl;
  @Input() options: FilterOption[];
  @Input() placeholder = 'all';
  @Input() displayPlaceholder = true;

  get ignoreValue() {
    return ignoreValue;
  }
}
