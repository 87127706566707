import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isPaginated, Paginator } from '@k2/common/pagination/paginator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'show-more-paginator',
  templateUrl: 'show-more-paginator.component.html',
  styleUrls: ['show-more-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMorePaginatorComponent<T> {
  @Input() paginator: Paginator;
  @Input() items: T[];

  get isVisible(): Observable<boolean> {
    return this.paginator.state.pipe(map(isPaginated));
  }

  increaseSize = () => {
    this.paginator.updateBy(state => ({
      offset: 0,
      limit: state.limit + state.pageSize
    }));
  };
}
