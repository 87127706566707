import { ApiClient } from '@k2/common/backend/api-client';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { map } from 'rxjs/operators';

export class AssignmentAssigneeEndpoint {
  constructor(private api: ApiClient) {}

  fetchAssignmentAssignee = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api.get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignee`);
  };

  updateAssigneePersonalDetails = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/personal_details`,
      data
    );
  };

  fetchNewAddressForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/address/form`)
      .pipe(map(payload => payload.form));
  };

  createNewAddress = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/addresses`,
      data
    );
  };

  fetchEditAddressForm = ({ clientId, assignmentId }: AssignmentIdentity, id: number) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/address/${id}`)
      .pipe(map(payload => payload.form));
  };

  updateAddress = ({ clientId, assignmentId }: AssignmentIdentity, id: number, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/address/${id}`,
      data
    );
  };

  deleteAddress = ({ clientId, assignmentId }: AssignmentIdentity, id: number) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/address/${id}`
    );
  };

  fetchNewDelegateForm = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/delegate/form`)
      .pipe(map(payload => payload.form));
  };

  createNewDelegate = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/delegate/new`,
      data
    );
  };

  fetchEditDelegateForm = ({ clientId, assignmentId }: AssignmentIdentity, id: string) => {
    return this.api
      .get(`/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/delegate/${id}`)
      .pipe(map(payload => payload.form));
  };

  updateDelegate = ({ clientId, assignmentId }: AssignmentIdentity, id: string, data: any) => {
    return this.api.patch(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/delegate/${id}`,
      data
    );
  };

  deleteDelegate = ({ clientId, assignmentId }: AssignmentIdentity, id: string) => {
    return this.api.delete(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/delegate/${id}`
    );
  };

  manualSyncDelegateWithK2Workspace = ({ clientId, assignmentId }: AssignmentIdentity, id: string, currentState: string) => {
    return this.api.post(
      `/clients/${clientId}/assignment/${assignmentId}/analysis/assignee/delegate/manual-sync/${id}/${currentState}`, {}
    );
  }
}
