import { assignmentSchema } from '@k2/common/assignments-state/normalizer-schemas';
import { clientSchema } from '@k2/common/clients-state/normalizer-schemas';
import { k2UserSchema } from '@k2/common/users-state/normalizer-schemas';
import { schema } from 'normalizr';

export const taskSchema = new schema.Entity('tasks', {
  assignment: assignmentSchema,
  assigned_to: k2UserSchema,
  client: clientSchema
});

export const tasksListSchema = new schema.Array(taskSchema);
