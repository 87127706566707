<span class="{{ type }}">
  <ng-container [ngSwitch]="type">
    <icon
      *ngSwitchCase="'ok'"
      class="visible-xs-inline"
      type="ok"
      matTooltip="ok"
    ></icon>
    <icon
      *ngSwitchCase="'done'"
      class="visible-xs-inline"
      type="ok"
      matTooltip="done"
    ></icon>
    <icon
      *ngSwitchCase="'cancel'"
      class="visible-xs-inline"
      type="ban-circle"
      matTooltip="cancel"
    ></icon>
    <icon
      *ngSwitchCase="'edit'"
      class="visible-xs-inline"
      type="pencil"
      matTooltip="edit"
    ></icon>
    <icon
      *ngSwitchCase="'remove'"
      class="visible-xs-inline"
      type="remove"
      matTooltip="remove"
    ></icon>
    <icon *ngSwitchDefault class="visible-xs-inline" type="ok"></icon>
  </ng-container>

  <span class="hidden-xs" #content><ng-content></ng-content></span>
</span>
