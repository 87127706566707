import { ApiClient } from '@k2/common/backend/api-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AutocompleteEndpoint {
  constructor(private api: ApiClient) {}

  fetchSuggestions = (resource: string, query: string): Observable<string[]> => {
    const [table, column] = resource.split('.', 2);

    let path = `/autocomplete/${table}/${column}`;
    if (query != null) {
      path += `/${encodeURIComponent(query)}`;
    }

    return this.api.get(path).pipe(map(payload => payload.autocomplete));
  };
}
