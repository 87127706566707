import { Component, Input, OnChanges } from '@angular/core';
import { isNotEmpty, isNotNil } from '@k2/common/helpers';
import { Field, MultiFieldOption } from '@k2/common/k2-forms-state/types';
import { times } from 'ramda';

@Component({
  selector: 'multi-field-control',
  templateUrl: 'multi-field-control.component.html',
  styleUrls: ['multi-field-control.component.scss']
})
export class MultiFieldControlComponent implements OnChanges {
  @Input() field: Field<Value[]>;
  values: Value[];

  ngOnChanges(): void {
    const values = this.field.control.value;
    if (values == null || values.length === 0) {
      this.values = [this.defaultValue];
    } else {
      this.values = values;
    }
  }

  propagateValueChange() {
    const validValues = this.values.filter(isValidValue);
    this.field.control.setValue(validValues);
  }

  addEmptyValue = () => {
    this.values = [...this.values, this.defaultValue];
  };

  removeValue = (value: Value) => {
    this.values = this.values.filter(existing => existing !== value);

    if (this.values.length === 0) {
      this.values = [this.defaultValue];
    }

    this.propagateValueChange();
  };

  get canRemove(): boolean {
    return this.values.length > 1;
  }

  get options(): MultiFieldOption[] {
    return this.field.attributes.valueOptions;
  }

  private get defaultValue(): Value {
    const valueSize = this.field.attributes.valueOptions.length;
    return times(() => null, valueSize);
  }

  get disabled() {
    return this.field.control.disabled;
  }

  touch = () => {
    this.field.control.markAsTouched();
  };
}

type Value = any[];

function isValidValue(value: Value): boolean {
  return value.every(v => isNotNil(v) && isNotEmpty(v));
}
