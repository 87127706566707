import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { DocumentFile } from '@k2/common/entities-state/types';
import { AddMessage } from '@k2/common/flash-messages/actions';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import qq from '@k2/common/uploads/fine-uploader/fine-uploader.js';
import { pipe, prop } from 'ramda';

@Component({
  selector: 'documents-uploader',
  templateUrl: 'documents-uploader.component.html'
})
export class DocumentsUploaderComponent implements OnInit, OnDestroy {
  @Input() multiple = true;
  @Output() state = new EventEmitter<UploaderState>();

  @ViewChild('fineUploader', { static: true }) private fineUploaderRef;

  private fineUploader;

  constructor(private actions: ActionDispatcher, private backend: Backend) {}

  ngOnInit(): void {
    const options = {
      element: this.fineUploaderRef.nativeElement,
      multiple: true, // Do not change this as working with FineUploader is the worst experience ever :(
      request: {
        method: 'POST',
        forceMultipart: false,
        endpoint: this.backend.toApiUrl('/documents/files')
      },
      deleteFile: {
        enabled: true,
        method: 'DELETE'
      },
      callbacks: {
        onUpload: this.propagateState,
        onAllComplete: this.propagateState,
        onCancel: this.propagateState,
        onError: this.onError,
        onComplete: this.onComplete,
        onSubmitDelete: this.setDeleteFileEndpoint,
        onDeleteComplete: this.propagateState
      },
      cors: {
        expected: true,
        sendCredentials: true
      }
    };

    this.fineUploader = new qq.FineUploader(options);
    this.fineUploader.addExtraDropzone(document.body);
  }

  ngOnDestroy(): void {
    this.fineUploader.cancelAll();
  }

  private setDeleteFileEndpoint = (fileId: number) => {
    const file = this.getFile(fileId);
    const url = this.backend.toApiUrl(`/documents/files/${file.id}/${file.identifier_hash}`);

    this.fineUploader.setDeleteFileEndpoint(url, fileId);
  };

  private onComplete = (id: number, name: string, response) => {
    const file = this.getFile(id);
    file.id = response.payload.file_id;
    file.identifier_hash = response.payload.identifier_hash;

    this.propagateState();
  };

  private onError = (id, name, reason, request: XMLHttpRequest) => {
    try {
      const body = JSON.parse(request.response);
      body.meta.flash
        .map(({ text }) => new AddMessage({ text, type: 'error' }))
        .forEach(this.actions.dispatch);
    } catch (e) {
      console.error('Unexpected error during file upload!', request);

      this.actions.dispatch(
        new AddMessage({ text: 'Unexpected error during file upload!', type: 'error' })
      );
    }

    this.propagateState();
  };

  private propagateState = () => {
    const toNormalizedFile = pipe(prop('id'), this.getFile, toDocumentFile);

    let state = {
      uploading: this.fineUploader
        .getUploads({ status: qq.status.UPLOADING })
        .map(toNormalizedFile),

      uploaded: this.fineUploader
        .getUploads({ status: qq.status.UPLOAD_SUCCESSFUL })
        .map(toNormalizedFile)
    };

    const uploading = this.fineUploader.getUploads({ status: qq.status.UPLOADING });
    const uploaded = this.fineUploader.getUploads({ status: qq.status.UPLOAD_SUCCESSFUL });

    if (!this.multiple && uploaded.length > 0 && uploading.length > 0) {
      uploaded.forEach(({ id }) => this.fineUploader.deleteFile(id));
      state = {
        uploading: state.uploading,
        uploaded: []
      };
    }

    this.state.emit(state);
  };

  private getFile = (fileId: number): UploaderFile => this.fineUploader.getFile(fileId);
}

function toDocumentFile({ id, identifier_hash, name, type }): DocumentFile {
  return { id, identifier_hash, name, mime_type: type };
}

type UploaderFile = DocumentFile & File;

export interface UploaderState {
  readonly uploading: DocumentFile[];
  readonly uploaded: DocumentFile[];
}
