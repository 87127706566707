import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'relative-time',
  templateUrl: 'relative-time.component.html',
  styleUrls: ['relative-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelativeTimeComponent implements OnChanges {
  @Input() iso: string; // ISO8601
  @Input('precise') isPrecise = false;


  ngOnChanges(changes: SimpleChanges) {
    if (changes.iso) {
      this.iso = this.iso?.replace(/\s/g, 'T');
    }
  }

  get time(): Date {
    return new Date(this.iso);
  }

  get isToday(): boolean {
    const now = new Date();
    const time = new Date(this.iso);

    return now.toDateString() === time.toDateString();
  }
}
