<ng-container *ngIf="tooltip" [ngSwitch]="tooltip.type">
  <span *ngSwitchCase="'success'"
        class="glyphicon glyphicon-ok-sign"
        customTooltip [text]="tooltip.text" [listData]="tooltip.listData"></span>

  <span *ngSwitchCase="'error'"
        class="glyphicon glyphicon-alert"
        customTooltip [text]="tooltip.text" [listData]="tooltip.listData"></span>

  <span *ngSwitchDefault
        class="glyphicon glyphicon-info-sign"
        [ngStyle]="tooltip.color ? { color: tooltip.color } : {}"
        customTooltip [text]="tooltip.text" [listData]="tooltip.listData"></span>
</ng-container>
