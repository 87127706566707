import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'email-contact',
  templateUrl: 'email-contact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailContactComponent {
  @Input() contact: string;

  get address(): string {
    const matches = this.contact.match(/.*<(.*)>/);
    return matches !== null ? matches[1] : this.contact;
  }
}
