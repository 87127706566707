import { getFieldsSpec } from '@k2/common/k2-forms-state/reducers';

export const getNewK2UserFieldsSpec = getFieldsSpec(['k2User']);

export function getK2UserWelcomeFieldsSpec(recipientEmail: string) {
  return getFieldsSpec(['k2UserWelcome', recipientEmail]);
}

export function getK2UserPasswordResetFieldsSpec(recipientEmail: string) {
  return getFieldsSpec(['k2UserPasswordReset', recipientEmail]);
}

export function getK2UserFieldsSpec(userId: number) {
  return getFieldsSpec(['k2User', userId]);
}

export function getK2UserSecurityFieldsSpec(userId: number) {
  return getFieldsSpec(['k2User', userId]);
}
