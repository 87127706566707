import { Injectable } from '@angular/core';
import { RootState } from '@k2/common/client-admins-state/state';
import { fieldsSpecToFields } from '@k2/common/k2-forms/fields';
import { FormDialogService } from '@k2/common/k2-forms/form-dialog/form-dialog.service';
import {
  FetchK2UserPasswordResetForm,
  FetchK2UserWelcomeForm,
  ResetK2UserPassword,
  SendK2UserWelcomeEmail
} from '@k2/common/k2-users-state/actions';
import {
  getK2UserPasswordResetFieldsSpec,
  getK2UserWelcomeFieldsSpec
} from '@k2/common/k2-users-state/selectors';
import { AppStore } from '@k2/common/state/services/app-store';
import { select } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class K2UserDialogs {
  constructor(private store: AppStore<RootState>, private dialog: FormDialogService) {}

  openWelcomeDialog = (recipientEmail: string) => {
    this.store.dispatch(new FetchK2UserWelcomeForm(recipientEmail));

    const fields = this.store.pipe(
      select(getK2UserWelcomeFieldsSpec(recipientEmail)),
      distinctUntilChanged(),
      fieldsSpecToFields
    );

    this.dialog
      .openEmailForm({ title: 'New K2 user welcome email', fields })
      .subscribe(value => this.store.dispatch(new SendK2UserWelcomeEmail(value)));
  };

  openResetDialog = (recipientEmail: string) => {
    this.store.dispatch(new FetchK2UserPasswordResetForm(recipientEmail));

    const fields = this.store.pipe(
      select(getK2UserPasswordResetFieldsSpec(recipientEmail)),
      distinctUntilChanged(),
      fieldsSpecToFields
    );

    this.dialog
      .openEmailForm({ title: 'K2 user password reset email', fields })
      .subscribe(value => this.store.dispatch(new ResetK2UserPassword(value)));
  };
}
