import {
  Actions,
  ADD_MESSAGE,
  REMOVE_ALL_MESSAGES,
  REMOVE_MESSAGE
} from '@k2/common/flash-messages/actions';
import { FlashMessage } from '@k2/common/flash-messages/message';
import { RootState, State } from '@k2/common/flash-messages/state';
import { pipe, uniqBy } from 'ramda';

export function featureReducer(state: State = [], action: Actions): State {
  switch (action.type) {
    case ADD_MESSAGE: {
      return [action.message, ...state];
    }
    case REMOVE_MESSAGE: {
      return state.filter(msg => msg !== action.message);
    }
    case REMOVE_ALL_MESSAGES: {
      return [];
    }
    default: {
      return state;
    }
  }
}

export const getFlashMessages = (rootState: RootState): FlashMessage[] => rootState.flashMessages;
export const getUniqFlashMessages = pipe(
  getFlashMessages,
  uniqBy(msg => msg.text)
);
