import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeEN_AU from '@angular/common/locales/en-AU';
import localeEN_GB from '@angular/common/locales/en-GB';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeJA from '@angular/common/locales/ja';
import localePL from '@angular/common/locales/pl';
import localeRU from '@angular/common/locales/ru';
import localeSV from '@angular/common/locales/sv';
import localeZH from '@angular/common/locales/zh';
import { LOCALE_ID, NgModule } from '@angular/core';

registerLocaleData(localeEN);
registerLocaleData(localeEN_GB);
registerLocaleData(localeEN_AU);
registerLocaleData(localeFR);
registerLocaleData(localeDE);
registerLocaleData(localeES);
registerLocaleData(localePL);
registerLocaleData(localeRU);
registerLocaleData(localeJA);
registerLocaleData(localeSV);
registerLocaleData(localeZH);

const defaultLocale = 'en-GB';
const supportedLocales = {
  en: localeEN,
  'en-US': undefined, // Included by default in Angular
  'en-GB': localeEN_GB,
  'en-AU': localeEN_AU,
  fr: localeFR,
  de: localeDE,
  es: localeES,
  pl: localePL,
  ru: localeRU,
  ja: localeJA,
  sv: localeSV,
  zh: localeZH
};

export const currentLocale = getLocaleId();

function getLocaleId(): string {
  try {
    const { navigator } = window;

    // IE 11
    const legacyNavigator = navigator as any;

    const preferredLocales = [
      ...(navigator.languages || []),
      navigator.language,
      legacyNavigator.browserLanguage,
      legacyNavigator.systemLanguage,
      legacyNavigator.userLanguage
    ].filter(locale => locale != null);

    for (const preferred of preferredLocales) {
      if (Object.keys(supportedLocales).includes(preferred)) return preferred;
    }

    return defaultLocale;
  } catch (error) {
    console.error('Failed to detect locale!', error);
    console.warn(`Using the default locale: ${defaultLocale}.`);
    return defaultLocale;
  }
}

export function getTimeFormat(): 12 | 24 {
  const localeSpec = supportedLocales[currentLocale];
  if (localeSpec != null && localeSpec[8] === 1) return 24;
  return 12;
}

@NgModule({
  imports: [],
  providers: [{ provide: LOCALE_ID, useValue: currentLocale }]
})
export class LocaleModule {}
