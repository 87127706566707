import { Injectable } from '@angular/core';
import { toBasicActions } from '@k2/common/state/batch-action';
import { Action, ActionsSubject, ReducerManager, StateObservable, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppStore<T> extends Store<T> {
  constructor(
    state$: StateObservable,
    private actions: ActionsSubject,
    reducerManager: ReducerManager
  ) {
    super(state$, actions, reducerManager);
  }

  dispatch(action: Action): Observable<any> {
    const actions = toBasicActions(action);
    actions.forEach(action => super.dispatch(action));

    return this.actions.pipe(
      first((next: ObservableActionResult) => next.source === action),
      timeout(30000),
      map((action: ObservableActionResult) => {
        if (action.type.endsWith('(SUCCESS)')) return action.payload;
        throw action.payload;
      })
    );
  }
}

export class ObservableActionResult implements Action {
  constructor(readonly type: string, readonly source: Action, readonly payload: any) {
  }

  static success(source: Action, payload: any) {
    return new ObservableActionResult(`${source.type} (SUCCESS)`, source, payload);
  }

  static failed(source: Action, payload: any) {
    return new ObservableActionResult(`${source.type} (FAILED)`, source, payload);
  }
}
