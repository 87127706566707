import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address, AssignmentBilling, Currency, Subsidiary } from '@k2/common/entities-state/types';

@Component({
  selector: 'assignment-billing',
  templateUrl: 'assignment-billing.component.html',
  styleUrls: ['assignment-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentBillingComponent {
  @Input() billing: AssignmentBilling;

  get currency(): Currency {
    return this.billing.currency;
  }

  get subsidiary(): Subsidiary {
    return this.billing.subsidiary;
  }

  get address(): Address {
    return this.billing.address;
  }
}
