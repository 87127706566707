import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { isRequired } from '@k2/common/k2-forms/controls';

@Component({
  selector: 'field-label',
  templateUrl: 'field-label.component.html',
  styleUrls: ['field-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldLabelComponent {
  @Input() field: Field;
  @Input() notRequired = false;

  get isRequired(): boolean {
    return isRequired(this.field.control);
  }

  get label(): string {
    return this.field.attributes.label;
  }

  get isEmpty(): boolean {
    return this.label == null || this.label === '';
  }

  get notes(): string {
    return this.field.attributes.notes;
  }
}
