import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterByService' })
export class FilterByServicePipe implements PipeTransform {
  transform(messages: any[], service: { id: number; name: string } | ''): any[] {
    if (service === '') return messages;
    return messages.filter(message => {
      return message.services_id === service.id;
    });
  }
}
