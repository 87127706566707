import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { addValidators, WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import { isFloat, isFloatTooLong } from '@k2/common/k2-forms/validation-functions';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';

@Component({
  selector: 'budget-control',
  templateUrl: 'budget-control.component.html',
  styleUrls: ['budget-control.component.scss']
})
export class BudgetControlComponent extends WithFormControl<BudgetValue> implements OnChanges {
  get currencies(): K2SelectOption[] {
    return (this.field.attributes as any).currencyOptions.map(option => ({
      id: option.id,
      text: option.value
    }));
  }

  get frequencies(): ValueOption[] {
    return (this.field.attributes as any).frequencyOptions;
  }

  @ViewChild('amount', { static: true }) amountInput: ElementRef;
  @Input() field: Field<BudgetValue>;
  model: BudgetValue;
  focused: { currency: boolean; amount: boolean; frequency: boolean } = {
    currency: false,
    amount: false,
    frequency: false
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.field) return;
    this.value = { currency: null, amount: null, frequency: null };
    this.model = this.field.defaultValue || { currency: null, amount: null, frequency: null };
    this.value = this.model;
    addValidators(this.field.control, this.customValidator);
    this.field.control.updateValueAndValidity();
  }

  private customValidator = (c: AbstractControl): ValidationErrors => {
    const { currency, amount, frequency } = this.model;
    const errors: any = {};

    if (this.focused.currency || this.focused.amount || this.focused.frequency) {
      return null;
    }

    if (amount && !isFloat(amount)) errors.float = true;
    if (amount && isFloatTooLong(amount)) errors.tooLongFloat = true;
    if (amount && amount < 0) return { min: `Field value cannot be less than 0` };

    if (Object.keys(errors).length === 0) return null;
    return errors;
  };

  update = (newValue, propertyName: keyof BudgetValue) => {
    if (newValue) {
      this.touch();
      this.markDirty();
    }
    this.model = { ...this.model, [propertyName]: newValue };
    if (this.model.currency !== null) {
      this.value.currency = this.model.currency;
    }
    if (this.model.amount !== null) {
      this.value.amount = this.model.amount;
    }
    if (this.model.frequency !== null) {
      this.value.frequency = this.model.frequency;
    }
    this.field.control.updateValueAndValidity();
  };

  onFrequencyTouch() {
    this.onFocusChanged('frequency', false);
    this.touch();
  }

  currencyOpenedChange(opened: boolean) {
    this.onFocusChanged('currency', opened);
    if (!opened) {
      this.amountInput.nativeElement.focus();
    }
  }

  onFocusChanged(key: string, isFocused: boolean) {
    this.focused[key] = isFocused;
    this.field.control.updateValueAndValidity();
  }
}

export interface BudgetValue {
  currency: number;
  amount: number;
  frequency: number;
}
