<ng-container *ngIf="isDisplayed">
  <div class="head" [class.inactive]="isNotActive | async">
    <ng-container *ngIf="region | async | isNotEmpty">
      <country-flag
        [code]="(region | async).country_iso"
        [tooltip]="(region | async).name"
      ></country-flag>

      <h1>
        <a routerLink="./">{{ name | async }}</a>
      </h1>

      <tag *ngIf="isNotActive | async" [value]="status | async"></tag>
    </ng-container>
  </div>

  <div class="content"><router-outlet></router-outlet></div>
</ng-container>
