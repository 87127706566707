<ng-container *ngSubscribe="fields as fields">
  <field [field]="fields.summary" class="medium"></field>
  <field [field]="fields.requested_at" class="medium"></field>
  <field [field]="fields.description" class="medium"></field>
  <field [field]="fields.application_for" class="medium"></field>
  <div>
  <field [field]="fields.amount" class="medium"></field>
  <field [field]="fields.currencies_id" class="medium"></field>
  <field [field]="fields.frequency" class="medium"></field>
  <field [field]="fields.suggested_resolution" class="medium"></field>
  </div>
  <field [field]="fields.exception_status" class="medium"></field>
  <field [field]="fields.decision_date" class="medium"></field>
  <field [field]="fields.client_contact" class="medium"></field>
  <field [field]="fields.comments" class="medium"></field>
</ng-container>
