import { ApiClient } from '@k2/common/backend/api-client';
import { AssignmentIdentity } from '@k2/common/entities-state/types';
import { map } from 'rxjs/operators';

export class HrEndpoint {
  constructor(private api: ApiClient) {}

  fetchAssignee = (assigneeId: number) => {
    return this.api.get(`/hr/assignee/${assigneeId}/overview`);
  };

  fetchAssigneeDocuments = (assigneeId: number) => {
    return this.api
      .get(`/hr/assignee/${assigneeId}/documents`)
      .pipe(map(payload => payload.documents));
  };

  fetchAdminAssignments = (userId: number) => {
    return this.api
      .get(`/hr/admin/${userId}/assignments`)
      .pipe(map(payload => payload.assignments));
  };

  fetchAdminPendingExceptions = (userId: number) => {
    return this.api
      .get(`/hr/${userId}/exceptions`)
      .pipe(map(payload => payload.pending_exceptions));
  };

  fetchClientOnlyDocuments = (clientId: number) => {
    return this.api.get(`/hr/${clientId}/client/documents`).pipe(map(payload => payload.documents));
  };

  fetchAssignmentDetails = ({ clientId, assignmentId }: AssignmentIdentity) => {
    return this.api
      .get(`/hr/${clientId}/assignment/${assignmentId}/details`)
      .pipe(map(payload => payload.form));
  };

  updateAssignmentDetails = ({ clientId, assignmentId }: AssignmentIdentity, data: any) => {
    return this.api.patch(`/hr/${clientId}/assignment/${assignmentId}/details`, data);
  };

  fetchAssignmentDocuments = (assignmentId: number) => {
    return this.api
      .get(`/hr/assignment/${assignmentId}/documents`)
      .pipe(map(payload => payload.documents));
  };

  fetchNewAssignmentDocumentForm = (assignmentId: number) => {
    return this.api
      .get(`/hr/assignment/${assignmentId}/documents/new`)
      .pipe(map(payload => payload.form));
  };

  createAssignmentDocument = (assignmentId: number, data) => {
    return this.api.post(`/hr/assignment/${assignmentId}/documents/new`, data);
  };

  deleteAssignmentDocument = (assignmentId: number, documentId: number) => {
    return this.api.delete(`/hr/assignment/${assignmentId}/document/${documentId}`);
  };

  fetchYearOnYearReports = () => {
    return this.api.get(`/hr/report/year-on-year`).pipe(map(payload => payload.reports));
  };

  demoteClientAdminAccount = (clientId: number, assigneeId: number) => {
    return this.api.delete(`/hr/clients/${clientId}/assignee/${assigneeId}/demote-from-admin`);
  };

  fetchPromoteToAdminForm = (clientId: number, assigneeId: number) => {
    return this.api
      .get(`/hr/clients/${clientId}/assignee/${assigneeId}/promote-to-admin-form`)
      .pipe(map(payload => payload.form));
  };

  patchPromoteAssigneeToAdmin = (clientId: number, assigneeId: number, data) => {
    return this.api.patch(`/hr/clients/${clientId}/assignee/${assigneeId}/promote-to-admin`, data);
  };
}
