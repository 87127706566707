import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { merge, toPairs, uniq } from 'ramda';

/**
 * Renders an each unique error message from a provided form controls.
 *
 * A custom error messages can be provided to override default error messages.
 */
@Component({
  selector: 'field-errors',
  templateUrl: 'field-errors.component.html',
  styleUrls: ['field-errors.component.scss']
})
export class FieldErrorsComponent {
  @Input() controls: AbstractControl | AbstractControl[];
  @Input('messages') customMessages?: { [errorName: string]: string } = {};

  constructor(private formGroup: FormGroupDirective) {}

  get current(): string[] {
    const controls = Array.isArray(this.controls) ? this.controls : [this.controls];
    const errors = controls
      .filter(hasError(this.formGroup))
      .map(control => control.errors)
      .reduce(merge, {});

    return uniq(
      toPairs(errors).map(([errorName, value]) => {
        if (this.customMessages[errorName] != null) return this.customMessages[errorName];
        if (typeof value === 'string') return value;
        return 'Field contains an invalid value';
      })
    );
  }
}

function hasError({ submitted }: FormGroupDirective) {
  return ({ errors, touched, dirty }: AbstractControl) =>
    errors != null && ((touched && dirty) || submitted);
}
