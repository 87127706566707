import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import {
  IMAGE_OVERLAY_DATA,
  ImageOverlayData
} from '@k2/common/ui/components/image-overlay/image-overlay-data';
export { ImageOverlayData } from '@k2/common/ui/components/image-overlay/image-overlay-data';

@Component({
  selector: 'image-overlay',
  templateUrl: 'image-overlay.component.html',
  styleUrls: ['image-overlay.component.scss']
})
export class ImageOverlayComponent implements AfterViewInit, OnDestroy {
  visible = false;

  constructor(@Inject(IMAGE_OVERLAY_DATA) readonly data: ImageOverlayData) {}

  ngAfterViewInit(): void {
    window.addEventListener('keyup', this.handleKeyEvent);

    setTimeout(() => {
      this.visible = true;
    });
  }

  private handleKeyEvent = (event: KeyboardEvent) => {
    if (event.code === 'Escape') {
      this.close();
    }
  };

  close = () => {
    this.visible = false;
    setTimeout(() => this.data.overlay.dispose(), 150);
  };

  ngOnDestroy(): void {
    window.removeEventListener('keyup', this.handleKeyEvent);
  }
}
