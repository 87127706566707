import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { hasFamily } from '@k2/common/assignees/utils';
import { Assignee } from '@k2/common/entities-state/types';

@Component({
  selector: 'assignee-card',
  templateUrl: 'assignee-card.component.html',
  styleUrls: ['assignee-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssigneeCardComponent {
  @Input() assignee: Assignee;
  @Input() serviceLevelTypesId: number | string;
  @Input() assigneeLink: any;
  @Input() familyLink: any;
  @Input() petsLink: any;
  @Input() isAssignmentEncrypted: boolean = true;

  get isNotDefault(): boolean {
    return this.serviceLevelTypesId && this.serviceLevelTypesId.toString() !== '1796';
  }

  get assignmentServiceLevelTag(): string {
    if (this.serviceLevelTypesId.toString() === '1797') {
      return 'vip';
    } else if (this.serviceLevelTypesId.toString() === '1798') {
      return 'bespoke';
    }
    return null;
  }

  get hasFamily(): boolean {
    return hasFamily(this.assignee);
  }
}
