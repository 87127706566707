<ng-container *ngIf="history | isLoaded">
  <k2-table [items]="history.current" [paginator]="currentPaginator">
    <ng-container header><h2>Active logins</h2></ng-container>

    <table>
      <thead>
        <tr>
          <th>OS</th>
          <th class="p2">Browser</th>
          <th>IP Address</th>
          <th class="text-center">Country</th>
          <th *ngIf="loginType === 1770" class="options">Options</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let session of history.current | paginate: currentPaginator | async
          "
        >
          <td>{{ session.os }}</td>
          <td class="p2">{{ session.browser }}</td>
          <td>{{ session.ip }}</td>
          <td class="text-center">
            <country-flag
              *ngIf="session.country_name"
              [code]="session.country"
              [tooltip]="session.country_name"
            ></country-flag>
            <empty *ngIf="session.country_name == null">Unknown</empty>
          </td>
          <td *ngIf="loginType === 1770" class="options">
            <a (click)="deleteSession(session)">
              <action type="remove">logout</action>
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <no-data ifNothing>{{ userName }} is not currently logged in.</no-data>
  </k2-table>

  <k2-table [items]="history.history" [paginator]="historyPaginator">
    <ng-container header>
      <h2>Last {{ history.history.length }} successful logins</h2>
    </ng-container>

    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>OS</th>
          <th class="p2">Browser</th>
          <th>IP Address</th>
          <th class="text-center">Country</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let session of history.history | paginate: historyPaginator | async
          "
        >
          <td><date-time [iso]="session.time"></date-time></td>
          <td>{{ session.os }}</td>
          <td class="p2">{{ session.browser }}</td>
          <td>{{ session.ip }}</td>
          <td class="text-center">
            <country-flag
              *ngIf="session.country_name"
              [code]="session.country"
              [tooltip]="session.country_name"
            ></country-flag>
            <empty *ngIf="session.country_name == null">Unknown</empty>
          </td>
        </tr>
      </tbody>
    </table>

    <no-data ifNothing>{{ userName }} has never logged in.</no-data>
  </k2-table>
</ng-container>
