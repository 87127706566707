import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Field } from '../../../k2-forms-state/types';

@Component({
  selector: 'contact-cards-control',
  templateUrl: './contact-cards-control.component.html',
  styleUrls: ['./contact-cards-control.component.scss']
})
export class ContactCardsControlComponent implements OnInit {
  @Input() field: Field;

  name: UntypedFormControl = new UntypedFormControl('', Validators.required);
  email: UntypedFormControl = new UntypedFormControl('', Validators.required);
  telephone: UntypedFormControl = new UntypedFormControl('', Validators.required);

  ngOnInit(): void {
    this._createDefaultModel();
  }

  get options(): ContactCardValue[] {
    return this.field.attributes.valueOptions;
  }

  get hasALotOptions(): boolean {
    return this.options.length > 8;
  }

  get disabled() {
    return this.field.control.disabled;
  }

  updateModel(contactId: number) {
    this.field.control.patchValue({
      id: contactId,
      name: null,
      telephone: null,
      email: null
    });
  }

  updateModelWithCustom() {
    this.field.control.patchValue({
      id: null,
      name: this.name.value,
      telephone: this.telephone.value,
      email: this.email.value
    });
  }

  isCustomSelected(): boolean {
    return this.field.control.value.id === null;
  }

  private _createDefaultModel() {
    let CONTACTS = this.field.attributes.valueOptions;

    if (CONTACTS.length === 0)
      throw new Error('Contact cards field requires at least one value option.');

    this.field.defaultValue
      ? this.field.control.patchValue({ id: this.field.defaultValue.id })
      : this.field.control.patchValue({ id: CONTACTS[0].id });
  }
}

export class ContactCardValue {
  email: string;
  first_name: string;
  id: number;
  job_title: string;
  last_name: string;
  responsibility: string;
  telephone_work: string;
}
