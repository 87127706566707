import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'date-time',
  templateUrl: 'date-time.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeComponent implements OnChanges {
  @Input() iso: string; // ISO8601
  @Input() utc: boolean = true;

  get utcTimezone() {
    return this.utc ? 'UTC' : null;
  }

  ngOnChanges() {
    this.iso = this.iso?.replace(/\s/g, 'T');
  }
}
