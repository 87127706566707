import { ApiClient } from '@k2/common/backend/api-client';
import { FeedbackQuestion, FeedbackQuestions } from '@k2/common/entities-state/types';
import { SelectOption } from '@k2/common/ui/components/basic-select/basic-select.component';
import { mapObjIndexed } from 'ramda';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditTrail, AuditTrailFilters, AuditTrailFiltersForm, IUserOption } from '@k2/common/models/audit-trail';

export class ConfigEndpoint {
  constructor(private api: ApiClient) {
  }

  fetchRegions = () => {
    return this.api.get(`/config/countries`).pipe(map(payload => payload.countries));
  };

  fetchNewRegionForm = () => {
    return this.api.get(`/form/country.form`).pipe(map(payload => payload.form));
  };

  createRegion = (data: any) => {
    return this.api.post(`/config/countries`, data);
  };

  fetchRegionForm = (regionId: number) => {
    return this.api
      .get(`/config/countries/${regionId}/details`)
      .pipe(map(payload => payload.country));
  };

  updateRegion = (regionId: number, data: any) => {
    return this.api.patch(`/config/countries/${regionId}/details`, data);
  };

  fetchCurrenciesState = () => {
    return this.api.get(`/config/currencies`);
  };

  fetchLiveCurrencies = () => {
    return this.api.get(`/config/currencies/live`).pipe(map(payload => payload.currencies));
  };

  updateCurrencies = (data: any) => {
    return this.api.patch(`/config/currencies`, data);
  };

  fetchCurrencyAdjustmentFactors = () => {
    return this.api.get(`/config/currency/adjustment/factors`);
  };

  updateCurrencyAdjustmentFactors = data => {
    return this.api.patch(`/config/currency/adjustment/factors`, data);
  };

  fetchServices = () => {
    return this.api.get(`/config/services`).pipe(map(payload => payload.services));
  };

  fetchNewServiceForm = () => {
    return this.api.get(`/config/services/new`).pipe(map(payload => payload.form));
  };

  createService = (data: any) => {
    return this.api.post(`/config/services`, data);
  };

  fetchServiceForm = (serviceId: number) => {
    return this.api.get(`/config/services/${serviceId}`);
  };

  updateService = (serviceId: number, data: any) => {
    return this.api.patch(`/config/services/${serviceId}`, data);
  };

  fetchQuestions = () => {
    return this.api.get('/config/feedback/questions').pipe(map(payload => payload.questions));
  };

  fetchNewQuestionForm = () => {
    return this.api.get('/config/feedback/questions/new').pipe(map(payload => payload.form));
  };

  createQuestion = (data: any) => {
    return this.api.post('/config/feedback/questions', data);
  };

  fetchQuestionForm = (id: number) => {
    return this.api.get(`/config/feedback/questions/${id}`).pipe(map(payload => payload.form));
  };

  updateQuestion = (id: number, data: any) => {
    return this.api.patch(`/config/feedback/questions/${id}`, data);
  };

  deleteQuestion = (id: number) => {
    return this.api.delete(`/config/feedback/questions/${id}`);
  };

  fetchManagerQuestionsMapping = () => {
    return this.api
      .get('/config/feedback/questions/manager/mappings')
      .pipe(map(payload => payload.mappings));
  };

  updateManagerQuestionsMapping = (questionIds: number[]) => {
    return this.api.put('/config/feedback/questions/manager/mappings', questionIds);
  };

  fetchManagerQuestions = (): Observable<FeedbackQuestions> => {
    return this.api
      .get('/config/feedback/questions/managers')
      .pipe(map(payload => payload.manager_questions));
  };

  updateManagerQuestionsOrder = (questions: FeedbackQuestions) => {
    return this.api.put('/config/feedback/questions/manager/order', toIdsMap(questions));
  };

  fetchServiceQuestionsMapping = (serviceId: number) => {
    return this.api
      .get(`/config/feedback/questions/service/${serviceId}/mappings`)
      .pipe(map(payload => payload.mappings));
  };

  updateServiceQuestionsMapping = (serviceId: number, questionIds: number[]) => {
    return this.api.put(`/config/feedback/questions/service/${serviceId}/mappings`, questionIds);
  };

  updateServiceQuestionsOrder = (serviceId: number, questions: FeedbackQuestions) => {
    return this.api.put(`/config/feedback/questions/service/${serviceId}`, toIdsMap(questions));
  };

  fetchProductServices = (): Observable<SelectOption> => {
    return this.api
      .get(`/config/products`)
      .pipe(map(payload => payload.form.fields.services_list.attributes.valueOptions));
  };

  fetchProductGroups = (serviceId: number) => {
    return this.api
      .get(`/config/products/service/${serviceId}/list`)
      .pipe(map(payload => payload.service_product_groups));
  };

  fetchNewProductForm = (serviceId: number, groupId: number) => {
    return this.api
      .get(`/config/products/service/${serviceId}/group/${groupId}/new`)
      .pipe(map(payload => payload.form));
  };

  createProduct = (serviceId: number, groupId: number, data) => {
    return this.api.post(`/config/products/service/${serviceId}/group/${groupId}`, data);
  };

  fetchProductForm = (serviceId: number, groupId: number, productId: number) => {
    return this.api
      .get(`/config/products/service/${serviceId}/group/${groupId}/product/${productId}`)
      .pipe(map(payload => payload.form));
  };

  updateProduct = (serviceId: number, groupId: number, productId: number, data) => {
    return this.api.patch(
      `/config/products/service/${serviceId}/group/${groupId}/product/${productId}`,
      data
    );
  };

  fetchSageErrors = () => {
    return this.api.get(`/config/integrations/sage/errors`).pipe(map(payload => payload));
  };

  fetchSageSuccesses = () => {
    return this.api
      .get(`/config/integrations/sage/successes`)
      .pipe(map(payload => payload.sage_successes));
  };

  resubmitSageRequest = (id: number) => {
    return this.api.patch(`/config/integrations/sage/errors/${id}/resubmit`, null);
  };

  fetchDocuments = () => {
    return this.api.get(`/config/documents`).pipe(map(payload => payload.documents));
  };

  fetchBouncedEmails = () => {
    return this.api
      .get(`/config/integrations/email/bounced_list`)
      .pipe(map(payload => payload.bounced_emails));
  };

  deleteBouncedEmail = (id: number) => {
    return this.api.delete(`/config/integrations/email/bounced_list/${id}`);
  };

  fetchEmailQueueFailures = () => {
    return this.api
      .get(`/config/integrations/email/email_queue_failures`)
      .pipe(map(payload => payload.failures));
  };

  deleteEmailQueueFailure = (id: number) => {
    return this.api.delete(`/config/integrations/email/email_queue_failures/${id}`);
  };

  fetchConfigSections = () => {
    return this.api.get(`/config/options`).pipe(map(payload => payload.options));
  };

  fetchSSOConfigSetup = () => {
    return this.api.get(`/config/sso/setup`);
  };

  updateSSOConfigSetup = (data: any) => {
    return this.api.patch(`/config/sso/setup`, data);
  };

  fetchSSOTest = () => {
    return this.api.get(`/auth/sso/saml/test`);
  };

  fetchAuditTrail(val: AuditTrailFiltersForm): Observable<{ data: AuditTrail[], filters: AuditTrailFilters }> {
    let url = `/config/integrations/audit/trail/assignment`;

    url += `/${val.assignment}/client/${val.client}/user/${val.user || 'all'}/user_role/${val.userRole || 'all'}`;
    url += `/label/${val.label !== 'all' ? window.btoa(val.label) : 'all'}`;
    if (val.dateFrom) {
      url += `/from_date/${val.dateFrom}`;
    }
    if (val.dateTo) {
      url += `/to_date/${val.dateTo}`;
    }
    url += `/limit/${val.limit}`;

    return this.api.get(url)
      .pipe(map(({ audit_trail_results, filters }) => ({ data: audit_trail_results, filters })));
  };

  fetchUsersList(searchString: string): Observable<IUserOption[]> {
    return this.api.get(`/config/integrations/audit/user/search/${searchString}`)
      .pipe(map(({ audit_user_search_list }) => audit_user_search_list));
  }

  fetchDocumentScanConfig() {
    return this.api.get('/config/document-scan');
  }

  updateDocumentScanConfig = (data: any) => {
    return this.api.patch('/config/document-scan', data);
  }
}

function toIdsMap(questions: FeedbackQuestions) {
  return mapObjIndexed((group: FeedbackQuestion[]) => group.map(q => q.id), questions);
}
