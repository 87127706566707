import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Airport } from './airport';

@Component({
  selector: 'airport-autocomplete-option',
  templateUrl: 'airport-autocomplete-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirportAutocompleteOptionComponent {
  @Input() airport: Airport;
  @Input() highlight: string;
}
