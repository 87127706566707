import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PaginatorComponent } from '@k2/common/pagination/components/paginator.component';
import { ShowMorePaginatorComponent } from '@k2/common/pagination/components/show-more-paginator.component';
import { PaginatePipe } from '@k2/common/pagination/pipes/paginate.pipe';

const declarations = [PaginatorComponent, ShowMorePaginatorComponent, PaginatePipe];

@NgModule({
  imports: [CommonModule],
  declarations,
  exports: declarations
})
export class PaginationModule {}
