import { enableProdMode, NgModuleRef } from '@angular/core';

export function bootstrap<T>(
  nodeModule: NodeModule,
  bootstrapModule: () => Promise<NgModuleRef<T>>
): Promise<NgModuleRef<T>> {
  enableProdMode();
  return bootstrapModule();
}

export function afterAppViewChecked(): void {}
