import { isNotNil } from '@k2/common/helpers';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import { filter, map, mapObjIndexed, pickBy, pipe, values } from 'ramda';

/**
 * Allows to provide featureReducer along with rootReducer.
 */
export function reducerFactory(reducers: any): any {
  if (typeof reducers === 'function') return reducers;
  return reducers.featureReducer || ((state, action) => state);
}

interface CrossCuttingReducer {
  rootReducer: ActionReducer<any>;
  featureReducer?: ActionReducer<any>;
}

/**
 * Custom rootReducer factory.
 *
 * It needs to be used in `StoreModule.forRoot()` to allow support
 * for feature modules with rootReducers.
 */
export function rootReducerFactory(reducers: ActionReducerMap<any>): ActionReducer<any> {
  const rootReducers: ActionReducerMap<any> = getRootReducers(reducers);
  const crossCuttingReducers: Array<ActionReducer<any>> = getCrossCuttingReducers(reducers);

  return reduceReducers(combineReducers(rootReducers), ...crossCuttingReducers);
}

const getCrossCuttingReducers: (
  reducers: ActionReducerMap<any>
) => Array<ActionReducer<any>> = pipe(
  values,
  filter(isCrossCuttingReducer),
  map(reducer => reducer.rootReducer)
);

const getRootReducers: (reducers: ActionReducerMap<any>) => ActionReducerMap<any> = pipe(
  mapObjIndexed((reducer: any) =>
    isCrossCuttingReducer(reducer) ? reducer.featureReducer : reducer
  ),
  pickBy(isNotNil)
) as any;

function isCrossCuttingReducer(reducer: any): reducer is CrossCuttingReducer {
  return reducer.rootReducer != null;
}

export function reduceReducers(...reducers) {
  return (state, action) => {
    return reducers.reduce((s, reducer) => reducer(s, action), state);
  };
}
