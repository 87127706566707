import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'inline-options',
  templateUrl: 'inline-options.component.html',
  styleUrls: ['inline-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineOptionsComponent {
  underConfirmation = false;
  @Input() noRemove = false;
  @Output() remove = new EventEmitter();

  get removable(): boolean {
    return !this.noRemove && this.remove.observers.length > 0;
  }

  requestConfirmation = () => {
    this.underConfirmation = true;
  };

  confirm = () => {
    this.underConfirmation = false;
    this.remove.emit();
  };

  cancel = () => {
    this.underConfirmation = false;
  };
}
