<k2-select
  [value]="place.country"
  (valueChange)="changeCountry($event)"
  [options]="countries"
  placeholder="Select country..."
  [disabled]="disabled"
></k2-select>

<k2-select
  *ngIf="states != null"
  [value]="place.state"
  (valueChange)="changeState($event)"
  [options]="states"
  placeholder="Select state..."
  [disabled]="disabled"
  class="state"
></k2-select>
