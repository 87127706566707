import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { safe } from '@k2/common/helpers';
import { AddMessage } from '@k2/common/flash-messages/actions';
import { RootState } from '@k2/common/flash-messages/state';
import { AppStore } from '@k2/common/state/services/app-store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const unknownError = {
  text: 'An unexpected error has occurred! Our technical team have been notified.'
};

@Injectable()
export class FlashMessagesHttpInterceptor implements HttpInterceptor {
  constructor(private readonly store: AppStore<RootState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(this.processOkResponse, this.processErrorResponse));
  }

  private processOkResponse = (response: HttpResponse<any>) => {
    const messages = safe(() => response.body.meta.flash) || [];

    messages
      .map(({ text }) => new AddMessage({ text, type: 'confirm' }))
      .forEach(msg => this.store.dispatch(msg));
  };

  private processErrorResponse = (response: HttpErrorResponse) => {
    const messages: Array<{ text: string }> = safe(() => response.error.meta.flash) || [
      unknownError
    ];

    messages
      .map(({ text }) => new AddMessage({ text, type: 'error' }))
      .forEach(msg => this.store.dispatch(msg));
  };
}

export const httpInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: FlashMessagesHttpInterceptor,
  multi: true
};
