import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { asyncIdentity } from '@k2/common/helpers';
import { Document } from '@k2/common/entities-state/types';
import { SubmitControlComponent } from '@k2/common/ui/components/submit-control/submit-control.component';
import { Observable } from 'rxjs';

@Component({
  templateUrl: 'documents-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsDialogComponent<T> {
  selected: number[] = [];

  @ViewChild(SubmitControlComponent) submitControl: SubmitControlComponent;

  constructor(
    private dialogRef: MatDialogRef<DocumentsDialogComponent<T>, T>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentsDialogData
  ) {}

  submit = async () => {
    this.submitControl.loading = true;
    const onSubmit = this.data.onSubmit || asyncIdentity;

    try {
      const response = await onSubmit(this.selected);
      this.dialogRef.close(response);
    } catch (e) {}

    this.submitControl.loading = false;
  };

  cancel = () => {
    this.dialogRef.close();
  };
}

export interface DocumentsDialogData<T = any> {
  readonly documents: Observable<Document[]>;
  readonly title: string;
  readonly submitLabel: string;
  readonly submitIcon?: string;
  readonly notes?: string;
  readonly onSubmit?: (value: number[]) => Promise<T>;
}
