import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Assignment } from '@k2/common/entities-state/types';

@Component({
  selector: 'assignment-details',
  templateUrl: 'assignment-details.component.html',
  styleUrls: ['assignment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentDetailsComponent {
  @Input() assignment: Assignment;
}
