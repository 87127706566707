import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { containsEmojis, removeEmojis } from "@k2/common/k2-forms/utils";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: 'textarea-control',
  templateUrl: 'textarea-control.component.html',
  styleUrls: ['textarea-control.component.scss']
})
export class TextareaControlComponent implements OnInit, OnDestroy {
  @Input() field: Field<string>;
  alive: boolean = true;

  get placeholder(): string {
    if (this.field.attributes.placeholder !== null && this.field.attributes.placeholder !== undefined) {
      return this.field.attributes.placeholder;
    } else {
      return this.field.attributes.label || '';
    }
  }

  ngOnInit() {
    this.field.control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(value => {
      if (containsEmojis(value)) {
        this.field.control.setValue(removeEmojis(value));
      }
    })
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
